import React, {useEffect, useReducer, useState} from "react";
import { constants } from "../../commons/constants";
import { handleFocus } from "./utility/functions";
import {Link, Redirect, useHistory} from "react-router-dom";
import { getPDPAContent, registerUser } from "../../commons/APIService";
import { useDispatch } from "react-redux";
import { USERINFO } from "../../actions";
import * as Texts from "../../commons/Texts";

const VerifyOtp = (props) => {
  console.log(props)
  let userData =
    props.location.state !== undefined ? props.location.state.userData : "";
  const dispatch = useDispatch();
  const [userInfo, setUserData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    userData
  );
  const [userRegistered, setUserRegistered] = useState("")
  const [enteredPin, setEnteredPin] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      a: "",
      b: "",
      c: "",
      d: "",
      error: false,
    }
  );
  console.log('userInfo', userInfo)
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false)
  const handleChange = (e) => {
    setWrongOtp(false)
    let a = document.getElementById("1").value,
      b = document.getElementById("2").value,
      c = document.getElementById("3").value,
      d = document.getElementById("4").value;
    let id = e.target.id;
    if (e.target.value.match(constants.numberRegex)) {
      setEnteredPin({ error: false });
      if (id == "1") {
        setEnteredPin({ a: e.target.value });
      } else if (id == "2") {
        setEnteredPin({ b: e.target.value });
      } else if (id == "3") {
        setEnteredPin({ c: e.target.value });
      } else if (id == "4") {
        setEnteredPin({ d: e.target.value });
      }
    } else {
      setEnteredPin({ error: true });
    }
    setUserData({
      OTP: `${a}${b}${c}${d}`,
    });
  };
  useEffect(()=> {
    let a = userInfo.OTP
    console.log(a)
  }, [userInfo && userInfo.OTP && userInfo.OTP != undefined])
  const requestRegister = async (e) => {
    e.preventDefault();
    let params = {
      storeId: userInfo.storeId,
      mobile: userInfo.mobile,
      email: userInfo.email,
      name: userInfo.name,
      countryCode: userInfo.countryCode,
      password: userInfo.password,
      otp: userInfo.OTP,
    };
    const storeId = { storeId: params.storeId };
    // dispatch(USERINFO(userInfo));
    let regUserRes = await registerUser(params);
    let message = regUserRes.desc
    if (
      regUserRes &&
      (regUserRes.result_code == 200 || regUserRes.result_code == 7101) &&
      regUserRes.CRM &&
      !regUserRes.CRM.isMember
      ) {
      let pdpaRes = await getPDPAContent(storeId);
      history.push({
        pathname: constants.PDPA_ROUTE_VERIFY,
        state: {
          agrement: pdpaRes.advocadoPDPA,
          noRedemptionPin: false,
          userInfo,
        },
      });
    } else if (regUserRes && (regUserRes.result_code == 200 || regUserRes.result_code == 7101) && regUserRes.name && regUserRes.CRM &&
        regUserRes.CRM.isMember){

      setUserRegistered(`${message}`)
    }
    else if (regUserRes && (regUserRes.result_code == 200 || regUserRes.result_code == 7101) && regUserRes.name && !regUserRes.CRM){

      setUserRegistered(`${message}`)
      console.log(message)
    }else if (regUserRes && regUserRes.result_code == 7221){
      setWrongOtp(true)
    }
    else if (regUserRes.CRM.isMember) {
      setShowError(true);
    }
  };
  return (
    <div className="px-5 col-10 mx-auto mb-4">
      {props.location.state === undefined && <Redirect to={constants.SIGNUP_ROUTE}/>}
      {!userRegistered ? <div>
        <h2 className="mb-3">{Texts.ENTER_OTP_TEXT}</h2>
        <form>
          <div className="row my-5 mx-0 otp">
            <div className="col pr-1 pl-0">
              <input
                  id="1"
                  maxLength={1}
                  type="text"
                  placeholder="-"
                  className="form-control form-control-lg"
                  value={enteredPin.a}
                  onKeyUp={(e) => handleFocus(e)}
                  onChange={(e) => handleChange(e)}
                  autoFocus={true}
              />
            </div>
            <div className="col px-2">
              <input
                  id="2"
                  maxLength={1}
                  type="text"
                  placeholder="-"
                  value={enteredPin.b}
                  className="form-control form-control-lg"
                  onKeyUp={(e) => handleFocus(e)}
                  onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col px-2">
              <input
                  id="3"
                  maxLength={1}
                  type="text"
                  placeholder="-"
                  value={enteredPin.c}
                  className="form-control form-control-lg"
                  onKeyUp={(e) => handleFocus(e)}
                  onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col pl-1 pr-0">
              <input
                  id="4"
                  type="text"
                  placeholder="-"
                  value={enteredPin.d}
                  maxLength={1}
                  className="form-control form-control-lg"
                  onKeyUp={(e) => handleFocus(e)}
                  onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          {enteredPin.error ? (
              <p className="text-warning text-center font-weight-bold">Only Numbers allowed</p>
          ) : (
              ""
          )}
          {showError && (
              <div className="test font-weight-bold text-xl-center text-info pb-5 text-center">
                User Already Exist &nbsp; &nbsp;<Link to={constants.LOGIN_ROUTE} className="text-success"> <i
                  className="feather-user"/> Sign In</Link>
              </div>
          )}
          {wrongOtp && (
              <div className="text-danger font-weight-bold text-xl-center text-info pb-5 text-center">
                Wrong OTP, Try Again
              </div>
          )}
          <button
              type="button"
              onClick={(e) => {
                if (userInfo && userInfo.OTP && userInfo.OTP.length == 4) {
                  requestRegister(e)
                }
              }}
              className={`btn btn-lg btn-block ${(userInfo && userInfo.OTP && userInfo.OTP.length == 4) ? ' btn-primary' : "disabled btn-dark"}`}
          >
            Verify Now
          </button>
        </form>
      </div> : <h5 className='text-center'>{userRegistered}, <Link to={constants.LOGIN_ROUTE}>Sign In </Link> to continue.</h5>}
    </div>
  );
};

export default VerifyOtp;
