import {useDispatch, useSelector} from "react-redux";
import {createRecipt} from "../commons/utility";
import {constants} from "../commons/constants";
import {paymentInformationUpdate, removeItemBasket, updateTableOrderHistory} from "../actions";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

const StoreResponse = (props) => {
    let state = useSelector(state => state.impReducer)
    let dispatch = useDispatch();
    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory()

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
                    setLocationKeys((keys) => [ location.key, ...keys ])
                        //console.log('back button press', history.location)
                if(state.hasTableService){
                    props.history.push(constants.START_ORDER)
                    dispatch(removeItemBasket([]))
                    dispatch(paymentInformationUpdate({}))
                }else {
                    props.history.push(constants.STORE_HOME)
                    if (props.history && props.history.location && props.history.location.state && !props.history.location.state.isError) {
                        dispatch(removeItemBasket([]))
                        dispatch(paymentInformationUpdate({}))
                    }
                }
            }
        })
    }, [ locationKeys, ])
    let resp = props.location.state;
    //console.log(history)
    // let PAC = state.payment.PAC
    let selectedProduct = state.paymentInformation && state.paymentInformation.recipt &&  state.paymentInformation.recipt;
    let image = state.storeNote && state.storeNote.Images && state.storeNote.Images.PACSuccess
    let imageFailed = state.storeNote && state.storeNote.Images && state.storeNote.Images.PACFailed
    // let pagerValue = localStorage.getItem('label')
    let  product = state.allProducts.products
    // useEffect(()=>{
    //     if(state.hasTableService){
    //         dispatch(updateTableOrderHistory(selectedProduct))
    //     }
    // },[state.paymentInformation && state.paymentInformation.recipt && state.paymentInformation.recipt.length > 0])
    // // let product = null
    const onPressHome = () => {
        if(state.hasTableService){
            props.history.push(constants.START_ORDER)
            dispatch(removeItemBasket([]))
            dispatch(paymentInformationUpdate({}))
        }else {
            props.history.push(constants.STORE_HOME)
            dispatch(removeItemBasket([]))
            dispatch(paymentInformationUpdate({}))
        }
    }
    let checkError = props.history && props.history.location && props.history.location.state && props.history.location.state
    //console.log(checkError && checkError.isError && checkError.response)
    let errorTitle = (checkError && checkError.displaytitle) ? checkError.displaytitle : null
    let errorMsg = (checkError && checkError.displaymsg) ? checkError.displaymsg : null

    return (
        <div>
            {window.location.pathname === constants.STORE_RESPONSE &&<div
                style={{width:'100%', height:'auto',zIndex:'1999', position:'absolute', top:'0', left:'0', backgroundColor:'white'}}>{
                props.history.location.state.isError ?
                    <div className='text-center pt-5 mt-5 p-3'>
                        <h1>{(checkError && checkError.response &&  checkError.response.status) && (checkError.response.status == 408 || checkError.response.status == 504)?"Service Not Available":errorTitle ? <h1>{errorTitle}</h1> : "Order Failed"}</h1>
                        <img className='w-50 p-3' src={imageFailed}/>
                        {errorMsg && <h3>{errorMsg}</h3>}
                        <h5>{(checkError && checkError.response &&  checkError.response.status) && (checkError.response.status == 408 || checkError.response.status == 504)&& "Our Service is currently unavailable. we are sorry for inconvenience"}</h5>
                        {/*<h3><FrownTwoTone twoToneColor='red'/></h3>*/}
                        <button className='btn btn-block btn-facebook mt-5 mb-5 p-2' onClick={() => {
                            props.history.replace(constants.STORE_HOME)
                        }}>To The Menu
                        </button>
                    </div> :<>
                    {/*<BackButton title={resp.displaytitle} />*/}
                    <div className='d-flex justify-content-centser align-items-center'>
                        <div className='col-md-6'>
                            <div className='text-center pb-3 pt-3'>
                                <h2 className='font-weight-bold'> {resp.displaytitle}  </h2>
                                <p style={{fontSize:'13px'}}>{resp.displaymsg}</p>
                                {/*<p style={{fontSize:'13px', paddingLeft:'2px'}}>{PAC.Success}</p>*/}
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div>
                                    <img style={{width: '200px', maxHeight:'200px'}} src={image}/>
                                </div>
                                <div className='d-flex flex-column justify-content-center'>

                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center p-1'>
                                <div className='d-flex flex-row justify-content-center'>
                                    <span>Order #&nbsp; </span>
                                    <span><b>{props.history.location.state.order_number}</b></span>
                                </div>
                                {state && state.paymentInformation && state.paymentInformation.label &&<div className='w-50 d-flex flex-row justify-content-end'>
                                    <span>Pager #&nbsp; </span>
                                    <span><b>{state.paymentInformation.label}</b></span>
                                </div>}
                            </div>
                            <div style={{height:selectedProduct && selectedProduct.length > 1 ? '308px' : '160px', overflowY:'scroll'}}>
                                {createRecipt(constants.PAYMENTSUCCESS, selectedProduct, product)}
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <div className='d-flex flex-row justify-content-between'>
                                    <strong>Total Price</strong>
                                    <strong>${Number(state.paymentInformation.totalPrice).toFixed(2)}</strong>
                                </div>
                                {state.paymentInformation.staffDiscount &&<><div className='d-flex flex-row justify-content-between'>
                                    <strong>Staff Discount</strong>
                                    <strong>{state.storeNote.StaffDiscount.Message3}</strong>
                                </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                    <strong>Total Discount</strong>
                                    <strong>$ {state.paymentInformation.discountedAmount}</strong>
                                    </div></>}
                                <div className='d-flex flex-row justify-content-between'>
                                    <strong>Grand Total</strong>
                                    <strong>${Number(state.paymentInformation.grandTotal).toFixed(2)}</strong>
                                </div>
                            </div>
                            <button className='btn btn-block btn-facebook mt-5 mb-5 p-2' onClick={onPressHome}><i className='feather-home'/>&nbsp;Home
                            </button>
                        </div>
                    </div>
                    </>
            }</div>}
        </div>
    )
}

export default StoreResponse;
