import React from "react";
import { BlockLoading } from "react-loadingg";
const Loading = () => (
  <div
    style={{
      backgroundColor: "rgba(255,255,255, 0.7)",//"rgba(67, 36, 47, 0.5)",
      position: "fixed",
      width: "100%",
      height: "100%",
      zIndex: "9999",
      top: 0
    }}
  >
    <BlockLoading size="large" color="#d92662" speed={1.5} />
  </div>
);
export default Loading;
