import React from "react";
import {useHistory} from "react-router-dom";
import {constants} from '../../commons/constants';
import {useDispatch} from "react-redux";
import {login, USERINFO} from "../../actions";
import {activateAdvocadoUser, userLogin} from "../../commons/APIService";
import {set} from "../../commons/storageService";

const PDPA = (props) => {
  console.log(props)
  const buttonHandler = () => {
    props.location.state.noRedemptionPin === false ? history.push({
      pathname: constants.REDEMPTION_PIN,
      state: props.location.state.userInfo
    }): activateAdvocadoUser(props.location.state.userInfo).then(res => {
      if (res && res.result_code && res.result_code == '200') {
        let userObj = {
          mobile: props.history.location.state.userInfo.mobile,
          password: props.history.location.state.userInfo.password,
          countryCode: props.history.location.state.userInfo.countryCode,
          storeId: props.history.location.state.userInfo.storeId
        }
        userLogin(userObj).then((r) => {
          if (r && r.result_code && r.result_code == "500") {
            return null;
          } else if (r.result_code == "200") {
            set("USER_INFO", r);
            dispatch(USERINFO(r));
            dispatch(login(true))
            props.history.replace(constants.STORE_HOME)
          }
        });
      }
    })
  }
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div>
      {/*modal*/}
      <div className='custom-modal'>
        <div className="modal-body modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Agreement
              </h5>
              <button
                onClick={() => history.goBack()}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className='modal-body' dangerouslySetInnerHTML={{__html: props.location.state.agrement}} ></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history.goBack()}
              >
                Close
              </button>
              <button onClick={buttonHandler}>
                Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PDPA;
