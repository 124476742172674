import _ from "lodash";
import {constants, phoneCodes} from "./constants";
import React from "react";
import {removeItemBasket} from "../actions";
import store from "../store";
import {Divider, List} from "antd";
import AlertRemoveProduct from "../component/authentication/utility/AlertRemoveProduct";

export const getSaleType = (id) => {
    if (id == 1) return "Dine-In"
    else if ( id == 5) return "Pick-Up"
    else if (id == 2) return "Take-Away"
}

export const isValidLoginData = (userInfo) => {
    return (
        userInfo &&
        userInfo.mobile &&
        userInfo.mobile.match(constants.phoneNumberRegex) &&
        userInfo.password &&
        userInfo.password.length >= 6 &&
        userInfo.password.match(constants.pinRegex)
    );
}

export const countryCodeList = () => {
    const codes = _.sortBy(phoneCodes, [function(o) { return parseInt(o.dial_code); }]);
    let codeList = codes.map(
        (item) => <option key={item.name} value={item.dial_code}>{item.dial_code}</option>
    )
    return codeList
}

export const deleteItem = (id) =>{
    let dispatch = store.dispatch;
    let state = store.getState()
    let mystate = state.impReducer;
    let newBasket = mystate.basket
    newBasket.map((item,i)=> {
        if(item[0].id == id){
            newBasket.splice(i,1)
            dispatch(removeItemBasket(newBasket))
        }
    })
}

export const quantityChange = (method, id, priceUpdate, setConfirmDelete) =>{
    let dispatch = store.dispatch;
    let state = store.getState()
    let mystate = state.impReducer;
    let newBasket = mystate.basket
    let q
    mystate.basket.map((product,i)=>{
        if(product[0].id == id){
            if (method == "add"){
                let basePrice = parseFloat(newBasket[i][0].totalPrice) / Number(newBasket[i][1].quantity)
                let newQuantity = Number(newBasket[i][1].quantity) + 1
                newBasket[i].map((item)=>{
                    if(item.quantity){
                        item.quantity = Number(item.quantity) + 1
                        item.Modifiers.map((modifiers,m)=>{
                            q = Number(item.Modifiers[m].quantity) + 1
                            item.Modifiers[m].quantity = q
                        })
                    }
                })
                let updatedPrice =(basePrice * newQuantity)
                newBasket[i][0].totalPrice =updatedPrice.toFixed(2)
            }else if(method == "subtract"){
                if(newBasket[i][1].quantity > 1) {
                    let basePrice = parseFloat(newBasket[i][0].totalPrice) / Number(newBasket[i][1].quantity)
                    let newQuantity = Number(newBasket[i][1].quantity) - 1
                    newBasket[i].map((item) => {
                        if (item.quantity && parseFloat(item.quantity) > 1) {
                            item.quantity = parseFloat(item.quantity) - 1
                            item.Modifiers.map((modifiers, m) => {
                                item.Modifiers[m].quantity = parseFloat(item.Modifiers[m].quantity) > 1 ? parseFloat(item.Modifiers[m].quantity) - 1 : parseFloat(item.Modifiers[m].quantity)
                            })
                        }
                    })
                    let updatedPrice =(basePrice * newQuantity)
                    newBasket[i][0].totalPrice =updatedPrice.toFixed(2)

                } else if (newBasket[i][1].quantity == 1 )  {
                    setConfirmDelete(id)
                }
            }

        }
    })
    dispatch(removeItemBasket(newBasket))
    priceUpdate && priceUpdate();
}

export const createRecipt = (pathname, selectedProducts, products, quantityChange, deleteItem, priceUpdate, confirmDelete, setConfirmDelete,deleteProduct, confirmDelProduct ) => {
    let selectedProdCopy = JSON.parse(JSON.stringify(selectedProducts));
    //console.log('prod******', selectedProducts)

    return selectedProducts.map((item, i) => {
        //console.log("create Recipt",item)
		    return (
			    <div className={pathname === constants.CART ?"col-lg-6":""}>
              <div className="cart-item d-flex flex-row position-relative">


              {pathname === constants.CART ?<div>
                  {(confirmDelete && confirmDelete == item[0].id) && <AlertRemoveProduct visible={confirmDelete != null ? true : false} infoText='Delete Product' description={`Are you sure you want to remove ${JSON.parse(item[0].prodInfo.product_name).en} from your cart`} setCofirmDelete = {deleteProduct} accept ={confirmDelProduct}/>}
                      <i className='feather-trash-2 cart-item-delete' onClick={()=> {
                  setConfirmDelete(item[0].id)
                  // deleteItem(item[0].id)
                  }}/></div> :
                  <span className='cart-item-quantity'>{item[1].quantity}</span>

              }
                <div className="left-sec position-relative d-flex flex-column justify-content-center" >
                  <div className="item-img position-relative" style={{background: `url(${item[0].prodInfo.product_pic}) no-repeat center/cover`}}>
                      <div className="position-absolute d-flex flex-row justify-content-center price-tag">
                          <span className="prod-price">$ {item[0].prodInfo.price}</span>
                      </div>
                  </div>



                </div>
                <div className="w-100 right-sec d-flex flex-column align-content-start justify-content-center">
                    <div className="d-flex flex-row justify-content-between">
                        <h4 className="item-title">{JSON.parse(item[0].prodInfo.product_name).en}</h4>
                        {pathname === constants.CART &&
                        <div className="d-flex flex-row justify-content-center qnty-selector">
                            <div className='count-number-2 float-right'>
                                <button type='button' className='btn-sm left dec btn btn-outline-secondary' onClick={() => quantityChange("subtract", item[0].id,priceUpdate && priceUpdate, setConfirmDelete)}>
                                    <i className='feather-minus'/>
                                </button>
                                <input className="count-number-input-2 text-white bg-secondary" value={item[1].quantity} readOnly/>
                                <button type='button' className='btn-sm left dec btn btn-outline-secondary' onClick={() => quantityChange("add", item[0].id,priceUpdate && priceUpdate)}>
                                    <i className='feather-plus'/>
                                </button>
                            </div>
                        </div>
                        }
                    </div>

                    <div className="cart-add-on-list">
                        {
                            item[0] && item[0].selectedCombo && Object.keys(item[0].selectedCombo).map((key, i) =>
                                item[0].selectedCombo[key] && item[0].selectedCombo[key].length > 0 &&
                                  <div className="cart-mod">
                                      <h4 className="cart-mod-title">- {key}</h4>
                                      {item[0].selectedCombo[key].map(ci =>
                                          <div>
                                              <p className="cart-mod-item">{ci.name}</p>
                                              {ci.Modifiers && ci.Modifiers.map(cim => {console.log('modifiers',cim)
                                                  return <div>
                                                      <p className='cart-mod-item'>- {cim.drinkModifier}</p>
                                                  </div>
                                              })}

                                          </div>
                                      )}
                                  </div>
                            )
                        }
                        {
	                        item[0] && item[0].selectedModifiers && Object.keys(item[0].selectedModifiers).map((key, i) =>
                                item[0].selectedModifiers[key] && item[0].selectedModifiers[key].length > 0 &&
                                <div className="cart-mod">
                                  <h4 className="cart-mod-title">- {key}</h4>
                                  {item[0].selectedModifiers[key].map(item=><p className="cart-mod-item">{item.name}</p>)}
                                </div>
                            )
                        }
                    </div>
                </div>
              </div>
			    </div>)
	    }
    )
}
