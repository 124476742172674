import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {guest, LOADER, login, storeToReduxStore, USERINFO} from "../../actions";
import { useHistory } from "react-router-dom";
import { constants } from "../../commons/constants";
import {countryCodeList, isValidLoginData} from "../../commons/utility";
import {getPDPAContent, storeID, userLogin} from "../../commons/APIService";
import {get, set} from "../../commons/storageService";
import * as texts from "../../commons/Texts";
import Alert from "./utility/Alert";
import {isValidData} from "./utility/functions";

const Login = (props) => {
  let history = useHistory();
  const mystate = useSelector((state) => state.impReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    let storeInfo = get(constants.STORE_INFO)
    let userInfo = mystate.userInfo
    dispatch(storeToReduxStore(storeInfo.storeInfos[0]))
    setUserData({ storeId: storeID() });

    if (userInfo && userInfo.CRM && userInfo.name) {
      dispatch(login(true));
      history.push({
        pathname: props.location && props.location.from?props.location.from:constants.APP_ROUTE,
        state: { store_id: storeID() },
      })
    }
  }, []);
  const [userInfo, setUserData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mobile: "",
      password: "",
      countryCode: "65",
      storeId: "",
    }
  );

  const [showError, setShowError] = useState(false);
  const { countryCode } = userInfo;
  const [showError2, setShowError2] = useState(false);
  const [errorMessage, setErrorMessage] = useState({title:"", message:""});
  const loginRequest = (e, type) => {
    e.preventDefault();
    if (type === constants.REGISTERED_USER) {
      if (isValidLoginData(userInfo)) {
        userLogin(userInfo).then((data) => {
          let res = data;
          if (res.isError) {
            //console.log("errrrrrrrrrrrrooooooorrrrr",res.data)
            dispatch(LOADER(false));
            setShowError2(true)
            setErrorMessage({title:"ERROR", message: res  && res.desc ? res.desc : "Server Error" })
          } else if (res.result_code == "7103") {
            setErrorMessage({title:"ERROR", message: res.desc})
            setShowError2(true);
          } else if (res && res.result_code && res.result_code == "200") {
            if (res && res.CRM && res.CRM.isMember && res.CRM.pdpa == 0) {
              getPDPAContent({ storeId: userInfo.storeId }).then((r) => {
                history.push({
                  pathname: constants.PDPA_ROUTE,
                  state: {
                    noRedemptionPin: true,
                    agrement: r.advocadoPDPA,
                    userInfo: userInfo,
                  },
                });
              });
            } else if (res && res.CRM && res.CRM.isMember == false) {
              getPDPAContent({ storeId: userInfo.storeId }).then((r) => {
                history.push({
                  pathname: constants.PDPA_ROUTE,
                  state: {
                    agrement: r.advocadoPDPA,
                    noRedemptionPin: false,
                    userInfo: userInfo,
                  },
                });
              });
            } else {
              dispatch(login(true));
              dispatch(guest(false))
              dispatch(USERINFO(res));
              set("objCredentials", userInfo )
              if (mystate.locate && mystate.hasTableService){
                history.push(constants.START_ORDER)
              }else history.push({
                pathname: props.location && props.location.from?props.location.from:constants.APP_ROUTE,
                state: { store_id: userInfo.storeId },
              });
            }
          }
        });
      } else {
        setShowError(true);
      }
    } else if (type === constants.NON_REGISTERED_USER) {
      dispatch(login(true));
      dispatch(guest(true));
      history.push({
        pathname: constants.APP_ROUTE,
        state: { store_id: userInfo.storeId },
      });
    }
  };
  return (
    <div className="px-5 col-10 mx-auto">
      <h2 className="text-dark my-0">{texts.WELCOME}</h2>
      <p className="text-dark text-50">{texts.LOGIN_SCREEN_MESSAGE}</p>
      <form className="mt-5 mb-4 login-form" autoComplete="off">
        <div className="form-group">
          {showError2 && errorMessage.message && (
            <Alert
                onClick={()=> setShowError2(false)}
                message={errorMessage.message}
                type={errorMessage.title}
            />
          )}
          <label htmlFor="mobile" className="text-dark d-block mb-2">
            {texts.ENTER_PHONE_NO}
          </label>
          <select
            id="country-code"
            placeholder={texts.COUNTRY_CODE_PLACEHOLDER}
            className="form-control d-inline-block w-auto border pl-2 phone-num-1"
            value={countryCode}
            onChange={(e) => {
              setShowError2(false);
              setUserData({ countryCode: e.target.value });
            }}
          >
            {countryCodeList()}
          </select>
          <input
            type="tel"
            maxLength="8"
            title="you can only enter numbers"
            value={userInfo.phoneNumber}
            className="form-control d-inline-block letterSpacing pl-2 phone-num-2"
            placeholder={texts.PHONE_NO_PLACEHOLDER}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setShowError2(false);
              setUserData({ mobile: e.target.value });
            }}
            autoComplete="off"
          />
          {showError && !userInfo.mobile ? (
            <p className="text-danger">{texts.ENTER_PHONE_NO_MSG}</p>
          ) : showError &&
            !userInfo.mobile.match(constants.phoneNumberRegex) ? (
            <p className="text-danger">
              {texts.ENTER_VALID_PHONE_NUMBER}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1" className="text-dark">
            {texts.ENTER_LOGIN_PIN}
          </label>
          <input
            type="password"
            maxLength="6"
            placeholder={texts.LOGIN_PIN_PLACEHOLDER}
            className="form-control letterSpacing pl-2"
            id="exampleInputPassword1"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => setUserData({ password: e.target.value })}
          />
          {showError && userInfo.password.length < 6 ? (
            <p className="text-danger">
              {texts.PIN_MUST_CONTAIN_6_NUMBERS}
            </p>
          ) : showError && !userInfo.password.match(constants.pinRegex) ? (
            <p className="text-danger">
              {texts.PIN_MUST_CONTAIN_6_NUMBERS}
            </p>
          ) : (
            ""
          )}
        </div>
        <button
          className="btn btn-primary btn-lg btn-block"
          onClick={(e) => loginRequest(e, constants.REGISTERED_USER)}
        >
          <i className="feather-log-in" /> {texts.LOGIN_TEXT}
        </button>
        <div className="py-2">
          <button
            className="btn btn-lg btn-secondary btn-block"
            onClick={(e) => loginRequest(e, constants.NON_REGISTERED_USER)}
          >
            <i className="feather-user" /> {texts.CONTINUE_AS_GUEST}
          </button>
        </div>
      </form>
      <p
        className="text-center text-dark pointer font-weight-bold"
        onClick={() => history.push(constants.FORGOT_PASSWORD)}
      >
        Forgot your PIN?
      </p>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <p className="text-center m-0 text-dark font-weight-bold">Don't have an account?<span className="pointer link-primary text-success font-weight-bold" onClick={() => history.push(constants.SIGNUP_ROUTE)}>
           &nbsp; &nbsp; Sign Up
          </span></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
