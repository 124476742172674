import {useSelector} from "react-redux";
import {constants} from "../commons/constants";
import React, {useEffect} from "react";
import {mobileOrderRequest, paymentUpdate} from "../commons/APIService";
import PaymentErrorComponent from "./commons/PaymentErrorComponent";

const PaymentTimeout =(props) => {
    const state = useSelector(state => state.impReducer)
    let EFTFailed = state.storeNote.Payment.EFT
    let image = state.storeNote.Images.EFTPaymentFailed
    let obj = {
        cloudOrderNumber:state.paymentInformation.cloudOrderNumber && state.paymentInformation.cloudOrderNumber  ,
        payment:{
            status: "timeout"
        }
    }
    useEffect(()=> {
        paymentUpdate(obj).then((r)=> {
            console.log(r)
        })
    },[])
    return (
      <div>
          <PaymentErrorComponent history={props.history} title='Payment Timeout' message={EFTFailed.Timeout} image={image} />
      </div>
    )
};
export default PaymentTimeout;
