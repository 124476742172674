import React, {useState} from "react";
import Sidemenu from "./sidemenu";
import {useSelector} from "react-redux";
import {constants} from "../../commons/constants";
import {Link} from "react-router-dom";

const HeaderSmall = (props) => {
  const [visible, setVisible] = useState(false)
  const state = useSelector((state)=> state.impReducer);
  const handleClick = () => {
    setVisible(true)
  };
  const onClose = () => {
    setVisible(false)
  }
  return (
    <>
      <div className="bg-primary p-3 d-none fixed-top">
          <div className="d-flex flex-row align-items-center justify-content-between">
            {/*<i className="feather-menu menu-toggle-icon" onClick={props.handleSideMenu}/>*/}
            <div></div>
            <div className="d-flex flex-row justify-content-between header-store-info">
              <img className='store-img rounded-circle ml-roundedimg' style={{width:"3.5em", height:"3.5em", backgroundColor:"rgba(255,255,255,0.9)"}} src={state.storeNote.Images.Logo}/>
              <div className='d-flex flex-column justify-content-center ml-2'>
                <h4 className="store-title title-overflow">{JSON.parse(state.store.store_name).en}</h4>
                <label className='store-address address-title'>{state.store.street}</label>
              </div>
            </div>

            {/*<a*/}
            {/*  className="text-white font-weight-bold ml-auto"*/}
            {/*  data-toggle="modal"*/}
            {/*  data-target="#exampleModal"*/}
            {/*>*/}
            {/*  Filter*/}
            {/*</a>*/}
            <Link to={constants.CART} className='d-flex'>
              <i className='feather-shopping-cart pointer text-white position-relative' style={{fontSize:"24px"}}>
                {state.basket && state.basket.length > 0 &&
                <span className="custom-badge-cart">{props.prodQuantity}</span>}
              </i>
            </Link>
          </div>
      </div>
      <div
        className="modal fade show"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style={{
          display: "none",
          paddingRight: "4px",
        }}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Filter</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="osahan-filter">
                <div className="filter">
                  {}
                  <div className="p-3 bg-light border-bottom">
                    <h6 className="m-0">SORT BY</h6>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-radio">
                    <input
                      type="radio"
                      id="customRadio1f"
                      name="location"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="customRadio1f"
                    >
                      Top Rated
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-radio">
                    <input
                      type="radio"
                      id="customRadio2f"
                      name="location"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="customRadio2f"
                    >
                      Nearest Me
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-radio">
                    <input
                      type="radio"
                      id="customRadio3f"
                      name="location"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="customRadio3f"
                    >
                      Cost High to Low
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-radio">
                    <input
                      type="radio"
                      id="customRadio4f"
                      name="location"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="customRadio4f"
                    >
                      Cost Low to High
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-radio">
                    <input
                      type="radio"
                      id="customRadio5f"
                      name="location"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="customRadio5f"
                    >
                      Most Popular
                    </label>
                  </div>
                  {}
                  <div className="p-3 bg-light border-bottom">
                    <h6 className="m-0">FILTER</h6>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultCheck1"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="defaultCheck1"
                    >
                      Open Now
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultCheck2"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="defaultCheck2"
                    >
                      Credit Cards
                    </label>
                  </div>
                  <div className="custom-control border-bottom px-0  custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultCheck3"
                    />
                    <label
                      className="custom-control-label py-3 w-100 px-3"
                      htmlFor="defaultCheck3"
                    >
                      Alcohol Served
                    </label>
                  </div>
                  {}
                  <div className="p-3 bg-light border-bottom">
                    <h6 className="m-0">ADDITIONAL FILTERS</h6>
                  </div>
                  <div className="px-3 pt-3">
                    <input
                      type="range"
                      className="custom-range"
                      min={0}
                      max={100}
                      name="minmax"
                    />
                    <div className="form-row">
                      <div className="form-group col-6">
                        <label>Min</label>
                        <input
                          className="form-control"
                          placeholder="$0"
                          type="number"
                        />
                      </div>
                      <div className="form-group text-right col-6">
                        <label>Max</label>
                        <input
                          className="form-control"
                          placeholder="$1,0000"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 border-0">
              <div className="col-6 m-0 p-0">
                <button
                  type="button"
                  className="btn border-top btn-lg btn-block"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
              <div className="col-6 m-0 p-0">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSmall;
