import * as Texts from "../commons/Texts";
import { useEffect } from "react";
import { get } from "../commons/storageService";
import { getPrivacy } from "../commons/APIService";
import React, { useState } from "react";
import BackButton from "./commons/BackButton";
import {constants} from "../commons/constants";

const Privacy = (props) => {
    const [contentPrivacy, setContentPrivacy] = useState("");
    const readPrivacy = () => {
        const storeDetails = get("STORE_CREDENTIALS");
        const deviceNo = storeDetails.deviceID;
        getPrivacy(deviceNo).then((r) => setContentPrivacy(r.data));
    };
    useEffect(() => {
        readPrivacy();
    }, []);
    return (
        <div>
          <BackButton title={Texts.PRIVACY} history={() => props.history.replace(constants.STORE_HOME)}/>

          <div className="st-content container">
              <p className="static-text">{contentPrivacy}</p>
          </div>
        </div>
    );
};

export default Privacy;
