import { constants } from "../commons/constants";

export const initialState = {
  hasTableService : false,
  locate:{},
  store: [],
  workTime:{},
  isRedeemable: false,
  location:'',
  storeNote:{},
  salesType:{},
  payType:[],
  payment:{},
  selectedProductPrice:'',
  basket:[],
  myOrders:{
    tableNumber:null,
    orderHistory:[]
  },
  paymentInformation:{
    totalPrice:'',
    staffDiscount:null,
    grandTotal:'',
    cloudOrderNumber:'',
    recipt:[],
    discountedAmount:'',
  },
  mobileOrder:{},
  logged: false,
  guest: false,
  mainMenu:[],
  subMenu:[],
  allProducts:{},
  allProductsAtt:[],
  comboProducts:{},
  comboInfo:{},
  userInfo: {},
  loader: false,
  pdpa:{},
  isMember:null,
  alert:{
    message:'',
    title:'',
    okHandler:null,
    showPopUp:false
  }
};
//test
const impReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOCATE: {
      return {...state, locate: action.payload}
    }
    case constants.TABLE_SERVICE: {
      return { ...state, hasTableService: action.payload}
    }
    case constants.TABLE_NUMBER: {
      return{
        ...state, myOrders: {
          ...state.myOrders, tableNumber: action.payload
        }
      }
    }
    case constants.TABLE_HISTORY: {
      return {
        ...state, myOrders: {
          ...state.myOrders, orderHistory: [...state.myOrders.orderHistory, action.payload]
        }
      }
    }
    case constants.USERINFO: {
      return{...state,userInfo: action.payload}
    }
    case constants.ALERTPOPUP: {
      return {...state, alert:{
        message: action.payload.message,
          title: action.payload.title,
          okHandler: action.payload.okHandler,
          showPopUp: action.payload.showPopUp

        }}
    }
    case constants.STORE_INFO : {
      return {
        ...state, store:action.payload
      }
    }
    case constants.WORKTIME: {
      return{
        ...state, workTime: action.payload
      }
    }
    case constants.REDEEMABLE : {
      return{
        ...state, isRedeemable: action.payload
      }
    }
    case 'LOCATIONUPDATE' : {
      return {
        ...state, location : action.payload
      }
    }
    case constants.SELECTEDPRODUCTPRICE:{
      return{
        ...state, selectedProductPrice: action.payload
      }
    }
    case constants.BASKET :{
      return {
        ...state, basket:[...state.basket, action.payload]
      }
    }
    case constants.BASKETITEMREMOVE:{
      return{
        ...state, basket:action.payload
      }
    }
    case constants.MOBILEORDER: {
      return{
        ...state, mobileOrder:action.payload
      }
    }
    case constants.PAYMENT_INFORMATION : {
      return{
        ...state, paymentInformation: action.payload
      }
    }
    case constants.STORE_NOTE: {
      return{
        ...state, storeNote: action.payload,
        salesType: action.payload.SalesType,
        payType: action.payload.PayType,
        payment: action.payload.Payment,
        pagerInfo:action.payload.Pager
      }
    }
    case constants.CREATE_USER: {
      return {
        ...state,
        registered: [...state.registered, action.payload],
      };
    }
    case constants.ISMEMBER:{
      return{
        ...state, isMember: action.payload
      }
    }
    case constants.PDPA:{
      return {
        ...state, pdpa:action.payload
      }
    }
    case constants.LOGIN: {
      return {
        ...state,
        logged: action.payload,
      };
    }
    case constants.GUEST: {
      return {
        ...state,
        guest: action.payload,
      };
    }
    case constants.GETMAINMENU: {
      return {
        ...state,
        mainMenu: action.payload.data,
      };
    }
    case constants.SUB_MENU: {
      return {
        ...state, subMenu: action.payload
      }
    }
    case constants.ALLPRODUCTS : {
      return {
        ...state,allProducts: action.payload
      }
    }
    case constants.ALLPRODUCTSATT : {
      return {
        ...state, allProductsAtt: action.payload
      }
    }
    case constants.ALLPRODUCTSDATATOSTORE: {
      return{
        ...state, mainMenu:action.payload[0],
        allProductsAtt: action.payload[1],
        allProducts: action.payload[2],
        comboInfo: action.payload[3],
        comboProducts: action.payload[4]
      }
    }
    case constants.LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case constants.OTP:{
      return{
        ...state, OTP: action.payload
      }
    }
    default:
      return state;
  }
};
export default impReducer;
