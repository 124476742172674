import {useDispatch, useSelector} from "react-redux";
import {mobileOrderRequest, verifyRedemptionPIN, baseURL} from "../commons/APIService";
import {constants} from "../commons/constants";
import {mobileOrder, paymentInformationUpdate, removeItemBasket} from "../actions";
import React, {useEffect, useState} from "react";
import BackButton from "./commons/BackButton";
import payAtCounter from "../images/payAtCounter.png";
import payByCreditCard from "../images/vias_Master.jpeg";
import {getSaleType} from "../commons/utility";

const Payment = (props) => {
    const state = useSelector(state => state.impReducer)
    let dispatch = useDispatch();
    let orderObject = props.location.state ?  props.location.state : state.mobileOrder;
    let saleType = state.mobileOrder && state.mobileOrder.request.transaction.saletypenum;//localStorage.getItem('typeForSaleType')
    const [redemptionPin, setRedemptionPin] = useState('')
    const [totalDiscount, setTotalDiscount] = useState()
    const [staffDiscount, setStaffDiscount] = useState()
    const [totalTender, setTotalTender]= useState()
    const [grandTotal, setGrandTotal]= useState()
    let [isRedeem, setIsRedeem] = useState(false)
    let [selection, setSelection] = useState({
        PBC:'none',
        PAC: 'none',
        CB : 'none',
        SV : 'none',
        PD:'none'
    })
    const [dicountedObject,setDiscountedObject] = useState()
    const [staffDiscountCheck,setStaffDiscountCheck] = useState(false)
    const [discountNum, setDiscountNum] = useState(0)
    const [voucher, setVoucher] = useState(false)

    useEffect(()=> {
        state.comboInfo.data.map(item =>{
            if(item.activity_sn == '2003'){
                setDiscountNum(parseFloat(item.discount_num).toFixed(2))
            }
        })
    }, [])
    useEffect(()=> {
        //console.log("@@@@@@@@@@@@@@@@@@@@@@@@",discountNum)
    },[discountNum])
    const onDiscountCheck = (obj) => {

        let discountOBJ = JSON.parse(JSON.stringify(obj))
        let tenderPrice = parseFloat(obj.request.transaction.payments.payment[0].tender).toFixed(2)
        if(staffDiscountCheck=== true){
            if (state.storeNote && state.storeNote.StaffDiscount && state.storeNote.StaffDiscount.Enabled){
                if(state.storeNote.StaffDiscount.StaffDiscountApplyToWholeCheck){
                    let discountedAmount= (parseFloat(tenderPrice/100 * discountNum).toFixed(2))
                    setTotalDiscount(discountedAmount)
                    setTotalTender(tenderPrice)
                    let newTender = parseFloat(tenderPrice) - discountedAmount
                    setGrandTotal(newTender)
                    setStaffDiscount(state.storeNote.StaffDiscount.Message3)
                    let discountProduct={}
                    state.comboInfo.data.map(item =>{
                        if(item.activity_sn == '2003'){
                            discountProduct = {
                                couponNum: item.discount_sn,
                                discount_amt:discountedAmount,
                                quantity: 1,
                                discount_name:item.activity_name,
                                discount_num: item.discount_num,
                            }
                        }
                    })
                    discountOBJ.request.transaction.payments.payment[0].tender= newTender.toFixed(2).toString();
                    discountOBJ.request.transaction.singleitems.singleitem.push(discountProduct)
                    setDiscountedObject(discountOBJ)
                }else if (!state.storeNote.StaffDiscount.StaffDiscountApplyToWholeCheck){
                    let amountTotal = 0.00;
                    let modifierPrice = 0.00;
                    let AddonPrice = 0.00;
                    state.basket.map(item => {
                        amountTotal = amountTotal + Number(item[0].prodInfo.price)
                        if (item[0].ModifierInfo && item[0].ModifierInfo.length>0){
                            item[0].ModifierInfo.map(m=>{
                                modifierPrice = modifierPrice + Number(m.price)
                            })
                        }
                        if (item[0].AddonInfo && item[0].AddonInfo.length > 0){
                            item[0].AddonInfo.map(m=>{
                                AddonPrice = AddonPrice+ Number(m.price)
                            })
                        }
                    })
                    //console.log('modifier', modifierPrice, 'addon', AddonPrice,'amount', amountTotal)
                    amountTotal = parseFloat(amountTotal) +
                        parseFloat(AddonPrice) + parseFloat(modifierPrice);
                    let  discount = amountTotal/100 * discountNum
                    let discountedPrice = amountTotal - discount
                    setTotalTender(tenderPrice)
                    setTotalDiscount(discount)
                    //console.log('discount', discountedPrice)
                    let newTender = (parseFloat(tenderPrice) - amountTotal) + discountedPrice
                    setGrandTotal(newTender)
                    setStaffDiscount(state.storeNote.StaffDiscount.Message3)
                    discountOBJ.request.transaction.payments.payment[0].tender= newTender.toFixed(2).toString();
                    setDiscountedObject(discountOBJ)
                }
            }
        }else if (voucher === true){
            let discountedAmount= (parseFloat(tenderPrice/100 *5).toFixed(2))
            setTotalDiscount(discountedAmount)
            setTotalTender(tenderPrice)
            let newTender = parseFloat(tenderPrice) - discountedAmount
            setGrandTotal(newTender)
            setStaffDiscount(state.storeNote.StaffDiscount.Message3)
            let discountProduct={}
            state.storeNote.CRM.campaigns.map(item =>{
                if(item.campaignType == 'voucher'){
                    discountProduct = {
                        couponNum: item.activitySn,
                        discount_amt:discountedAmount,
                        quantity: 1,
                        discount_name:item.name,
                        discount_num: "5",
                    }
                }
            })
            discountOBJ.request.transaction.payments.payment[0].tender= newTender.toFixed(2).toString();
            discountOBJ.request.transaction.singleitems.singleitem.push(discountProduct)
            setDiscountedObject(discountOBJ)
            //console.log(discountOBJ)
        }else if (staffDiscountCheck == false){

        }
    }
    useEffect(()=> {
        onDiscountCheck(orderObject)
    },[staffDiscountCheck])
    useEffect(()=> {
        onDiscountCheck(orderObject)
    },[voucher])
    let [showModal,setShowModal] = useState(false)
    let [payCode, setPayCode] = useState(null)
    let [confirmRedemption, setConfirmRedmption] = useState(false)
    let [wrongPin, setWrongPin] = useState(false)
    const onSelectType = (id, payCode) => {
        if (id == 60){
            setPayCode(payCode)
            setSelection({
              PBC:'solid var(--theme-font-dim-white)',
              PAC: 'none',
              CB : 'none',
              SV : 'none',
              PD:'none'
          })
        } else if (id == 23){
            setPayCode(payCode)
            setSelection({
                PBC:'none',
                PAC: 'solid var(--theme-font-dim-white)',
                CB : 'none',
                SV : 'none',
                PD:'none'
            })
        } else if (id == 2021){
            setPayCode(payCode)
            setStaffDiscountCheck(false)
            setSelection({
                PBC:'none',
                PAC: 'none',
                CB : 'solid var(--theme-font-dim-white)',
                SV : 'none',
                PD:'none'
            })
        } else if (id == 2022){
            setPayCode(payCode)
            setStaffDiscountCheck(false)
            setSelection({
                PBC:'none',
                PAC: 'none',
                CB : 'none',
                SV : 'solid var(--theme-font-dim-white)',
                PD:'none'
            })
        }else if (id == 2023){
            setPayCode(payCode)
            setStaffDiscountCheck(false)
            setSelection({
                PBC:'none',
                PAC: 'none',
                CB : 'none',
                SV : 'none',
                PD:'solid var(--theme-font-dim-white)'
            })
        }
        //setTimeout(()=>console.log("Pay Code",payCode), 500)
    }

    const onSelectPaymentType = (payType) =>{
        let newOrderObject = (staffDiscountCheck || voucher) ? dicountedObject : orderObject
        newOrderObject.request.transaction.payments.payment[0].methodnumber = Number(payType)
        newOrderObject.request.transaction.payments.payment[0].status ="pending"
        newOrderObject.request.transaction.membermobile = (state.userInfo && state.userInfo.CRM) && state.userInfo.CRM.phoneNumber
        newOrderObject.request.transaction.membercountrycode = (state.userInfo && state.userInfo.CRM) && state.userInfo.CRM.countryCallingCode
        newOrderObject.request.transaction.returnurl = baseURL + constants.PROCESSING
        newOrderObject.request.transaction.cancelurl = baseURL + constants.PAYMENT_CANCEL
        newOrderObject.request.transaction.timeouturl = baseURL + constants.PAYMENT_TIMEOUT
        if ((payType == 2022 || payType == 2021 || payType == 2023 ) && !confirmRedemption){
            setShowModal(true)
        }
        else if ((payType == 2022 || payType == 2021 || payType == 2023) && confirmRedemption){
            let obj = {
                countryCode:state.userInfo.CRM.countryCallingCode,
                mobile:state.userInfo.CRM.phoneNumber,
                pin:redemptionPin,
                storeId:state.store.store_id
            }
            verifyRedemptionPIN(obj).then((r) => {
                if (r.isPINVerified){
                    newOrderObject.request.transaction.membercountrycode = obj.countryCode
                    newOrderObject.request.transaction.membermobile = obj.mobile
                    newOrderObject.request.transaction.payments.payment[0].redemptionpin = obj.pin
                    newOrderObject.request.transaction.payments.payment[0].campaignType= (payType== 2022 ?"stored-value" : payType== 2021? "cashback": "prepaid")
                    dispatch(mobileOrder(newOrderObject))
                    mobileOrderRequest(newOrderObject).then((r) => {
                        console.log('befor condition ', r)
                        localStorage.setItem('cloudOrderNumber', r.cloud_order_number)
                        if (r.result_code == 200){
                            let storeMessage = JSON.parse(r.store_response)
                            localStorage.setItem('recipt',JSON.stringify(state.basket))
                            if(storeMessage.Response.ErrorNum == 0){
                                let paymentSuccessObj = state.paymentInformation;
                                    paymentSuccessObj.totalPrice = staffDiscountCheck ? totalTender :newOrderObject.request.transaction.payments.payment[0].tender
                                    paymentSuccessObj.staffDiscount = staffDiscountCheck
                                    paymentSuccessObj.discountedAmount = staffDiscountCheck ? totalDiscount :null
                                    paymentSuccessObj.grandTotal = staffDiscountCheck ? grandTotal :newOrderObject.request.transaction.payments.payment[0].tender
                                    paymentSuccessObj.recipt = state.basket
                                    paymentSuccessObj.cloudOrderNumber = r.cloud_order_number
                                dispatch(paymentInformationUpdate(paymentSuccessObj))
                                dispatch(removeItemBasket([]))
                                props.history.replace({
                                    pathname:constants.PAYMENTSUCCESSFUL,
                                    state: r
                                })
                            }else if (storeMessage.Response.ErrorNum == -1 || storeMessage.Response.ErrorNum == 999 ){
                                let s = r
                                s.isError = true
                                props.history.replace(
                                    {
                                        pathname: constants.PAYMENTSUCCESSFUL,
                                        state:{s,storeMessage}
                                    }
                                )
                            }
                        }else if (r.isError){
                            //console.log('i am here in error')
                            props.history.replace(
                                {
                                    pathname: constants.PAYMENTSUCCESSFUL,
                                    state: r
                                })
                        }
                    })
                }else if (!r.isPINVerified){
                    setWrongPin(true)
                }
            })
        } else {
            dispatch(mobileOrder(newOrderObject))
            mobileOrderRequest(newOrderObject).then((r) =>{
                localStorage.setItem('cloudOrderNumber', r.cloud_order_number)
                let type = getSaleType(state.mobileOrder.request.transaction.saletypenum)
                if(r && r.sessionID){
                    let obj = {
                        session: {
                            id: r.sessionID,
                        },
                        interaction: {
                            merchant: {
                                name: type,
                            },
                            displayControl: {
                                billingAddress: "HIDE",
                            }
                        }
                    };
                    if (payType == 2013) {
                        let paymentSuccessObj = state.paymentInformation;
                        paymentSuccessObj.totalPrice = staffDiscountCheck ? totalTender :newOrderObject.request.transaction.payments.payment[0].tender
                        paymentSuccessObj.staffDiscount = staffDiscountCheck
                        paymentSuccessObj.discountedAmount = staffDiscountCheck ? totalDiscount :null
                        paymentSuccessObj.grandTotal = staffDiscountCheck ? grandTotal :newOrderObject.request.transaction.payments.payment[0].tender
                        paymentSuccessObj.recipt = state.basket
                        paymentSuccessObj.cloudOrderNumber = r.cloud_order_number
                        dispatch(paymentInformationUpdate(paymentSuccessObj))
                        window.Checkout.configure(obj);
                        window.Checkout.showPaymentPage();
                    } else if (payType == 999){
                        if (r && r.result_code == 200){
                            let paymentSuccessObj = state.paymentInformation;
                            paymentSuccessObj.totalPrice = staffDiscountCheck ? totalTender :newOrderObject.request.transaction.payments.payment[0].tender
                            paymentSuccessObj.staffDiscount = staffDiscountCheck
                            paymentSuccessObj.discountedAmount = staffDiscountCheck ? totalDiscount :null
                            paymentSuccessObj.grandTotal = staffDiscountCheck ? grandTotal :newOrderObject.request.transaction.payments.payment[0].tender
                            paymentSuccessObj.recipt = state.basket
                            paymentSuccessObj.cloudOrderNumber = r.cloud_order_number
                            dispatch(paymentInformationUpdate(paymentSuccessObj))
                            dispatch(removeItemBasket([]))
                            props.history.replace({
                                pathname:constants.STORE_RESPONSE,
                                state: r
                            })
                        }
                    }
                } else if (r.result_code == 200){
                    let storeMessage = JSON.parse(r.store_response)
                    let paymentSuccessObj = state.paymentInformation;
                    paymentSuccessObj.totalPrice = staffDiscountCheck ? totalTender :newOrderObject.request.transaction.payments.payment[0].tender
                    paymentSuccessObj.staffDiscount = staffDiscountCheck
                    paymentSuccessObj.discountedAmount = staffDiscountCheck ? totalDiscount :null
                    paymentSuccessObj.grandTotal = staffDiscountCheck ? grandTotal :newOrderObject.request.transaction.payments.payment[0].tender
                    paymentSuccessObj.recipt = state.basket
                    paymentSuccessObj.salesType = saleType
                    paymentSuccessObj.cloudOrderNumber = r.cloud_order_number
                    dispatch(paymentInformationUpdate(paymentSuccessObj))
                    localStorage.setItem('recipt',JSON.stringify(state.basket))
                    if(storeMessage.Response.ErrorNum == 0){
                        dispatch(removeItemBasket([]))
                        props.history.replace({
                            pathname:constants.STORE_RESPONSE,
                            state: r
                        })
                    }else if (storeMessage.Response.ErrorNum == -1 || storeMessage.Response.ErrorNum == 999){
                        let s = r
                        s.isError = true
                        props.history.replace(
                            {
                                pathname: constants.STORE_RESPONSE,
                                state:s
                            }
                        )
                    }
                }else if (r.isError){
                    props.history.replace(
                        {
                            pathname: constants.STORE_RESPONSE,
                            state: r
                        })
                }
            })
        }

    }
    const createPaymentType = () => {
        return state.payType.map((item) => {
            return (
                <div className={((saleType == 5 || saleType == 2) && item.id == 23 && 'disabled') + " payment-option"} style={{border: item.id == 23 ? selection.PAC : selection.PBC}} onClick={() => {
                    if ((saleType == 5 || saleType == 2) &&  item.id == 23){}
                    else onSelectType(item.id, item.pay_code)
                }}>
                    <img className="payment-img" src={item.id == 23 ? payAtCounter : payByCreditCard}/>
                    <span className=" payment-title">{item.id == 23 ? "Pay At Counter" : "Pay By Credit  Card"}</span>
                </div>
            )
        })
    }
    const createAdvocadoPayment = () => {
        if (state.storeNote && state.storeNote.CRM && state.storeNote.CRM.enabled == true
            && state.userInfo && state.userInfo.CRM && state.userInfo.CRM.isMember == true){
                return state.storeNote.CRM.campaigns.map((item) => {
                    if (!state.isRedeemable){
                        if (item.image && item.enabled == true && item.campaignType != 'prepaid'){
                            return (
                                state.userInfo.CRM.campaignBalances.map(data => {
                                        if (data.campaignType === item.campaignType){
                                            return <div
                                                className={`payment-option ${Number(data.balance) < Number(orderObject.request.transaction.payments.payment[0].tender) && "disabled"}`}

                                                        style={{border:item.methodNumber == 2021 ? selection.CB: item.methodNumber == 2022 ? selection.SV : ''}}
                                                        onClick={()=> {
                                                            if (Number(data.balance) < Number(orderObject.request.transaction.payments.payment[0].tender)){
                                                                onSelectType(item.methodNumber, item.methodNumber)
                                                            }
                                                            else onSelectType(item.methodNumber, item.methodNumber)
                                                        }}>
                                                <img className="payment-img" src={item.image}/>
                                                <span className="payment-title">{item.name}</span>
                                                <span> ${data.balance}</span>
                                            </div>
                                        }
                                    })
                            )
                        }
                    }else if (state.isRedeemable){
                        if (item.image && item.enabled == true && item.campaignType == 'prepaid'){
                            return (
                              <div className="payment-option"
                                   style={{border:selection.PD}}
                                   onClick={()=> onSelectType(item.methodNumber, item.methodNumber)}>
                                  <img className="payment-img" src={item.image}/>
                                  <span className="payment-title">{item.name}</span>
                              </div>
                              )
                        }
                    }
                })
        }

    }

    const onInuptChange = (v) => {
        if(wrongPin)setWrongPin(false)
        setRedemptionPin(v)
        if(v.length == 4) {
            setConfirmRedmption(true)
        }
    }
    const onVerify = ()  => {
            onSelectPaymentType(payCode)
    }
    const modal = () => {
        return <div className='modal' style={{background:"rgba(0,0,0, 0.5)"}} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white" >Verify Redemption Pin</h5>
                        <button type="button" className='close text-white' onClick={()=> setShowModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type='password' className='form-control redemption-pin-input' maxLength={4} value={redemptionPin} onChange={e=>onInuptChange(e.target.value)} />
                        {wrongPin && <span className='d-block text-danger text-center mt-3'>You entered an invalid redemption pin.</span>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={()=> setShowModal(false)}>Close</button>
                        <button type="button" className={`btn btn-primary ${redemptionPin.length < 4 && "disabled"}`} onClick={onVerify}>Verify Pin</button>
                    </div>
                </div>
            </div>
        </div>
    }
    return (
        <div>
            <BackButton title="Payment Options" history={() => props.history.replace(constants.CART)}/>
            <div className="payment-screen">
                <div className='order-summary p-4 mb-2 d-flex flex-row justify-content-between bg-white'>
                    <h5 className="font-weight-bold">Total Price</h5>
                    <h5 className="font-weight-bold">
                        $ {(staffDiscountCheck || voucher) ? dicountedObject && parseFloat(dicountedObject.request.transaction.payments.payment[0].tender).toFixed(2) : parseFloat(orderObject.request.transaction.payments.payment[0].tender).toFixed(2)}</h5>
                </div>

                <div className="payment-options">
                    {props.history.location.pathname === constants.PAYMENT && !state.isRedeemable && createPaymentType()}
                    {createAdvocadoPayment()}

                    {(payCode== 2013 || payCode == 999) && state.storeNote && state.storeNote.StaffDiscount && state.storeNote.StaffDiscount.Enabled &&
                        <div className='form-group text-center'>
                            <div className="form-check mt-4">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={()=> {
                                   if (voucher) document.getElementById('exampleCheck2').checked = false
                                    setStaffDiscountCheck(!staffDiscountCheck)
                                    setVoucher(false)
                                }}/>
                                <label className="form-check-label" htmlFor="exampleCheck1">{state.storeNote.StaffDiscount.Message1}</label>
                            </div>
                            <p>{staffDiscountCheck && state.storeNote.StaffDiscount.Message2}</p>
                        </div>
                    }
                    {state.userInfo && state.userInfo.CRM && state.storeNote && state.storeNote.CRM && state.storeNote.CRM.enabled &&
                     state.storeNote.CRM.campaigns.map((item)=> {
                         if (item.campaignType == 'voucher') {
                             return state.userInfo.CRM.campaignBalances.map(i => {
                                 if (i.campaignType == 'voucher' && i.balance > 0){
                                     return (<div className='form-group text-center'>
                                         <div className="form-check mt-4">
                                             <input type="checkbox" className="form-check-input" id="exampleCheck2"
                                                    onChange={() => {
                                                        if (payCode== 2013 || payCode == 999) {
                                                            document.getElementById('exampleCheck1').checked = false
                                                        }
                                                        setStaffDiscountCheck(false)
                                                        setVoucher(!voucher)
                                                    }}/>
                                             <label className="form-check-label"
                                                    htmlFor="exampleCheck2">{item.name}</label>
                                         </div>
                                     </div>)
                                 }
                             })
                         }
                     })}

                </div>

                <button className={`btn btn-primary font-weight-bold w-75 m-auto ${!payCode && "disabled"}`} onClick={()=> {
                    if(payCode != null)onSelectPaymentType(payCode)
                    else {}
                }}> Confirm Payment </button>
                {showModal && modal()}

            </div>

        </div>
    )
}

export default Payment;

// id 23 pay at counter
