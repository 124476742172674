import {Button, Modal} from "antd";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {constants} from "../commons/constants";
import * as texts from '../commons/Texts'

const TopUpCredit = (props) => {
    const state = useSelector((state) => state.impReducer)
    let userInfo = state.userInfo && Object.keys(state.userInfo).length > 0 ? state.userInfo : null
    let [pin,setPin] = useState('')
    const getPin = (value) => {
        setPin(value)
    }
    let [errorMessage, setErrorMessage]= useState("")
    let voucher = null;

    if(userInfo && userInfo.CRM && userInfo.CRM.campaignBalances && props.campaign && props.campaign.autoApplyVoucherOnTopUp ){
        let campaignBalances = userInfo.CRM.campaignBalances
        campaignBalances.map((item,i) => {
            if(item.campaignType == 'voucher') voucher = item
        })
    }
    //console.log(props)
    const handleOk = () => {
        props.handleOK(props.amount, props.campaign, pin)
    }
    return (
        <Modal className="ant-d-custom-header" onCancel={props.handleCancel}
            footer={[<Button key="back" className="btn btn-secondary" onClick={props.handleCancel}>
            Cancel
            </Button>,
            <Button key="submit" type="primary" className="btn btn-primary" loading={props.loading} onClick={handleOk}>
                {props.okText}
            </Button>]}
            title='Top Up Credit' visible={props.isModalVisible}>
            <div className='form' >
                <div className='form-group' >
                    <label className='form-label'>Please Select amount</label>
                    <ul className='topUpList' >
                        {
                            props.topUps.map((item,i)=> <li className={'topUpSelect ' +(props.amount == item && 'selectActive' )} onClick={()=> props.amountSelected(item)} key={i} > <span>${item}</span>
                                {props.campaignType && props.campaignType == 'stored-value' ? <span> Get ${props.earnedValue[i]}</span> :
                                    <span> Get {props.earnedValue[i]}&nbsp; Items</span>}
                            </li>)
                        }
                    </ul>
                </div>
                {
                    voucher && voucher.balance > 0 && <div className='form-group'>
                        <label className='form-label'> {texts.ENTER_REDEMPTION_PIN}</label>
                        <input className='form-control' type='password' placeholder={texts.REDEMPTION_PIN_PLACEHOLDER} name="redemptionPin" maxLength={4} value={pin} onChange={(e)=> getPin(e.target.value)}/>

                    </div>
                }
            </div>

        </Modal>
    )
}

export default TopUpCredit;
