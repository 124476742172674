import React from "react";

const BackButton = (props) => (
	<div className={`d-flex flex-row align-content-center justify-content-between st-sc-banner fixed-top ${props.gradient || 'bg-primary'}`}>
		{props.history ? <i className="d-flex flex-column justify-content-center feather-chevron-left back-icon"
			onClick={props.history}/> : <i className="d-flex flex-column justify-content-center "/>}
		<h4 className="d-flex flex-column justify-content-center heading">{props.title}</h4>
		<div className="empty-div"></div>
	</div>
);
export default BackButton;
