import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, Redirect } from "react-router-dom";
import { Router } from "react-router";
import { constants } from "../../commons/constants";
import Loading from "../commons/Loading";
import SignUp from "./SignUp";
import VerifyOtp from "./VerifyOtp";
import Login from "./Login";
import RedemptionPin from "./RedemptionPin";
import PDPA from "./PDPA";
import ForgotPassword from "./ForgotPassword";

const videoSource = require("../../bg.mp4");
const VideoComponent = () => (
  <video loop autoPlay muted id="vid">
    <source src={videoSource.default} type="video/mp4" />
    <source src={videoSource.default} type="video/ogg" /> Your browser does not
    support the video tag.
  </video>
);

const Auth = (props) => {
  const state = useSelector((state)=> state.impReducer)
  let history = props.history
  const loader = useSelector((state) => state.impReducer.loader);
  useEffect(()=>{
    if(state.userInfo && state.userInfo.CRM){
      props.history.push(constants.STORE_HOME)
    }
  },[])
  return (
    <div>
      {loader && <Loading />}
      <div  className="login-page vh-100">
        <div className="bg-login vh-100" style={{background:`url(${state.storeNote.Images.LandingPage}) no-repeat center/cover`}}></div>
        {/*<VideoComponent />*/}
        <div className="d-flex align-items-center justify-content-center vh-100" >
          <div className="px-5 col-md-6 col-lg-6 ml-auto" style={{background:"rgba(255,255,255, 0.5)", padingTop: "10px", paddingBottom: "10px"}}>
            <Router history={history}>
              <Route exact path={constants.AUTH_ROUTE}>
                {state && state.locate && state.locate.hasTableService ? <Redirect to={constants.STORE_HOME}/> : <Redirect to={constants.LOGIN_ROUTE}/>}
              </Route>
              <Route exact path={constants.LOGIN_ROUTE} component={Login}>
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.SIGNUP_ROUTE} component={SignUp} >
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.VERIFY_OTP_ROUTE} component={VerifyOtp} >
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.REDEMPTION_PIN} component={RedemptionPin}>
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.PDPA_ROUTE} component={PDPA} >
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.PDPA_ROUTE_VERIFY} component={PDPA} >
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
              <Route path={constants.FORGOT_PASSWORD} component = {ForgotPassword}>
                {state && state.locate && state.locate.hasTableService && <Redirect to={constants.STORE_HOME}/>}
              </Route>
            </Router>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
