import { constants } from "../commons/constants";
import {
  getActivityComboWithPrice,
  getMenu,
  getProductAtt,
  getProductByStore,
  getStoreComboProduct
} from "../commons/APIService";
// import { setAsync } from "../commons/storageService";

export const norm = () => {
  return {
    type: constants.DEFAULT,
  };
};

export const locate = (data) => {
  return {
    type: constants.LOCATE,
    payload: data,
  }
}
export const tableServiceInfo = (data) => {
  return {
    type: constants.TABLE_SERVICE,
    payload: data,
  }
}
export const updateTableNumber = (data) => {
  return{
    type: constants.TABLE_NUMBER,
    payload: data,
  }
}

export const updateTableOrderHistory = (data) => {
  return {
    type: constants.TABLE_HISTORY,
    payload: data,
  }
}

export const login = (data) => {
  return {
    type: constants.LOGIN,
    payload: data,
  };
};
export const isMember = (data) => {
  return {
    type: constants.ISMEMBER,
    payload: data,
  };
};
export const pdpa = (data) => {
  return {
    type: constants.PDPA,
    payload: data,
  };
};
export const guest = (boolean) => {
  return {
    type: constants.GUEST,
    payload: boolean,
  };
};
export const LOADER = (value) => ({
  type: constants.LOADER,
  payload: value,
});
export const CREATEUSER = (data) => {
  return {
    type: constants.CREATE_USER,
    payload: data,
  };
};
export const USERINFO = (data) => {
  return {
    type: constants.USERINFO,
    payload: data,
  };
};
export const OTP = (number) => {
  return {
    type: constants.OTP,
    payload: number,
  };
};
export const storeToReduxStore = (data) =>{
  return {
    type: constants.STORE_INFO,
    payload: data,
  }
}
export const workTiming = (data) => {
  return {
    type: constants.WORKTIME,
    payload: data,
  }
}
export const showAlertPopUp = (data)=> {
  return {
    type:constants.ALERTPOPUP,
    payload: data,
  }
}
export const subMenuData = (data) =>{
  return {
    type: constants.SUB_MENU,
    payload:data,
  }
}
export const setRedeemable = (data) => {
  return {
    type: constants.REDEEMABLE,
    payload: data
  }
}
export const mainMenuData = (data) => {
  return {
    type: constants.GETMAINMENU,
    payload: { data: data, successMessage: "message" },
  };
};
export const allProductData= (data) => {
  return {
    type: constants.ALLPRODUCTS,
    payload: data
  }
}
export const createBasket = (data) => {
  return {
    type: constants.BASKET,
    payload: data
  }
}
export const removeItemBasket = (data) => {
  return {
    type: constants.BASKETITEMREMOVE,
    payload: data
  }
}
export const locationUpdate =(data)=> {
  return{
    type: 'LOCATIONUPDATE',
    payload: data
  }
}
export const selectedProductPrice = (data) => {
  return {
    type: constants.SELECTEDPRODUCTPRICE,
    payload: data
  }
}
export const mobileOrder = (data) => {
  return {
    type: constants.MOBILEORDER,
    payload:data
  }
}

export const paymentInformationUpdate = (data) =>{
  return {
    type: constants.PAYMENT_INFORMATION,
    payload:data
  }
}

export const allProductAtt = (data) =>{
  return {
    type: constants.ALLPRODUCTSATT,
    payload: data
  }
}

export const storeNote = (data) => {
  return {
    type: constants.STORE_NOTE,
    payload: data
  }
}
export const allProductDataToStore = (data) => {
  return{
    type: constants.ALLPRODUCTSDATATOSTORE,
    payload: data
  }
}
export const getMenuData = (storeId) => {
  return async function (dispatch) {
    dispatch(LOADER(true));
    Promise.all([
      getMenu(storeId),
      getProductAtt(storeId),
      getProductByStore(storeId),
      getActivityComboWithPrice(storeId),
      getStoreComboProduct(storeId),
    ]).then(resp =>  {
        dispatch(allProductDataToStore(resp))
      dispatch(LOADER(false))
    })
  };
};

export const getAllProdAtt = (storeId) => {
  return async function (dispatch) {
    dispatch(LOADER(true));
    getProductAtt((storeId)).then(resp => {
      dispatch(allProductAtt(resp))
      dispatch(LOADER(false))
    })
  }
}
