import {createRecipt} from "../commons/utility";
import {constants} from "../commons/constants";
import {paymentInformationUpdate, removeItemBasket} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {sendReciptToEmail} from "../commons/APIService";
import BackButton from "./commons/BackButton";
import {useHistory} from "react-router-dom";

const PaymentToStoreResponse =  (props) =>{
    let state = useSelector(state => state.impReducer)
    let dispatch = useDispatch();
    const [emailInfo, setEmailInfo] = useState("")
    const [nameInfo, setNameInfo] = useState("")
    const [receiptSend, setReceiptSend] = useState(null)
    const [showError, setShowError] = useState(false)
    const [ locationKeys, setLocationKeys ] = useState([props.location.pathname])
    const history = useHistory()
    //console.log(receiptSend)
    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
                setLocationKeys((keys) => [ location.key, ...keys ])
                //console.log('back button press', history.location)
                props.history.replace(constants.STORE_HOME)
                dispatch(removeItemBasket([]))
                dispatch(paymentInformationUpdate({}))
            }
        })
    }, [ locationKeys,])
    useEffect(()=> {
        //console.log(receiptSend)
    }, [receiptSend])
    let EFT = state.payment.EFT
    let selectedProduct = state.basket.length >0? state.basket : JSON.parse(localStorage.getItem("recipt"))
    let image = state.storeNote.Images.EFTPaymentSuccess
    let imageFailed = state.storeNote.Images.EFTPaymentSuccessOrderFailed
    let  product = state.allProducts.products
    let historyState = props.location.state;
    const onChangeInputHandler = (e) => {
        if(e.target.id === 'name'){
            setNameInfo(e.target.value)
        }else setEmailInfo(e.target.value)
    }
    const validateInput = () => {
        if (!emailInfo.match(constants.emailAddressRegex)) {
            setShowError(true)
            return false
        } else if (nameInfo.length < 2) {
            setShowError(true)
            return false
        }else {
            return true
        }
    }
    const onSendReciptButton = () => {
        if(validateInput()){
            let emailSendObj = {
                cloudordernumber: state && state.paymentInformation && state.paymentInformation.cloudOrderNumber && state.paymentInformation.cloudOrderNumber,
                name: nameInfo,
                email: emailInfo,
            }
            sendReciptToEmail(emailSendObj).then(r =>{
                //console.log(r)
                if (r.desc){
                    setReceiptSend(r.desc)
                }
            })
        }
    }
    return (
        <>
            {historyState.storeMessage || historyState.isError ?
                <>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='col-md-6 bg-white'>
                            <div className='text-center pb-3 pt-3'>
                                <h2>{(historyState.isError || historyState.r.isError) ? (historyState.displaytitle  ? historyState.displaytitle : historyState.r.displaytitle):"Order Failed"}</h2>
                                <p>{(historyState.isError || historyState.r.isError) ? (historyState.displaymsg ? historyState.displaymsg : historyState.r.displaymsg) :EFT.POSFailed }</p>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div>
                                    <img style={{width: '120px', maxHeight:'120px'}} src={imageFailed}/>
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div className='d-flex flex-row justify-content-center'>
                                    <span> {props.history.location.state.order_number ? "Order #":state.paymentInformation.cloudOrderNumber && "Cloud Order No:"}&nbsp;</span>
                                    <span><b>{props.history.location.state.order_number ? props.history.location.state.order_number : state.paymentInformation.cloudOrderNumber && state.paymentInformation.cloudOrderNumber }</b></span>
                                </div>
                            </div>
                                {(state.mobileOrder.request.transaction.saletypenum != 5 || state.mobileOrder.request.transaction.saletypenum != 2 ) && (state.userInfo && !state.userInfo.name) &&
                                    <div> { receiptSend == null ?
                                    <div>
                                        <div className="form-group">
                                            <label className='form-label' aria-labelledby='inputName'>Name</label>&nbsp;
                                            <input className='form-control' type='name' id='name' aria-label='inputName'
                                                   value={nameInfo} onChange={e => onChangeInputHandler(e)}/>
                                            {showError && nameInfo.length < 2 &&
                                                <p className='text-danger'> Please enter your full name</p>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'
                                                   aria-labelledby='inputEmail'>Email</label>&nbsp;
                                            <input className='form-control' type='email' id='email'
                                                   aria-label='inputEmail'
                                                   value={emailInfo} onChange={e => onChangeInputHandler(e)}/>
                                            {showError && (emailInfo.length == 0 ?
                                                <p className='text-danger'>Please enter your email address</p> :
                                                (!emailInfo.match(constants.emailAddressRegex) &&
                                                    <p className='text-danger'>Enter a valid email id </p>))}
                                        </div>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <button className='btn btn-primary  mt-1 mb-1' onClick={onSendReciptButton}>
                                                Get Receipt
                                            </button>
                                        </div>
                                    </div> :
                                    <div>
                                        <h1>Receipt Sent</h1>
                                    </div>} </div>
                                }
                            <button className='btn btn-block btn-facebook border rounded-pill' onClick={() => {
                                props.history.replace(constants.STORE_HOME)
                                dispatch(removeItemBasket([]))
                            }}>Back To Home
                            </button>
                        </div>

                    </div>
                </>
                :<>
                    {/*<BackButton title="Payment Successful" />*/}
                    <div className='d-flex justify-content-center align-items-center pl-2 pr-2'>
                        <div className='col-md-6 bg-white'>
                            <div className='text-center pb-3 pt-3'>
                                <h2 className='font-weight-bold'>{historyState.displaytitle}</h2>
                                <p style={{fontSize:'13px', paddingLeft:'2px'}}>{historyState.displaymsg}</p>
                                {/*<p style={{fontSize:'13px', paddingLeft:'2px'}}>{EFT.Success}</p>*/}
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div>
                                    <img style={{width: '120px', maxHeight:'120px'}} src={image}/>
                                </div>

                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div className='d-flex flex-row justify-content-center'>
                                    <span>Order #&nbsp; </span>
                                    <span><b>{props.history.location.state.order_number}</b></span>
                                </div>
                                {state.paymentInformation.label && <div className='w-50 d-flex flex-row justify-content-end'>
                                    <span>Pager #&nbsp; </span>
                                    <span><b>{state.paymentInformation.label}</b></span>
                                </div>}
                            </div>
                            <div style={{height:selectedProduct.length > 1 ? '308px' : '160px', overflowY:'auto'}}>
                                {createRecipt(constants.PAYMENTSUCCESS, selectedProduct, product)}
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <div className='d-flex flex-row justify-content-between'>
                                    <strong>Total Price</strong>
                                    <strong>${Number(state.paymentInformation.totalPrice).toFixed(2)}</strong>
                                </div>
                                {state.paymentInformation.staffDiscount &&<><div className='d-flex flex-row justify-content-between'>
                                    <strong>Staff Discount</strong>
                                    <strong>{state.storeNote.StaffDiscount.Message3}</strong>
                                </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <strong>Total Discount</strong>
                                        <strong>$ {state.paymentInformation.discountedAmount}</strong>
                                    </div></>}
                                <div className='d-flex flex-row justify-content-between'>
                                    <strong>Grand Total</strong>
                                    <strong>${Number(state.paymentInformation.grandTotal).toFixed(2)}</strong>
                                </div>
                                <hr/>
                                {(state.mobileOrder.request.transaction.saletypenum != 5 && state.mobileOrder.request.transaction.saletypenum != 2) && (state.userInfo && !state.userInfo.CRM) && receiptSend == null && <div>

                                    <div className="form-group">
                                        <label className='form-label' aria-labelledby='inputName'>Name</label>&nbsp;
                                        <input className='form-control' type='name' id='name' aria-label='inputName'
                                               value={nameInfo} onChange={e => onChangeInputHandler(e)}/>
                                        {showError && nameInfo.length < 2 && <p className='text-danger'> Please enter your full name</p>}
                                    </div>
                                    <div className="form-group">
                                        <label className='form-label' aria-labelledby='inputEmail'>Email</label>&nbsp;
                                        <input className='form-control' type='email' id='email' aria-label='inputEmail'
                                               value={emailInfo} onChange={e => onChangeInputHandler(e)}/>
                                        {showError && (emailInfo.length ==0?
                                            <p className='text-danger'>Please enter your email address</p> :
                                            (!emailInfo.match(constants.emailAddressRegex) &&<p className='text-danger'>Enter a valid email id </p>))}
                                    </div>
                                    <div className='d-flex flex-column justify-content-between'>
                                        <button className='btn btn-primary  mt-1 mb-1' onClick={onSendReciptButton}>Get
                                            Receipt
                                        </button>
                                    </div>
                                </div>}
                                {receiptSend != null && <div className='text-center'>
                                    <h5 className='text-success pt-1 pb-2'> {receiptSend} </h5>
                                </div>}
                            </div>
                            <button className='btn btn-block btn-facebook mb-5 p-2' onClick={() => {
                                props.history.replace(constants.STORE_HOME)
                                dispatch(removeItemBasket([]))
                                dispatch(paymentInformationUpdate({}))
                            }}><i className='feather-home'/>&nbsp;Home
                            </button>

                        </div>

                    </div>
                </>
            }
        </>
    )
}

export default PaymentToStoreResponse;
