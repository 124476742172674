import { Drawer, Menu } from "antd";
import { LeftOutlined} from "@ant-design/icons"
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { constants } from "../../commons/constants";
import {guest, login, USERINFO} from "../../actions";
import React from "react";
import { useDispatch } from "react-redux";
import {remove} from "../../commons/storageService";

const Sidemenu = (props) => {
  const mystate = useSelector((state) => state.impReducer);
  const dispatch = useDispatch();
  //console.log("sideMenu",props)
  const checkCampaignStatus = (userCampaignBalance, storeNote) => {
    if (storeNote && storeNote.CRM && storeNote.CRM.campaigns) {
      storeNote.CRM.campaigns.map((item) => {
        if (userCampaignBalance.campaignType == item.campaignType) {
          userCampaignBalance.enabled = item.enabled;
        }
      });
    }
    return userCampaignBalance;
  };
  return (
    <Drawer
        // swipeOpenEnabled ={true}
        swipeCloseEnabled ={true}
      closable={true}
      closeIcon={<LeftOutlined />}
      placement="left"
      onClose={props.onClose}
      visible={props.visibility}
      className="left-drawer"
    >
      {mystate.userInfo && (
        <>
            { mystate.userInfo.name &&
              <div className="header-top-sec fixed-top">
                <div className="header-top-left">
                  <img
                    src="/img/avatar.png"
                    className="user-avatar"
                  />
                </div>
                <div className="header-top-right">
                  <h5 className="title">{mystate.userInfo.name}</h5>
                  <p className="desc">
                    {mystate.userInfo &&
                      mystate.userInfo.CRM &&
                      mystate.userInfo.CRM.countryCallingCode &&
                      "+"}
                    {mystate.userInfo &&
                      mystate.userInfo.CRM &&
                      mystate.userInfo.CRM.countryCallingCode}
                    {mystate.userInfo.CRM && mystate.userInfo.CRM.phoneNumber}
                  </p>
                </div>
              </div>
            }
          {mystate.userInfo &&
            mystate.userInfo.CRM &&
            mystate.userInfo.CRM.isMember && (
              <div>
                <div className="campaign-heading">
                  {mystate.userInfo.CRM.type} Membership Tier: {mystate.userInfo && mystate.userInfo.CRM && mystate.userInfo.CRM.membership && mystate.userInfo.CRM.membership.membershipTagName}
                </div>
                <div className="campaign-sect">
                  {mystate.userInfo &&
                    mystate.userInfo.CRM &&
                    mystate.userInfo.CRM.isMember &&
                    mystate.userInfo.CRM.campaignBalances &&
                    mystate.userInfo.CRM.campaignBalances.map((item, i) => {
                      item = checkCampaignStatus(item, mystate.storeNote);
                      if (item.campaignType === "prepaid" && item.enabled)
                        return (
                          <div key={i} className="campaign-item ">
                            <div className={"campaign-item-sect-top " + (item.balance == 0 && item.campaignType != "stored-value" ? "disable-campaign" : "")}>
                              <span className="key-campaign">{item.name}</span>
                              <span className="val-campaign">
                                {item.balance}
                              </span>
                            </div>
                            <ul className="list-group list-group-flush campaign-actions">
                              <li onClick={(e) => {
                                props.onPrepaidTopup(e, item)
                                      props.onClose()
                                    }
                                }
                                className="action-campaign list-group-item"
                              >
                                Top Up
                              </li>
                              {item.balance > 0 && (
                                <li onClick={() => {
                                    props.onCampaignTypeClick(item);
                                    props.onClose();
                                  }}
                                  className="action-campaign list-group-item"
                                >
                                  Redeem
                                </li>
                              )}
                            </ul>
                          </div>
                        );
                      else if (item.enabled)
                        return (
                          <div
                            key={i}
                            className={
                              "campaign-item " +
                              (item.balance == 0 &&
                              item.campaignType != "stored-value"
                                ? "disable-campaign"
                                : "")
                            }
                          >
                            <div className="campaign-item-sect-top">
                              <span className="key-campaign">{item.name}</span>
                              <span className="val-campaign">
                                {item.campaignType == "cashback" ||
                                item.campaignType == "stored-value"
                                  ? "$"
                                  : ""}
                                {item.balance}
                              </span>
                            </div>

                              {(item.campaignType == "stored-value" ||
                                item.campaignType == "prepaid") && (
                                <ul className="list-group list-group-flush campaign-actions">
                                  <li
                                    onClick={(e) => {
                                        props.onPrepaidTopup(e, item)
                                        props.onClose();
                                      }
                                    }
                                    className="action-campaign list-group-item"
                                  >
                                    Top Up
                                  </li>
                                </ul>
                              )}
                          </div>
                        );
                    })}
                </div>
              </div>
            )}
        </>
      )}
      {((mystate.userInfo && !mystate.userInfo.name) && mystate.locate && !mystate.locate.hasTableService) && (
        <div className="header-not-login fixed-top">
          <div>
            <p className="desc">
              You are not logged in. Please{" "}
              <Link
                className="links"
                to={constants.LOGIN_ROUTE}
                onClick={() => {
                  dispatch(login(false));
                  dispatch(guest(false));
                }}
              >
                Login
              </Link>{" "}
              or{" "}
              <Link
                to={constants.SIGNUP_ROUTE}
                onClick={() => {
                  props.history.push(constants.SIGNUP_ROUTE);
                }}
                className="links"
              >
                Register
              </Link>{" "}
              to enjoy members benefits.
            </p>
          </div>
        </div>
      )}
        <Menu
            mode="inline"
            theme="light"
            className="ant-menu"
            defaultSelectedKeys={['1']}
        >
            <Menu.Item key="1"><Link to={constants.STORE_HOME} onClick={props.onClose}>Home</Link></Menu.Item>
            <Menu.Item key="2"><Link to={constants.TNC} onClick={props.onClose}>Terms and Conditions</Link></Menu.Item>
            <Menu.Item key="3"><Link to={constants.REFUND_POLICY} onClick={props.onClose}>Refund / Cancelation Policies</Link></Menu.Item>
            <Menu.Item key="4"><Link to={constants.PRIVACY} onClick={props.onClose}>Privacy Policies</Link></Menu.Item>
            <Menu.Item key="5"><Link to={constants.FEEDBACK_CONTACT} onClick={props.onClose}>Contact Us</Link></Menu.Item>
            { mystate.userInfo && mystate.userInfo.name && <Menu.Item key="9"><a
                onClick={() => {
                    dispatch(login(false));
                    remove("USER_INFO");
                    dispatch(USERINFO({}))
                    props.history.push(constants.LOGIN_ROUTE)
                }}>Logout</a></Menu.Item> }

            { (mystate.userInfo && !mystate.userInfo.name && mystate.locate && !mystate.locate.hasTableService) && <Menu.Item key="7"><a
                onClick={() => {
                    dispatch(login(false))
                    props.history.push(constants.LOGIN_ROUTE)
                }}>Login</a></Menu.Item> }

            {(mystate.userInfo && !mystate.userInfo.name && mystate.locate && !mystate.locate.hasTableService) && <Menu.Item key="8"><a
                                                            onClick={() => {
                                                                props.history.push(constants.SIGNUP_ROUTE);
                                                            }}>Sign Up</a></Menu.Item> }

        </Menu>

    </Drawer>
  );
};

export default Sidemenu;
