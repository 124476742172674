import axios from "axios";
import { constants } from "./constants";
import {LOADER, locate} from "../actions";
import { get, set } from "./storageService";
import store from "../store";
import md5 from "md5";
import _ from "lodash";
import {useSelector} from "react-redux";
import impReducer from "../reducers/impReducer";
let dispatch = store.dispatch;

let apiDetails = null;

export const storeCredentials = () => get(constants.STORE_CREDENTIALS);
export const storeID = () => get(constants.STORE_ID)

export const LOCATE_STORE = "https://mobile.jeffy.sg/api/entry/getstoreinfo/";
export const GET_ORDER_HISTORY = "https://mobile.jeffy.sg/api/table/GetOrderedItems/"
export const GET_STORE_URL = "api/mobile/getStoreByDeviceNo";
export const USER_LOGIN_URL = "api/user/userLogin";

export let baseURL = (window.location.hostname == 'localhost')
  ? 'http://' + window.location.host
  : 'https://' + window.location.host

export const FORGOT_PASSWORD_URL = "api/user/forgotPassword";
export const USER_REGISTER_URL = "api/user/userRegister";
export const GET_OTP_URL = "api/user/getOTP";
export const GET_PRODUCT_BY_STORE_URL = "api/mobile/getProductByStore"
export const GET_PDPA_CONTENT = "api/user/advocadoGetPDPA";
export const ACTIVATE_ADVOCADO_USER = "api/user/advocadoActivateUser";
export const ADVOCADO_TOP_UP = "api/mobile/advocadoTopUp";
export const ADVOCADO_TOP_UP2 = "api/mobile/advocadoTopUp";
export const ACTIVITY_COMBO_WITH_PRICE = "api/mobile/getActivityComboWithPrice";
export const STORE_COMBO_PRODUCT = "api/mobile/getStoreComboProduct";
export const GET_MENU = "api/mobile/getMenu";
export const GET_USER = "api/user/getCustomerInfo";
export const GET_TNC = "api/mobile/getTNC";
export const GET_PRIVACY = "api/mobile/getPrivacyPolicy";
export const GET_REFUND_POLICY = "api/mobile/getCancelRefunds";
export const GET_FEEDBACK = "api/mobile/getFeedback";
export const VERIFY_REDEMPTION_PIN = "api/user/advocadoVerifyPIN";
export const FORGOT_REDEMPTION_PIN = "api/user/advocadoForgotPIN";
export const SYNC_MOBILE_ORDER = "api/mobile/sendMobileOrder";
export const PAYMENT_UPDATE = "api/mobile/paymentUpdate";
export const GET_PRODUCT_ATT = "api/mobile/getProductAtt";
export const SEND_RECIPT_EMAIL = "api/mobile/sendEmailReceipt"

export const getMandatoryHeaders = (credentials, uri, body) => {
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  const sign = md5(
    credentials.appKey + credentials.appSecret + uri + body + secondsSinceEpoch
  ).toString();

  const headers = {
    "Content-Type": "application/json",
    "Tenant-Id": `${credentials.tenantID}`,
    time: `${secondsSinceEpoch}`,
    sign: `${sign}`,
    appkey: `${credentials.appKey}`,
    "Serial-Number": `${credentials.deviceID}`,
    "Sale-Channel": "APP",
    "Update-Channel": "APP",
  };
  return headers;
};

/*
 * Params: (method: POST, url, data: data)
 */
export const getProductdata = (data) => {
  let options = {
    data: data,
    url: GET_PRODUCT_ATT,
    method: constants.POST,
  };
  return httpRequest(options);
};

export const advocadoTopUpCredit = (data) => {
  let options = {
    data: data,
    url: ADVOCADO_TOP_UP2,
    method: constants.POST,
    showLoading:true,
  }
  return httpRequest(options)
}

export const sendReciptToEmail = (data) => {
  let options = {
    data: data,
    url: SEND_RECIPT_EMAIL,
    method : constants.POST,
    showLoading: true,
  }
  return httpRequest(options)
}

export const mobileOrderRequest = (orderObj) => {
  let options = {
    data: orderObj,
    url: SYNC_MOBILE_ORDER,
    method: constants.POST,
    showLoading:true,
  }
  return httpRequest(options)
}
export const paymentUpdate = (obj) => {
  let options = {
    data: obj,
    url: PAYMENT_UPDATE,
    method: constants.POST,
    showLoading:true,
  }
  return httpRequest(options)
}

export const getTnc = (data) => {
  let options = {
    data: data,
    url: GET_TNC,
    method: constants.POST,
    showLoading: true,
  };
  return httpRequest(options);
};

export const getFeedback = (data) => {
  let options = {
    data: data,
    url: GET_FEEDBACK,
    method: constants.POST,
    showLoading: true,
  };
  return httpRequest(options);
};

export const getPrivacy = (data) => {
  let options = {
    data: data,
    url: GET_PRIVACY,
    method: constants.POST,
    showLoading: true,
  };
  return httpRequest(options);
};

export const getRefundPolicy = (data) => {
  let options = {
    data: data,
    url: GET_REFUND_POLICY,
    method: constants.POST,
    showLoading: true,
  };
  return httpRequest(options);
};

export const getCustomerInfo = (data) => {
  let options = {
    data:data,
    url: GET_USER,
    method: constants.POST,
  }
  return httpRequest(options)
}

export const httpRequest = (options) => {
  if (options.showLoading) dispatch(LOADER(true));
  const credentials = storeCredentials();
  let url = `${credentials.apiDomain}/${options.url}`;
  const config = {};
  config.data = JSON.stringify(options.data) || null;
  config.url = url || "";
  config.headers = getMandatoryHeaders(
    credentials,
    options.url,
    JSON.stringify(options.data)
  );
  config.method = options.method || constants.POST;
  return axios(config)
    .then((data) => {
      if (dispatch && options.showLoading) dispatch(LOADER(false));
      if (data && data.data) {
        if (data.data.result_code != "200" && data.data.result_code != "7103")
          data.data.isError = true;
        else if (data.data.result_code == "7103") return data.data;
        else if (data.data.result_code == "200") {
          if (options.storeAsSync) {
            set(options.storeAsSync, data.data);
          }
          if (options.storeInDB) {
            set(options.storeInDB, data.data);
            return data.data;
          }

        }
      }
      return data.data;
    }).catch((e) => {
      e.isError = true;
        if (dispatch && options.showLoading) dispatch(LOADER(false));
      //console.log("error status", e.response);
      return e;
    });
};

//Reset Password

export async function resetPassword(params) {
  let options = {
    data: params,
    url: FORGOT_PASSWORD_URL,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
  }
  return httpRequest(options);
}

// GET MENU
export const getMenu = (id) => {
  let state = store.getState();
  //console.log(state)
  let param = { store_id: id, redeemable: (state.impReducer.isRedeemable? 1: 0)};
  let options = {
    data: param,
    url: GET_MENU,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: false,
    storeInDB: "MENU"
  };
  return httpRequest(options).then((r) => {
    //console.log("getmenu", r);
    let menu = r && r.result_code == "200" && r.menu ? r.menu : null;
    return menu.sort((a, b) =>
      a.category_sn > b.category_sn ? 1 : b.category_sn > a.category_sn ? -1 : 0
    );
  });
};

export const getProductAtt = (id) => {
 const param = { store_id : id};
 let options = {
   data: param,
   url: GET_PRODUCT_ATT,
   method: constants.POST,
   showErrorMsg: false,
   showSuccessMsg: false,
   showLoading: false,
   storeInDB: "PRODUCT_BY_ATT"
 }
 return httpRequest(options)
}

export const getProductByStore = async (id) => {
  const param = {store_id : id};
  let options = {
    data: param,
    url: GET_PRODUCT_BY_STORE_URL,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: false,
    storeInDB: "PRODUCT_BY_STORE"
  }
  // return httpRequest(options);
  let resp = await httpRequest(options);
  resp.products = _.uniq(resp.products, "product_sn");
  //console.log("Get Product Result: ", resp);
  return resp;
}

export const  getActivityComboWithPrice = (id) => {
  const param = {store_id : id};
  let options = {
    data: param,
    url: ACTIVITY_COMBO_WITH_PRICE,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: false,
    storeInDB: "COMBO_ITEM_WITH_PRICE"
  }
  return httpRequest(options)
}

export const getStoreComboProduct = (id) => {
  const param = {store_id : id}
  let options={
    data: param,
    url: STORE_COMBO_PRODUCT,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: false,
    storeInDB: "DRINKS_FOR_COMBO"
  }
  return httpRequest(options)
}

export const getOrderHistory = (id, sid) => {
  let config = {
    method: 'get',
    url: GET_ORDER_HISTORY+ id + "/" + sid
  }
  return axios(config)
      .then((res) => {
        //console.log('order history', res)
        return res.data;
      })
      .catch((e) => {
        e.isError = true;
        return e;
      });
}

export const locateStoreById = (id, sid) => {
  localStorage.clear();
  let config
  if(id && sid){
    config = {
      method: "get",
      url: LOCATE_STORE + id + "/" + sid,
    }
  }else if (id){
    {
      config = {
        method: "get",
        url: LOCATE_STORE + id,
      }
    }
  }
  //console.log("config",config)
  return axios(config)
    .then((res) => {
      //console.log("res",res)
      dispatch(locate(res.data))
      set(constants.STORE_CREDENTIALS, res.data)
      return res.data;
    })
    .catch((e) => {
      e.isError = true;
      return e;
    });
};

// Register User
export const registerUser = (params) => {
  let options = {
    url: USER_REGISTER_URL,
    data: params,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
  };
  return httpRequest(options);
};
// STORE INFORMATION RECEIVED
export const getStoreInfo = (data) => {
  let options = {
    url: GET_STORE_URL,
    data: data,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
    storeInDB: constants.STORE_INFO,
  };
  return httpRequest(options);
};

// OTP CALL
export async function getOTP(data) {
  let options = {
    url: GET_OTP_URL,
    data: data,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
  };
  return httpRequest(options);
}

// ACTIVATE ADVOCADO USER

export async function activateAdvocadoUser(params) {
  let options = {
    url: ACTIVATE_ADVOCADO_USER,
    data: params,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
  };
  return httpRequest(options);
}

//Verify Redemption Pin
export async function verifyRedemptionPIN(params) {
  let options = {
    url: VERIFY_REDEMPTION_PIN,
    data: params,
    method: constants.POST,
    showLoading:true
  }

  return httpRequest(options);
}

// LOGIN SUCCESSFULLY
export const userLogin = (data) => {
  let options = {
    url: USER_LOGIN_URL,
    data: data,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
    storeInDB: constants.USER_INFO,
    storeAsSync: constants.USER_INFO
  };
  return httpRequest(options);
};

// PDPA CONTENT RECEIVED SUCCESSFULLY
export async function getPDPAContent(params) {
  let options = {
    url: GET_PDPA_CONTENT,
    data: params,
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: true,
  };
  return httpRequest(options);
}

export const getRedeemableMenu = async () => {
  let options = {
    url: GET_MENU,
    data: JSON.stringify({ store_id: storeID(), redeemable: 1 }),
    method: constants.POST,
    showErrorMsg: false,
    showSuccessMsg: false,
    showLoading: false,
  };
  return httpRequest(options);
};

export async function getStoreByDeviceNo() {
  const data = JSON.stringify({ deviceNo: `${apiDetails().deviceID}` });
  const headers = getMandatoryHeaders(GET_STORE_URL, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(GET_STORE_URL),
    headers: headers,
    data: data,
  };

  return axios(config);
}


export async function preAdvocadoTopUp(params) {
  const data = JSON.stringify(params);
  const headers = getMandatoryHeaders(ADVOCADO_TOP_UP, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(ADVOCADO_TOP_UP),
    headers: headers,
    data: data,
  };

  return axios(config);
}

export async function postAdvocadoTopUp(params) {
  const data = JSON.stringify(params);
  const headers = getMandatoryHeaders(ADVOCADO_TOP_UP, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(ADVOCADO_TOP_UP),
    headers: headers,
    data: data,
  };

  return axios(config);
}

export async function getUserInfo() {
  let USER_INFO = localStorage.getItem("USER_INFO")
    ? JSON.parse(localStorage.getItem("USER_INFO"))
    : null;
  if (!USER_INFO) {
    return null;
  }
  const obj = {
    storeId: storeID(),
    countryCode: USER_INFO.CRM.countryCallingCode,
    mobile: USER_INFO.CRM.phoneNumber,
  };

  const data = JSON.stringify(obj);
  const headers = getMandatoryHeaders(GET_USER, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(GET_USER),
    headers: headers,
    data: data,
  };

  return axios(config).then((user) => {
    if (
      user &&
      user.data &&
      user.data.result_code &&
      user.data.result_code === "200"
    ) {
      localStorage.setItem("USER_INFO", JSON.stringify(user.data));
      return user.data;
    } else {
      return null;
    }
  });
}

export async function generateOrderToStore(params) {
  const data = JSON.stringify(params);
  const headers = getMandatoryHeaders(SYNC_MOBILE_ORDER, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(SYNC_MOBILE_ORDER),
    headers: headers,
    data: data,
  };

  return axios(config);
}


export async function forgotRedemptionPIN(params) {
  const data = JSON.stringify(params);
  const headers = getMandatoryHeaders(FORGOT_REDEMPTION_PIN, data);
  const config = {
    method: "post",
    url: `${apiDetails().apiDomain}/`.concat(FORGOT_REDEMPTION_PIN),
    headers: headers,
    data: data,
  };

  return axios(config);
}

export async function prepareForProductOrder() {
  let obj = { store_id: storeID() };
  return Promise.all([
    getMenu(obj),
    getActivityComboWithPrice(obj),
    getStoreComboProduct(obj),
  ]);
}
