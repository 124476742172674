// import * as localforage from "localforage";

export const get = (keyOrKeys) => {
    if (keyOrKeys) {
        // If only one  key is passed
        if  (typeof keyOrKeys === 'string') {
            return getItem(keyOrKeys);
        } else  if (typeof keyOrKeys === 'object') { // if array of  keys is passed
            let arrayOfVals = [];
            if (keyOrKeys.length > 0) {
                keyOrKeys.forEach(key => {
                    arrayOfVals.push(getItem(key))
                })
            }
            return arrayOfVals;
        }
    }
}

export const set = (keyOrKeys, valueOrValues) => {
    if (keyOrKeys) {
        // If only one  key is passed
        if  (typeof keyOrKeys === 'string') {
            return setItem(keyOrKeys, valueOrValues);
        } else  if (typeof keyOrKeys === 'object') { // if array of  keys is passed
            let arrayOfVals = [];
            if (keyOrKeys.length > 0) {
                keyOrKeys.forEach((key, i) => {
                    arrayOfVals.push(setItem(key, valueOrValues[i]))
                })
            }
            return arrayOfVals;
        }
    }
}

export const remove = (keyOrKeys) => {
    if (keyOrKeys) {
        // If only one  key is passed
        if  (typeof keyOrKeys === 'string') {
            removeItem(keyOrKeys);
        } else  if (typeof keyOrKeys === 'object') { // if array of  keys is passed
            if (keyOrKeys.length > 0) {
                keyOrKeys.forEach(key => {
                    removeItem(key)
                })
            }
        }
    }
    return true;
}

const getItem = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
};

const setItem = (key, value) => {
    let val = JSON.stringify(value);
    return window.localStorage.setItem(key, val);
};

const removeItem = (key) => {
    window.localStorage.removeItem(key);
};