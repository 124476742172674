import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
const loadState = () => {
    try {
        let reducerValExist = false
        let storeResp = {
            impReducer: {}
        };
        let address = (window.location.pathname).toString().match(/locate./g);
        //console.log("*****************",address)
        for (const  ls in localStorage) {
            if (ls.match(/^reducer./g) || address != null) {
                reducerValExist = true
                storeResp.impReducer[ls.replace('reducer.', '')] = JSON.parse(localStorage[ls]);
            }
        }
        return reducerValExist  ? storeResp : undefined;
        //
        // const serializedState = localStorage.getItem('state');
        // if(serializedState === null) {
        //     return undefined;
        // }
        // return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};
export const saveState = (state) => {
    // console.log("store state *****",state)
    try {
        const reducer = state.impReducer || {};
        //console.log("REDUCER: ", reducer);
        for (const s in reducer) {
            if  (s!='allProductsAtt') {
                // console.log(s, reducer[s]);
                localStorage.setItem(`reducer.${s}`, JSON.stringify(reducer[s]));
            }
        }
        // const serializedState = JSON.stringify(state);
        // localStorage.setItem('state', serializedState);
    } catch (e) {
        // ignore write errors
        console.log(e);
    }
};
const persistedState = loadState();
const composedEnhancer = compose(
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const store = createStore(rootReducer,persistedState, composedEnhancer);
store.subscribe(() => {
    saveState(store.getState());
});
export default store;
