import React from 'react'
import {Alert, Button, Space, Popconfirm, message} from 'antd'

const AlertRemoveProduct = (props) => {
    return (
        <div style={{position:'fixed', left:'50%', right: '50%'}}>
            {/*<Alert*/}
            {/*    message={props.infoText}*/}
            {/*    description={props.description}*/}
            {/*    type="info"*/}
            {/*    action={*/}
            {/*        <Space direction="vertical">*/}
            {/*            <Button size="small" type="primary" onClick={props.accept}>*/}
            {/*                Accept*/}
            {/*            </Button>*/}
            {/*            <Button size="small" danger type="ghost" onClick={props.setCofirmDelete}>*/}
            {/*                Decline*/}
            {/*            </Button>*/}
            {/*        </Space>*/}
            {/*    }*/}
            {/*/>*/}
            <Popconfirm
                title={props.description}
                placement="top"
                visible={props.visible}
                onConfirm={props.accept}
                onCancel={props.setCofirmDelete}
                okText="Yes"
                cancelText="No"
            >
            </Popconfirm>
        </div>
    )
}

export default AlertRemoveProduct
