export const WELCOME = 'Welcome'
export const LOGIN_SCREEN_MESSAGE = 'Sign in or Continue as guest'
export const ENTER_PHONE_NO = 'Mobile Number'
export const PHONE_NO_PLACEHOLDER = ''
export const ENTER_LOGIN_PIN = 'Login PIN (6 digits)'
export const LOGIN_PIN_PLACEHOLDER = 'PIN'
export const CONFIRM_LOGIN_PIN_PLACEHOLDER = 'Confirm PIN'
export const ORDERHISTORY= 'Order History'

export const LOGIN_TEXT = 'Login'
export const FORGOT_PIN = 'Forgot PIN?'
export const DONT_HAVE_AN_ACCOUNT = 'Don\'t have an account...'
export const CREATE_ACCOUNT_TEXT = 'Create Account'

export const NAME = 'Name'
export const NAME_PLACEHOLDER = ''
export const ENTER_EMAIL_ADDRESS = 'Email Address'
export const EMAIL_ADDRESS_PLACEHOLDER = ''
export const REGISTER = 'Register'

export const AN_OTP_HAS_BEEN_SENT = 'An OTP has been sent to your phone number. Please enter it below. If you have entered an incorrect phone number or want to change it please refresh this page and re-register with the correct phone number.'
export const ENTER_OTP = 'Enter OTP'
export const OTP_PLACEHOLDER = 'OTP'
export const VERIFY_ACCOUNT = 'Verify Account'
export const ALREADY_HAVE_AN_ACCOUNT = 'Already have an account...'

export const ENTER_VALID_PHONE_NUMBER = 'Please enter a valid phone number'
export const PIN_MUST_CONTAIN_6_NUMBERS = 'Your PIN must contain 6 digits'
export const ENTER_VALID_EMAIL = 'Please enter a valid email address'
export const ENTER_VALID_OTP = 'You entered an invalid OTP'

export const COUNTRY_CODE_PLACEHOLDER = 'Code'
export const ENTER_PHONE_NO_MSG = 'Please enter phone number'
export const RETYPE_PIN_PLACEHOLDER = 'Confirm PIN'
export const PASSWORD_NOT_MATCH = 'PIN and Confirm PIN does not match'
export const ENTER_EMAIL_ADDRESS_MSG = 'Please enter email address'

export const ENTER_NAME = 'Please enter your name'
export const GET_OTP = 'Get OTP'
export const RESET_PASSWORD = 'Reset PIN'
export const ENTER_NEW_PIN = 'Enter New PIN'
export const PDPA_TITLE = 'Personal Data Protection Act'

export const CREATE_REDEMPTION_PIN = 'Create Redemption PIN'
export const ENTER_REDEMPTION_PIN = 'Enter Redemption PIN (4 Digit)'
export const REDEMPTION_PIN_PLACEHOLDER = 'e.g. 1234'
export const AGAIN_REDEMPTION_PIN = 'Confirm PIN'
export const PIN_MUST_CONTAIN_4_NUMBERS = 'Your PIN must contain 4 numeric characters'
export const REDEMPTION_PIN_NOTE = 'Note: This is not your login PIN. This will be used for redemption purposes.'

export const CONTINUE = 'Continue'
export const USER_NOT_FOUND = 'User Not Found'
export const LOADING = 'Please wait...'
export const LOGIN_SUCCESS = 'Logged in Successfully...'
export const LOGOUT_SUCCESS = 'Successfully Logged Out...'
export const CLOSE = 'Close'
export const CHOOSE_AMOUNT_TO_TOPUP = 'Please select the amount you want to top-up'
export const VERIFY_OTP_TEXT = "Verify your phone number"
export const ENTER_OTP_TEXT = "Enter your OTP code here"

export const CONTINUE_AS_GUEST = 'Continue as Guest'
export const TNC = 'Terms and Conditions'
export const PRIVACY = "Privacy Policy"
export const REFUND= "Refund and Cancellation Policy"
export const FEEDBACK= "Feedback and Contact"
