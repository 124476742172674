import * as Texts from "../commons/Texts";
import { useEffect } from "react";
import { get } from "../commons/storageService";
import { getRefundPolicy } from "../commons/APIService";
import React, { useState } from "react";
import BackButton from "./commons/BackButton";
import {constants} from "../commons/constants";

const Privacy = (props) => {
    const [contentRefund, setContentRefund] = useState("");
    const readRefundPolicy = () => {
        const storeDetails = get("STORE_CREDENTIALS");
        const deviceNo = storeDetails.deviceID;
        getRefundPolicy(deviceNo).then((r) => setContentRefund(r.data));
    };
    useEffect(() => {
        readRefundPolicy();
    }, []);
    return (
      <div>
        <BackButton title={Texts.REFUND} history={() => props.history.replace(constants.STORE_HOME)}/>
        <div className="st-content container">
            <p className="static-text">{contentRefund}</p>
        </div>
      </div>
    );
};

export default Privacy;
