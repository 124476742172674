import React, {useEffect, useReducer, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {allProductAtt, getAllProdAtt, getMenuData, removeItemBasket} from "../actions";
import {constants} from "../commons/constants";
import {v4 as uuidv4} from 'uuid';
import * as Texts from "../commons/Texts";
import BackButton from "./commons/BackButton";
import {List, Divider, Checkbox, Popover} from "antd";

const AddToCart = (props) => {
  const mystate = useSelector((state) => state.impReducer);
  const dispatch = useDispatch();
  const allProductAtt = mystate && mystate.allProductsAtt && mystate.allProductsAtt.atts;
  const [selectedComboCat, setSelectedComboCat] = useState({})
  const [selectedAllModifier,setSelectedAllModifer] = useState({})
  const [checkStatus, setCheckStatus] = useState({} )
  const [modifierStatus, setModiferStatus] = useState({})
  const [disableCheck, setDisableCheck] = useState({})
  const [selectDrink, setSelectDrink] = useState(false)
  const [visible, setVisible] = useState(false)
  const { product, modifiers, mainProductSn, comboCategories, mustSelectComboDrink } = props.location.state;
  const [calculatePrice, setCalculatePrice] = useState({TotalPrice : parseFloat(product.price).toFixed(2)})
  const [quantity, setQuantity] = useState('1')
  const [comboProducts, setComboProducts] = useState([])

  const [total, setTotal] = useReducer((state,newState) => ({...state, ...newState}),{
    productPrice: parseFloat(product.price),
    drinkPrice:'0',
    drinkModifierPrice:'0',
    modifierPrice:'0',
    addonPrice:'0',
    total: '0'
  });
  const [modifier, setModifier] = useState([]);

  useEffect(()=> {

    comboCategories.map((item) => {
      let obj = selectedComboCat
      obj[item.label] = []
      let objDisableCheck = disableCheck
      objDisableCheck[item.label] = false
      setSelectedComboCat(obj)
      setDisableCheck(objDisableCheck)
    })
    if (mustSelectComboDrink){
      setSelectDrink(true)
    }
    modifiers && modifiers.map(item => {
      let obj= selectedAllModifier
      obj[item.att_name && JSON.parse(item.att_name).en] =[]
      setSelectedAllModifer(obj)
    })
  },[])
  const calculatePriceUpdate = () => {
    let totalCalculatedPrice = parseFloat(product.price);
    Object.keys(selectedComboCat).map(i => {
      selectedComboCat[i].map(s => {
        if (s.price){
          totalCalculatedPrice = parseFloat(totalCalculatedPrice) + parseFloat(s.price)
          if(s.Modifiers && s.Modifiers.length > 0 ){
            s.Modifiers.map(m => {
              totalCalculatedPrice = parseFloat(totalCalculatedPrice) + parseFloat(m.drinkModifierPrice)
            })
          }
        }
      })
    })
    if (selectedAllModifier){
        Object.keys(selectedAllModifier).map(keys => {
          selectedAllModifier[keys].map(i => {
            if (i.price){
              totalCalculatedPrice = parseFloat(totalCalculatedPrice) + parseFloat(i.price)
            }
          })
        })
    }
    setCalculatePrice({TotalPrice: parseFloat(totalCalculatedPrice).toFixed(2)})
  }

  useEffect(()=> {
    calculatePriceUpdate();
  }, [total.total])

  const checkMaxSelection = (label, title) => {
    let modifierQuantity = 0;
    if (title){
      selectedComboCat[label].map(i=> {
        i.Modifiers.length > 0 && i.Modifiers.map(n => {
          if (n.title == title){
            modifierQuantity = modifierQuantity +1
          }
        })
      })
    }
  return modifierQuantity
  }

 const handleComboChange = (e, label, comboProductFound, modi, modifier,title) => {
    let modiCheckObj = modifierStatus
   // let modifierQuantity = 0;
   // if (title){
   //   selectedComboCat[label].map(i=> {
   //     console.log(i)
   //     i.Modifiers.length > 0 && i.Modifiers.map(n => {
   //       if (n.title == title){
   //         modifierQuantity = modifierQuantity +1
   //       }
   //     })
   //   })
   // }
    if (modi && modifier){
      if(e.target.checked){
        selectedComboCat[label].map((item,i) => {
          if (item.id == comboProductFound.product_id && checkMaxSelection(label, title) < modifier.max_num){
            modiCheckObj[e.target.id] = e.target.checked
            setModiferStatus(modiCheckObj)
            onComboItemSelect(e, label,comboProductFound, modi, modifier, title)
          }else {
            modiCheckObj[e.target.id] =  !e.target.checked
            setModiferStatus(modiCheckObj)
          }
        } )
      }else if (!e.target.checked){
        modiCheckObj[e.target.id] = false
        setModiferStatus(modiCheckObj)
        onComboItemSelect(e,label,comboProductFound,modi, modifier, title)
      }
    }else{
      if(selectedComboCat[label].length == (comboCategories.map(i=> {if (i.label == label) return i.maxSelect})) ){
        let obj = checkStatus
        obj[e.target.id] = !checkStatus[e.target.id]
        setCheckStatus(obj)
        onComboItemSelect(e, label, comboProductFound)
      }else {
        //console.log('i am  else', checkStatus)
        let obj = checkStatus
        obj[e.target.id] = !checkStatus[e.target.id]
        setCheckStatus(obj)
        onComboItemSelect(e, label, comboProductFound)
      }
    }
 }

  const compareMainProduct = (cartItems, newItem) => {
    let  isEqual = false;
    let newBasket = JSON.parse(JSON.stringify(mystate.basket))
    for (let l=0; l < cartItems.length; l++ ){
      let item = cartItems[l]
      if (item[0].prodInfo.product_sn === newItem[0].prodInfo.product_sn){
        if (
            compareModifiers(item[1].Modifiers, newItem[1].Modifiers) &&
            compareComboProducts(item.slice(2), newItem.slice(2))
        ) {
          isEqual = true;
          newBasket[l].map((item)=>{
            if(item.totalPrice) {
              item.totalPrice = item.totalPrice + newItem[0].totalPrice
            }
            else if(item.quantity){
              item.quantity = Number(newItem[1].quantity) + item.quantity;
              item.Modifiers.map((modifiers,m)=>{
                item.Modifiers[m].quantity = Number(newItem[1].quantity) + item.Modifiers[m].quantity
              })
            }
          })
          dispatch(removeItemBasket(newBasket))
          props.history.push(constants.STORE_HOME)
          break;
        }
      }
    }
    return isEqual;
  }
  const compareModifiers = (oldItem, newItem) => {

    let isEqual = true;
    oldItem = oldItem.map(i=> i.prodnum)
    newItem = newItem.map(i => i.prodnum)
    console.log('found old mo', oldItem, newItem)
    if(oldItem.length != newItem.length) {
      return false
    }
    for (let i = 0; i < oldItem.length; i++) {
      if (newItem.indexOf(oldItem[i]) == -1){
        isEqual = false;
      }
    }
    return isEqual
  }

  const compareComboProducts = (oldComboItems, newComboItems) => {
    let isEqual = true;
    let oldArray = oldComboItems.map(i => i.prodNum)
    let newArray = newComboItems.map(i => i.prodNum)
    if (oldArray.length != newArray.length) return false

    for (let i = 0; i < oldArray.length; i++) {
      let itemFoundAtIndex = newArray.indexOf(oldArray[i]);
      if (itemFoundAtIndex == -1){
        isEqual = false;
      } else {
        isEqual = compareModifiers(oldComboItems[i].Modifiers, newComboItems[itemFoundAtIndex].Modifiers)
      }
    }
    return isEqual;
   }

  //add to cart function
  const addToCart = () =>{
    let newBasket = mystate.basket;
    let data = productsInfo
    data[0].ModifierInfo = modifier
    data[0].selectedCombo = selectedComboCat
    data[0].selectedModifiers = selectedAllModifier
    data[0].totalPrice2 = (quantity)*(parseFloat(total.addonPrice)+parseFloat(total.drinkPrice)+ parseFloat(total.drinkModifierPrice)+ parseFloat(total.modifierPrice)+parseFloat(total.productPrice));
    data[0].totalPrice = (calculatePrice.TotalPrice) * (quantity)
    productsInfo.map((item,i) => {
      if (item.quantity){
        data[i].quantity = Number(quantity)
        if(item.Modifiers.length > 0){
          item.Modifiers.map((modifiers, m)=> {
            data[i].Modifiers[m].quantity = Number(quantity)
          })
        }
      }
    })
    if (compareMainProduct(mystate.basket, data)){
    }else {
      newBasket.push(data);
      dispatch(removeItemBasket(newBasket))
      props.history.push(constants.STORE_HOME)
    }
  }
  const [productsInfo, setProductsInfo] = useState(
      [
        {
          MainProduct: mainProductSn,
          id: uuidv4(),
          MainProductPrice:parseFloat(product.price),
          totalPrice:"",
          prodInfo: product
        },
        {
          Modifiers:[],
          prodNum:product.product_sn,
          quantity:quantity
        }
      ]
  )
  const handleQuantityChange = (c) =>{
    let newQuantity ;
    if (c == 'decrement' && parseFloat(quantity) > 1){
      newQuantity = parseFloat(quantity) - 1
      setQuantity(newQuantity)
    } else if(c === 'increment'){
      newQuantity = parseFloat(quantity) + 1
      setQuantity(newQuantity)
    }
  }
  const [productDetail, setProductDetails] = useState({
    name: JSON.parse(product.product_name).en,
    price: parseFloat(product.price),
    note: product.note
  });
    useEffect(()=> {
    },[productsInfo])

  const checkMustSelect = () => {
      let condition = true
    let final = true;
    let arr = []
    for (let i=0; i < comboCategories.length; i++) {
      if (comboCategories[i].maxSelect === comboCategories[i].minSelect){
        if (selectedComboCat[comboCategories[i].label]) {
          condition =  selectedComboCat[comboCategories[i].label].length == comboCategories[i].maxSelect
          arr.push(condition)
        }else {
          condition = false
          arr.push(condition)
        }
      }
    }

    for (let i=0; i < modifiers?.length; i++){
      if((modifiers[i]?.min_num == modifiers[i]?.max_num )&& (modifiers[i]?.min_num > 0)){
        if(selectedAllModifier[JSON.parse(modifiers[i].att_name).en]?.length >= modifiers[i]?.min_num){
          condition = true
          arr.push(condition)
        }else if (selectedAllModifier[JSON.parse(modifiers[i].att_name).en]?.length < modifiers[i]?.min_num){
          condition = false
          arr.push(condition)
        }
      }
    }
    let semi = arr.indexOf(false)> -1
    final = !semi
    return final
  }

  const onComboItemSelect = (e, comboCategory, selectedComboProduct, selectedComboModifier, checkModifier, title) => {
    let modifierQuantity = 0;
    if (title){
      selectedComboCat[comboCategory].map(i=> {
        //console.log(i)
        i.Modifiers.length > 0 && i.Modifiers.map(n => {
          if (n.title == title){
            modifierQuantity = modifierQuantity +1
          }
        })
      })
    }
    let drinkName = JSON.parse(selectedComboProduct.product_name).en
    let drinkProduct={
      Modifiers:[],
      prodNum:selectedComboProduct.product_sn,
      quantity:quantity
    }
    let comboModifier = {
      description:'',
      prodnum:'',
      quantity: quantity
    }
    if(selectedComboModifier){
      if(e.target.checked){
        let modifierName = JSON.parse(selectedComboModifier.att_val_name).en
        comboModifier.description = modifierName
        comboModifier.prodnum = selectedComboModifier.att_val_sn
        comboModifier.costEach = selectedComboModifier.price
        comboModifier.quantity = quantity;
        let x = false;
        let updatedState;
        let condition = productsInfo.map(item => {
          if (item.prodNum === selectedComboProduct.product_sn) {
            console.log(item)
          if(modifierQuantity < checkModifier.max_num) {
              console.log(x)
              x = true;
              return true;
            }
          }
        })

        if (x) {
          let newObj;
          let selectedCombo = selectedComboCat
          selectedComboCat[comboCategory].map((item,i) => {
            if (item.id === selectedComboProduct.product_id && modifierQuantity < checkModifier.max_num){
              updatedState = JSON.parse(JSON.stringify(addSingleProdModifier(productsInfo, comboModifier, selectedComboProduct.product_sn)))
              let drinkModifierInfo = {
                drinkModifier : modifierName,
                id: selectedComboModifier.att_val_id,
                drinkModifierPrice : selectedComboModifier.price,
                title: title
              }
              newObj = item
              newObj.Modifiers.push(drinkModifierInfo)
              selectedCombo[comboCategory][i]=newObj
              return newObj
            }
          })
          setSelectedComboCat(selectedCombo)
          setTotal({
            drinkModifierPrice: parseFloat(total.drinkModifierPrice) + parseFloat(selectedComboModifier.price),
            total: parseFloat(total.total) + parseFloat(selectedComboModifier.price)
          })
          setProductsInfo(updatedState)

        } else {
          e.target.checked = !e.target.checked
        }
      }else if(!e.target.checked){
        let itemRemoved = arrayRemove(productsInfo,selectedComboModifier.att_val_sn, selectedComboProduct.product_sn)
        let newobj;
        let selectedCombo = selectedComboCat
        selectedComboCat[comboCategory].map((item,i) => {
          if (item.id === selectedComboProduct.product_id){
            newobj = item
            let modifierRemoved = arrayRemove(newobj.Modifiers,selectedComboModifier.att_val_id)
            selectedCombo[comboCategory][i].Modifiers = modifierRemoved
            setSelectedComboCat(selectedCombo)
          }
        })
        setProductsInfo(itemRemoved)
      }
    }else if(e.target.checked)
      {
        let updateSelection = selectedComboCat;
        let proDetail = {id: selectedComboProduct.product_id,
          name: drinkName,
          drinkProdNum: selectedComboProduct.product_sn,
          Modifiers: [],
          price: selectedComboProduct.price}
        updateSelection[comboCategory].push(proDetail)
        setSelectedComboCat(updateSelection)

        setTotal({
          drinkPrice: parseFloat(total.drinkPrice) + parseFloat(selectedComboProduct.price),
          drinkModifierPrice: '0',
          total: parseFloat(total.total) + parseFloat(selectedComboProduct.price)
        })
      setProductsInfo(prevState => [...prevState, drinkProduct])
        let objDisableCheck = disableCheck;
        objDisableCheck[comboCategory] = true;
        if(mustSelectComboDrink) {
          setSelectDrink(false)
        }

      for(let z = 0; z < comboCategories.length; z ++){
        let condition = comboCategories[z]
        if (comboCategories[z].label == comboCategory) {
          if(selectedComboCat[comboCategory].length == comboCategories[z].maxSelect){
            setDisableCheck(objDisableCheck)
          }
        }
      }
    } else if (!e.target.checked){
      e.target.checked = false
      let selectedCombo = selectedComboCat
      if(mustSelectComboDrink) {
        setSelectDrink(true)
      }
      let newComboArr = arrayRemove(selectedComboCat[comboCategory], selectedComboProduct.product_id)
      selectedCombo[comboCategory] = newComboArr
      setSelectedComboCat(selectedCombo)
      let newArr = arrayRemove(productsInfo,undefined,selectedComboProduct.product_sn,'product')
      setProductsInfo(newArr)
      setTotal({
        drinkPrice: parseFloat(total.drinkPrice) - parseFloat(selectedComboProduct.price),
        total: parseFloat(total.total) - parseFloat(selectedComboProduct.price)
      })
      let objDisableCheck = disableCheck;
      objDisableCheck[comboCategory] = false;
      for(let z = 0; z < comboCategories.length; z ++){
        if (comboCategories[z].label == comboCategory) {
          if(selectedComboCat[comboCategory].length == comboCategories[z].maxSelect){
            setDisableCheck(objDisableCheck)
          }
        }
      }
    }
    calculatePriceUpdate()
  };

  const onModifierSelect = (e,select, keyLabel, maxSelect ) =>{
    console.log(e)
    let name = JSON.parse(select.att_val_name).en
    let toUpdateAllModifier = selectedAllModifier
    console.log(selectedAllModifier)
    let obj = {
      id: select.att_val_id,
      name: name,
      price: select.price
    }
    let selectedModifier = {
      description: name,
      prodnum: select.att_val_sn,
      quantity: quantity
    }
    if(e.target.checked){
      if (toUpdateAllModifier[keyLabel].length < maxSelect  ){
        setModifier((preState) => [...preState, obj])
        toUpdateAllModifier[keyLabel].push(obj)
        setProductsInfo(addSingleProdModifier(productsInfo, selectedModifier, product.product_sn))
        setTotal({
          modifierPrice: parseFloat(total.modifierPrice) + parseFloat(select.price),
        })
        setTotal({
          total: parseFloat(total.productPrice) + parseFloat(total.drinkPrice) +
              parseFloat(total.drinkModifierPrice) + parseFloat(total.modifierPrice) + parseFloat(total.addonPrice)
        })
      }else {
        console.log(e)
        e.target.checked = !e.target.checked
      }
    }
    else if(!e.target.checked){
      let newarr = arrayRemove(modifier,select.att_val_id)
      setModifier(newarr)
      let arrayToRemove = toUpdateAllModifier[keyLabel]
      let removedItemArr = arrayRemove(arrayToRemove, select.att_val_id)
      toUpdateAllModifier[keyLabel] = removedItemArr
      setSelectedAllModifer(toUpdateAllModifier)
      let itemRemoved = arrayRemove(productsInfo,select.att_val_sn, product.product_sn)
          setProductsInfo(itemRemoved)
      setTotal({
        modifierPrice: parseFloat(total.modifierPrice) - parseFloat(select.price),
        })
      setTotal({total: parseFloat(total.productPrice) + parseFloat(total.drinkPrice)+
            parseFloat(total.drinkModifierPrice)+parseFloat(total.modifierPrice) +parseFloat(total.addonPrice)})
    }
    calculatePriceUpdate()
  }

  const addSingleProdModifier = (prevState, updateObj,sn) => {
    let newState = prevState;
    newState.map(item => {
      if(item.prodNum === sn){
        item.Modifiers.push(updateObj)
      }
    })
    return newState;
  }
  const arrayRemove = (arr, id,sn,type) => {
    let newArr = arr;
    if(sn) {
      if(type === 'product'){
        return arr.filter((val)=>{
          return val.prodNum != sn;
        })
      }else{
        arr.map((item, i) => {
          if (item.prodNum === sn) {
            let newModifiers = item.Modifiers.filter((val) => {
              console.log('i am inside modifier', val)
              return val.prodnum != id
            })
            newArr[i].Modifiers = newModifiers;
          }
        })
        console.log('data i  get', newArr)
        return newArr;
      }
    }else {
      return arr.filter((val) => {
        return val.id != id;
      });
    }
  };

  return (
    <div className="single_product">
      <BackButton title={productDetail.name} history={()=> props.history.replace(constants.STORE_HOME)} gradient='bg-alpha-reverse'/>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 main-sect">
          <div className="image_selected">
            <div className="prod-img d-flex flex-column justify-content-end" style={{background: `url(${product.product_pic}) no-repeat center/cover`}}>
              <div className="prod-desc bg-alpha d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-content-center">
                  <h3 className="prod-name">{productDetail.name}</h3>
                  <span className="prod-price">${Number(productDetail.price).toFixed(2)}</span>
                </div>
                <span className="prod-detail">{productDetail.note}</span>
              </div>
            </div>
          </div>
        </div>
	      <div className="col-xs-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 main-sect">
				  <div className="selection-sect">
                    {
                      modifiers && modifiers.length > 0 && modifiers.map(item=> {
                        console.log("modifier item:", item)
                        return <div className="selection-bar">
                            <Divider orientation="left" className="font-weight-bold text-uppercase text-truncate-custom">
                              {
                                  item.att_name && JSON.parse(item.att_name).en
                              }
                              {(item.max_num == item.min_num) &&(item.min_num > 0) &&
                                  <span className='text-danger'> Select {item.min_num} </span>}
                            </Divider>
                            {selectedAllModifier[JSON.parse(item.att_name).en] && selectedAllModifier[JSON.parse(item.att_name).en].length > 0 &&
                                <List
                                    dataSource={selectedAllModifier[JSON.parse(item.att_name).en]}
                                    bordered
                                    renderItem={item => (
                                        <List.Item>
                                          <span className='item-name'>{item.name}</span>
                                          <span
                                              className="badge badge-danger badge-pill pl-3 pr-3 mb-2 mr-3 ">${item.price}</span>
                                        </List.Item>
                                    )}/>}
                            {/*{item.max_num !== item.min_num && */}
                            <button type="button" className="btn btn-block btn-outline-danger link" data-toggle="modal"
                                    data-target={`#adon${item.att_id}`}> Customize
                            </button>
                            {/*}*/}
                          </div>
                        }
                      )
                    }


					{
						comboCategories && comboCategories.length  > 0 && comboCategories.map((item, i) =>
						<div className="selection-bar">
							<Divider orientation="left" className="font-weight-bold text-uppercase  text-truncate-custom">
								{item.label} {comboCategories.map(i=> {
                                  if (i.label == item.label){
                                    console.log(i)
                                    if(i.maxSelect == i.minSelect) return <span className='text-danger'> Select {i.minSelect} </span>

                            }})}
							</Divider>
							{selectedComboCat && selectedComboCat[item.label] && selectedComboCat[item.label].length > 0 &&
                            <List
								dataSource={selectedComboCat[item.label]}
								bordered
								renderItem={item => (
									<List.Item>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex flex-row justify-content-between">
                        <span className='d-flex item-name'>{item.name}</span>
                        <span className="d-flex badge badge-danger badge-pill pl-3 pr-3 mb-2 mr-3 ">${item.price}</span>
                      </div>

                        {
                          item.Modifiers.length > 0 &&
                          <List
                            dataSource={item.Modifiers}
                            renderItem={modifier => (
                              <div className='d-flex flex-row justify-content-between'>
                                <div><span className='ml-2'>{modifier.drinkModifier}</span></div>
                                <div><span className="badge badge-warning badge-pill pl-3 pr-3 mb-2 mr-3 ">${modifier.drinkModifierPrice}</span></div>
                              </div>
                            )}/>
                        }
                      </div>

                    </List.Item>
                  )}/>}

                <button type="button" className="btn btn-block btn-outline-danger link" data-toggle="modal" data-target={`#${item.label.replace(/[^\w\s]/g, "").replace(/\s+/g, "")}`}
                        onClick={() => {
                  setComboProducts(item?.products)
                  let x = JSON.parse(JSON.stringify(checkStatus))
                  let y;
                  let p;
                  item.products.map(i =>{
                    y = i.product_id
                    p = y.toString()
                    x[p] = false

                  })
                }}
                > Customize</button>
              </div>
              )
            }



            <List bordered>
              <List.Item>
                <span className="font-weight-bold">Total Price</span>
                <span className="badge badge-danger badge-pill pl-3 pr-3 mb-2 mr-3 ">
                      <i className="feather-dollar-sign"/>
                  {console.log(calculatePrice)}
                  {parseFloat((calculatePrice.TotalPrice) * quantity).toFixed(2)}
                  {/*{Number((parseFloat(total.addonPrice)+parseFloat(total.drinkPrice)+ parseFloat(total.drinkModifierPrice)+ parseFloat(total.modifierPrice)+parseFloat(total.productPrice))*(quantity)).toFixed(2)}*/}
                    </span>
              </List.Item>
            </List>




            <div className="d-flex flex-column align-content-center justify-content-between mt-2 ">

              <div className="d-flex flex-row justify-content-center mb-2">
                <div className='count-number float-right'>
                  <button type='button' className='btn-sm left dec btn btn-outline-secondary'
                          onClick={() => handleQuantityChange("decrement")}>
                      <i className='feather-minus'/>
                  </button>
                  <input className="count-number-input text-white bg-secondary" value={quantity} readOnly/>
                  <button type='button' className='btn-sm left dec btn btn-outline-secondary'
                          onClick={() => handleQuantityChange("increment")}>
                      <i className='feather-plus'/>
                  </button>
                </div>
              </div>



                <button type="button" className={`btn btn-primary shop-button ${!checkMustSelect() && "disabled"}`}
                        onClick={() => {
                          if(selectDrink){
                            setVisible(!visible)
                          }
                          else if (checkMustSelect()) {
                            addToCart()
                          }
                        }}
                >
                  Add to Cart
                </button>
            </div>


          </div>



        </div>


        <div className="col-lg-6 order-2">

          <div className="product_description">

            <div className="row">

              {
                modifiers && modifiers.length > 0 && modifiers.map(item => {
                      return <div className='col'>
                        <div className='modal fade show'
                             id={'adon'+ item.att_id}
                             tabIndex={-1}
                             role="dialog"
                             aria-labelledby={item.att_id}
                             style={{
                               display: "none",
                               paddingRight: "4px",
                             }}
                             aria-modal="true"
                        >
                          <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                    className="modal-title product_options font-weight-bold text-uppercase"
                                    id="modifierLabel"
                                >
                                  {JSON.parse(item.att_name).en}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {/*<br />*/}
                              <div className="modal-body">
                                {
                                  (item.att_val_info && item.att_val_info.length > 0) &&
                                  (item.min_num == 1 &&  item.max_num ==1) ?
                                      <List
                                          dataSource={item.att_val_info}
                                          renderItem={subItem => (
                                              <List.Item>
                                                <label className="d-flex flex-row justify-content-center m-0">
                                                  <input type='checkbox'
                                                         checked={selectedAllModifier[JSON.parse(item.att_name).en]?.some(i=> {
                                                           return i.id === subItem.att_val_id
                                                         })}
                                                         className="mt-1 mr-2"
                                                         onChange={(e) => onModifierSelect(e, subItem,JSON.parse(item.att_name).en, item.max_num)}/>
                                                  {JSON.parse(subItem.att_val_name).en}
                                                </label>

                                                <span className="text-info">
                                    $ {subItem.price}
                                  </span>
                                              </List.Item>
                                          )}/>
                                      :
                                      <List
                                      dataSource={item.att_val_info}
                                      renderItem={subItem => (
                                          <List.Item>
                                            <label className="d-flex flex-row justify-content-center m-0">
                                              <input type='checkbox' className="mt-1 mr-2"  onChange={(e) => onModifierSelect(e, subItem,JSON.parse(item.att_name).en, item.max_num)}/>
                                              {JSON.parse(subItem.att_val_name).en}
                                            </label>


                                            <span className="text-info">
                                    $ {subItem.price}
                                  </span>
                                          </List.Item>
                                      )}/>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                )
              }
            </div>

            {comboCategories && comboCategories.length > 0 && comboCategories.map((item)=>
                <div className="col">

                    <div
                        className="modal fade show"
                        id={`${item.label.replace(/[^\w\s]/g, "").replace(/\s+/g, "")}`}
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby={`${item.label.replace(/[^\w\s]/g, "").replace(/\s+/g, "")}`}
                        style={{
                          display: "none",
                          paddingRight: "4px",
                        }}
                        aria-modal="true"
                    >
                      <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                                className="modal-title product_options font-weight-bold text-uppercase"
                                id="modifierLabel"
                            >
                              {item.label}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form>

	                            {comboProducts &&
	                            comboProducts.length >= 0 &&
		                            <List
			                            dataSource={comboProducts}
			                            renderItem={comboProductFound => (
				                            <List.Item>
					                            <div className="d-flex flex-column justify-content-around">
						                            <Checkbox id={comboProductFound.product_id} onChange={e => handleComboChange(e,item.label,comboProductFound)} disabled={ !checkStatus[comboProductFound.product_id] && disableCheck[item.label]} checked={checkStatus[comboProductFound.product_id]} name={item.label}>
							                            {JSON.parse(comboProductFound.product_name).en}
						                            </Checkbox>

						                            {allProductAtt.map((prodAtt) =>
							                            comboProductFound.product_id === prodAtt.product_id &&
							                            prodAtt.atts.map((modifier) =>
                                                          modifier &&
                                                              modifier.att_val_info.length > 0 ? (
                                                                  <div>
                                                                    {
                                                                        (selectedComboCat[item.label].length > 0 && checkStatus[comboProductFound.product_id]) && disableCheck[item.label] &&
                                                                        <span className="btn-link"
                                                                              htmlFor={comboProductFound.product_id}
                                                                              data-toggle="dropdown" aria-haspopup="true"
                                                                              aria-expanded="false">
                                                    + Choose {JSON.parse(modifier.att_name).en}
                                                  </span>
                                                                    }

                                                                    <div
                                                                        id={comboProductFound.product_sn}
                                                                        className="dropdown-menu border-dark"

                                                                        aria-labelledby={JSON.parse(comboProductFound.product_name).en}
                                                                    >
                                                                      {modifier.att_val_info && modifier.att_val_info.length > 0 &&

                                                                          <List
                                                                              dataSource={modifier.att_val_info}
                                                                              renderItem={modi => (
                                                                                  <List.Item>
                                                                                    <label
                                                                                        className="d-flex flex-row justify-content-center m-0">
                                                                                      <Checkbox name={JSON.parse(modi.att_val_name).en}
                                                                                                id={modi.att_val_id}
                                                                                                disabled={(checkMaxSelection(item.label, JSON.parse(modifier.att_name).en) == modifier.max_num) && !modifierStatus[modi.att_val_id]}
                                                                                                checked={modifierStatus[modi.att_val_id] ? modifierStatus[modi.att_val_id] :false}
                                                                                                onChange={(e) => {
                                                                                                  handleComboChange(e, item.label, comboProductFound, modi, modifier,JSON.parse(modifier.att_name).en)
                                                                                                  // onComboItemSelect(e, item.label, comboProductFound, modi, modifier)
                                                                                                }}>
                                                                                      {JSON.parse(modi.att_val_name).en}</Checkbox>
                                                                                    </label>

                                                                                    <span className="text-info float-right">
                                                                                            $ {modi.price}
                                                                                          </span>
                                                                                  </List.Item>
                                                                              )}/>
                                                                      }
                                                                    </div>
                                                                  </div>
                                                              ) : ''

							                            )
						                            )
						                            }

					                            </div>

					                            <span className="text-info float-right">
                                        $ {comboProductFound.price}
                                      </span>




				                            </List.Item>
			                            )}/>
	                            }

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
