import * as Texts from "../commons/Texts";
import { useEffect } from "react";
import { get } from "../commons/storageService";
import { getTnc } from "../commons/APIService";
import BackButton from "./commons/BackButton";
import React, { useState } from "react";
import {constants} from "../commons/constants";

const TNC = (props) => {
  const [contentTNC, setContentTNC] = useState("");
  const readTNC = () => {
    const storeDetails = get("STORE_CREDENTIALS");
    const deviceNo = storeDetails.deviceID;
    getTnc(deviceNo).then((r) => setContentTNC(r.data));
  };
  useEffect(() => {
    readTNC();
  }, []);
  return (
    <div>
      <BackButton title={Texts.TNC} history={() => props.history.push(constants.STORE_HOME)}/>
      <div className="st-content container">
        <p className="static-text">{contentTNC}</p>
      </div>
    </div>
  );
};

export default TNC;
