import React, {useEffect, useState} from "react";
import {
  getStoreInfo,
  locateStoreById,
} from "../commons/APIService";
import { useHistory, useParams } from "react-router-dom";
import { set } from "../commons/storageService";
import { constants } from "../commons/constants";
import Loading from "./commons/Loading";
import {useDispatch, useSelector} from "react-redux";
import {
  paymentInformationUpdate,
  removeItemBasket,
  storeNote,
  storeToReduxStore,
  tableServiceInfo,
  workTiming
} from "../actions";
import moment from "moment";

const LocateStore = () => {
  let state = useSelector((state) => state.impReducer)
  let history = useHistory();
  let params = useParams("locate/:id/:sid");
  let {id, sid} = params
  let storeStartTime = "";
  let storeEndTime = "";
  let date = new Date();
  let modifyDate= date.toString();
  const getHours = modifyDate.slice(16,18);
  const getMinutes = modifyDate.slice(19, 21);
  const pickUpTime = getHours + ":" +getMinutes;
  let momentTime = moment(pickUpTime);
  const dispatch = useDispatch();
  useEffect(()=> {
    localStorage.clear();
    // dispatch(removeItemBasket([]))
    // dispatch(paymentInformationUpdate({}))
  },[])
  const [loading,setLoading] = useState(true)
  const [qrError , setQrError] = useState(false)
  const locateStore = async () => {
    let storeCredentials
    if (id && sid) {
      storeCredentials = await locateStoreById(id, sid);
    } else if(id) {
      storeCredentials = await locateStoreById(id);
    }
    if (!storeCredentials.isError) {
      let storeInfo = await getStoreInfo({ deviceNo: storeCredentials.deviceID });
      if (!storeInfo.isError) {
        dispatch(storeToReduxStore(storeInfo.storeInfos[0]))
        let store = storeInfo.storeInfos[0]
        let workTime = store.work_time ? JSON.parse(store.work_time) : '';
        let stNote = store.store_note ? JSON.parse(store.store_note) : '';
        if(sid){
          stNote.sessionId = sid;
          stNote.storeId = id;
        }
        //console.log("store note manage",stNote)
        dispatch(workTiming(workTime))
        dispatch(storeNote(stNote))
        set([
            constants.STORE_ID,
            constants.STORE_NOTE
        ], [
          store.store_id,
          stNote
        ]);

        let result = [];

        workTime.real.map((data)=>{
          let toDay = date.getDay();
          if (toDay === 0){
            toDay = 7;
          }
          if (toDay === data.id){
            data.time.map((time) => {
              storeStartTime = time.start;
              storeEndTime = time.end;

              const  intervals = (st, en) =>  {
                let start = moment(st, "hh:mm").add(5,'m')
                let  end = moment(en,"hh:mm")
                let current = moment(start)
                if (momentTime._i >= current._i) {
                  while (current <= end){
                    result.push(current.format("HH:mm").slice(0,5))
                    current.add(15, "minutes")
                  }
                }
              }
              intervals(`${storeStartTime}`, `${storeEndTime}`);
            })
          }
        })

        let curDate = new Date();
        curDate = curDate.toDateString();
        let times = result.map((t)=> {
          return new Date(curDate+ " " + t)
        });
        let now = new Date();
        let min = Math.abs(now - times[0]);
        let result1 = "";
        for (let i=1; i< times.length; i++){
          if(Math.abs(now - times[i]) <= min){
            min = Math.abs(now - times[i]);
            result1 = result[i]
          }
        }
        let a = result.indexOf(result1)
        let pickUpTimings = [];
        for (var i = a+1; i < result.length; ++i){
          pickUpTimings.push(result[i])
        }
        localStorage.setItem('pickUptiming', pickUpTimings)
        if (pickUpTimings.length > 0) {
          // making table service true for development purpose
          let tableService = storeCredentials.hasTableService
          //TABLE SERVICE CHECK
          state.locate && dispatch(tableServiceInfo(tableService))
          if (tableService == true){
            setQrError(false)
            history.replace(constants.START_ORDER)
          }else if (tableService == false){
            setQrError(false)
            history.replace(constants.LOGIN_ROUTE)
          }
        } 
        else if (pickUpTimings.length === 0) { // if no timing available, means store is closed
          setLoading(false)
        }
      }
      if ((id && sid) && storeInfo.isError) { // if TS and no store info is found, redirect to login page
        setQrError(true)
        setLoading(false)
      }
      else if (id && storeInfo.isError) { // if QS and no store is found, redirect to login page
        setQrError(null)
        setLoading(false)
      }
    }
  };
  useEffect(() => {
    locateStore();
  },[]);
  return (
    <>
      {loading ? <Loading/> : qrError ? 
      <div className='container-fluid'>
        <div className='header-main'>
          <div className='d-flex vh-100 background-overlay'>
            <div className='d-flex flex-column w-100 align-content-center justify-content-around text-center'>
              <div>
                <h1 className='h1 text-white'>Invalid QR Code</h1>
              </div>
              <div>
                <i className='feather-x-circle text-danger' style={{fontSize:"150px"}}/>
              </div>
              <div>
                <h2 className='text-white'>Please approach staff for assistance.</h2>
              </div>
            </div>
          </div>
        </div>
      </div> 
      : qrError == null ?
      <div className='container-fluid'>
        <div className='header-main'>
          <div className='d-flex vh-100 background-overlay'>
            <div className='d-flex flex-column align-content-center w-100 justify-content-around text-center'>
              <div>
                <h1 className='h1 text-white'>Store Not Found</h1>
              </div>
              <div>
                <i className='feather-x-circle text-danger' style={{fontSize:"150px"}}/>
              </div>
              <div>
                <h2 className='text-white'>No Store found for given link!</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className='login-page vh-100'>
        <div className="bg-login" style={{background:`url(${state.storeNote.Images.LandingPage}) no-repeat center/cover`}}></div>
        <div className='background-overlay'>
          <div className="d-flex vh-100 back w-100">
            <div className="d-flex flex-column justify-content-around text-center col-md-6 col-lg-6 ml-auto mr-1 vh-100">
              <div className="img-fluid d-flex flex-column justify-content-start">
                <div><img className="img-header" src={state.storeNote.Images.Logo}/></div>
                <label className="store-title-2 text-white">{JSON.parse(state.store.store_name).en}</label>
              </div>
              <div className='img-fluid d-flex flex-column justify-content-start'>
                <div>
                  <img className='store-close' src={state.storeNote.Images.StoreClosed}/>
                </div>
              </div>
              <div style={{height: '30px'}}>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};
export default LocateStore;
