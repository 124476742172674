import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {paymentUpdate} from "../commons/APIService";
import {constants} from "../commons/constants";

const Processing = (props) => {
    let dispatch = useDispatch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let urlParam = window.location.search;
    let resultIndicator = params.resultIndicator
    let cloudOrderNumber = localStorage.getItem('cloudOrderNumber')
    let obj = {
        cloudOrderNumber:cloudOrderNumber,
        payment:{
            status: "paid",
            resultIndicator: resultIndicator
        }
    }
    useEffect(()=>{
        paymentUpdate(obj).then(r=>{
            if (r.result_code == 200){
                let storeMessage = JSON.parse(r.store_response)
                //console.log(storeMessage)
                if(storeMessage.Response.ErrorNum == 0){
                    props.history.push({
                        pathname:constants.PAYMENTSUCCESSFUL,
                        state: r
                    })
                }else if (storeMessage.Response.ErrorNum == -1){
                    props.history.push({
                        pathname:constants.PAYMENTSUCCESSFUL,
                        state: {r, storeMessage}
                    })
                }
                else if (storeMessage.Response.ErrorNum == 999){
                    props.history.replace({
                        pathname:constants.PAYMENTSUCCESSFUL,
                        state:{r,storeMessage}
                    })
                }
            }else if (r.result_code == 504 || r.result_code == 408){
                let defaulMessage = "server unreachable"
                props.history.replace({
                    pathname: constants.PAYMENTSUCCESSFUL,
                    state:{r, defaulMessage}
                })
            }else if (r.result_code == 500){
                //console.log(r)
                let storeMessage = r.displaymsg
                props.history.replace({
                    pathname: constants.PAYMENTSUCCESSFUL,
                    state: { r , storeMessage}
                })
            }
        })
    },[])
    return(
        <div className='d-flex justify-content-center'>

        </div>
    )
}

export default Processing;
