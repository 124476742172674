import React, { useEffect, useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { countryCodeList } from "../../commons/utility";
import { useDispatch } from "react-redux";
import {getOTP, storeID} from "../../commons/APIService";
import * as texts from "../../commons/Texts";
import { get } from "../../commons/storageService";
import { constants } from "../../commons/constants";
import {isValidData} from "./utility/functions";

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);
  const [userData, setUserData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      mobile: "",
      email: "",
      password: "",
      confirmPassword: "",
      countryCode: "65",
      storeId: "",
      function: constants.FUNCTION_USER_REGISTER,
    }
  );
  const [showError, setShowError] = useState(false);
  const handleChange = (e) => {
    if (e.target.id === "name") {
      setUserData({ name: e.target.value });
    } else if (e.target.id === "email") {
      setUserData({ email: e.target.value });
    } else if (e.target.id === "mobile") {
      setUserData({ mobile: e.target.value });
    } else if (e.target.id === "password") {
      setUserData({ password: e.target.value });
    } else if (e.target.id === "confirmPassword") {
      setUserData({ confirmPassword: e.target.value });
    }
  };

  const requestSignUp = (e) => {
    e.preventDefault();
    if (isValidData(userData)) {
      setShowError(false);
      let data = {
        storeId: userData.storeId,
        mobile: userData.mobile,
        countryCode: userData.countryCode,
        email: userData.email,
        function: userData.function,
      };
      getOTP(data, dispatch).then((res) => {
        if (res && res.result_code == 200) {
          setOtpSent(true);
          history.push({
            pathname: constants.VERIFY_OTP_ROUTE,
            state: { userData: userData },
          });
        }
      });
    } else {
      setShowError(true);
    }
  };
  useEffect(() => {
    setUserData({ storeId: storeID() })
  }, []);
  const { countryCode, password, confirmPassword, mobile, name, email } =
    userData;
  return (
    <div>
      <div className="px-5 col-10 mx-auto">
        <h2 className="text-dark my-0">Welcome</h2>
        <p className="text-50  text-dark">Sign up here</p>
        <form className="login-form mt-5 mb-4 " onSubmit={(e) => requestSignUp(e)}>
          <div className="form-group">
            <label htmlFor="name" className="text-dark form-label">
              Name
            </label>
            <input
              type="text"
              required={true}
              placeholder={texts.NAME_PLACEHOLDER}
              className="form-control pl-2"
              id="name"
              aria-describedby="nameHelp"
              onChange={(e) => handleChange(e)}
            />
            {showError && userData.name.length === 0 ? (
              <div className="test">{texts.ENTER_NAME}</div>
            ) : ""}
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="text-dark d-block mb-2">
              {texts.ENTER_PHONE_NO}
            </label>
            <select
              id="country-code"
              placeholder={texts.COUNTRY_CODE_PLACEHOLDER}
              className="form-control d-inline-block w-auto border pl-2 phone-num-1"
              value={countryCode}
              onChange={(e) => setUserData({ countryCode: e.target.value })}
            >
              {countryCodeList()}
            </select>
            <input
              id="mobile"
              maxLength = "8"
              required={true}
              type="tel"
              title="you can only enter numbers"
              value={mobile}
              className="form-control d-inline-block pl-2 letterSpacing phone-num-2"
              placeholder={texts.PHONE_NO_PLACEHOLDER}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => handleChange(e)}
            />
            {showError && !userData.mobile ? (
              <p className="  text-danger">{texts.ENTER_PHONE_NO_MSG}</p>
            ) : showError &&
              !userData.mobile.match(constants.phoneNumberRegex) ? (
              <p className="  text-danger">{texts.ENTER_VALID_PHONE_NUMBER}</p>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="email" className="text-dark">
              {texts.ENTER_EMAIL_ADDRESS}
            </label>
            <input
              type="text"
              required={true}
              placeholder={texts.EMAIL_ADDRESS_PLACEHOLDER}
              className="form-control letterSpacing pl-2"
              id="email"
              onChange={(e) => handleChange(e)}
            />
            {showError && !userData.email ? (
              <p className="  text-danger">{texts.ENTER_EMAIL_ADDRESS_MSG}</p>
            ) : showError &&
              !userData.email.match(constants.emailAddressRegex) ? (
              <p className="  text-danger">{texts.ENTER_VALID_EMAIL}</p>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password" className="text-dark">
              {texts.ENTER_LOGIN_PIN}
            </label>
            <input
              type="password"
              maxLength="6"
              required={true}
              placeholder={texts.LOGIN_PIN_PLACEHOLDER}
              className="form-control letterSpacing pl-2 mb-1"
              id="password"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => handleChange(e)}
            />
            {showError && userData.password.length < 6 ? (
              <p className="  text-danger">
                {texts.PIN_MUST_CONTAIN_6_NUMBERS}
              </p>
            ) : showError && !userData.password.match(constants.pinRegex) ? (
              <p className="  text-danger">
                {texts.PIN_MUST_CONTAIN_6_NUMBERS}
              </p>
            ) : (
              ""
            )}
            <input
                maxLength="6"
              type="password"
              required={true}
              placeholder={texts.CONFIRM_LOGIN_PIN_PLACEHOLDER}
              className="form-control letterSpacing pl-2"
              id="confirmPassword"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => handleChange(e)}
            />
            {showError &&
            userData.password.length > 0 &&
            userData.confirmPassword != userData.password ? (
              <p className=" text-danger">{texts.PASSWORD_NOT_MATCH}</p>
            ) : (
              ""
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block"
            onClick={(e) => requestSignUp(e)}
          >
            SIGN UP
          </button>
        </form>
      </div>
      <div className="new-acc d-flex align-items-center justify-content-center">
        <Link to={constants.LOGIN_ROUTE}>
          <p className="text-center m-0 text-dark">Already have an account? Sign in</p>
        </Link>
      </div>
    </div>
  );
};
export default SignUp;
