import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {activateAdvocadoUser, getCustomerInfo, userLogin} from "../../commons/APIService";
import {login, USERINFO} from "../../actions";
import {constants} from "../../commons/constants";
import {Link, Redirect} from "react-router-dom";
import {set} from "../../commons/storageService";

const RedemptionPin = (props) => {
    console.log(props)
    const dispatch = useDispatch();
    const mystate = useSelector((state) => state.impReducer)
    const [redemptionPin, setRedemptionPin] = useState('');
    const [checkPin,setCheckPin]=useState('');
    const [showError, setShowError] = useState(false)
    const [regSuccess, setRegSuccess] = useState(false)
    const handleChange = (e) => {
        if(e.target.value.match(constants.numberRegex)){
            setShowError(false)
            if (e.target.id === 'pin') {
                setRedemptionPin(e.target.value)
            } else if (e.target.id === 'confirmPin') {
                setCheckPin(e.target.value)
            }
        }
        else {
            setShowError(true)
        }
    }
    const submitHandler = (e) =>{
        e.preventDefault();
        if(redemptionPin === checkPin){
            setShowError(false)
            let obj = Object.assign({}, props.location.state, {redemptionPin:redemptionPin})
            activateAdvocadoUser(obj).then(res => {
                if (res && res.result_code && res.result_code == '200') {
                    // setRegSuccess(true)
                    let userObj = {
                        mobile: props.history.location.state.mobile,
                        password: props.history.location.state.password,
                        countryCode: props.history.location.state.countryCode,
                        storeId: props.history.location.state.storeId
                    }
                    userLogin(userObj).then((r) => {
                        if (r && r.result_code && r.result_code == "500") {
                            return null;
                        } else if (r.result_code == "200") {
                            set("USER_INFO", r);
                            dispatch(USERINFO(r));
                            dispatch(login(true))
                            props.history.replace(constants.STORE_HOME)
                        }
                    });
                }
            })
        }else{
            setShowError(true)
        }
    }
  return (
      <>
      {props.location.state === undefined && <Redirect to={constants.SIGNUP_ROUTE}/>}
          {!regSuccess ? <div className="px-5 col-10 mx-auto">
              {mystate.logged && <Redirect to={constants.APP_ROUTE}/>}
              <h2 className="text-dark my-0">Create Redemption PIN</h2>
              <form className="mt-5 mb-4" autoComplete='off'>
                  <div className="form-group">
                      <label htmlFor="pin" className="text-dark">
                          Enter Redemption Pin (4 Digit)
                      </label>
                      <input
                          type="tel"
                          maxLength="4"
                          placeholder="e.g. 1234"
                          className="form-control letterSpacing"
                          id="pin"
                          value={redemptionPin}
                          onChange={(e) => handleChange(e)}
                      />
                      {showError && !redemptionPin.match(constants.redemptionPinRegex) ?
                          <p className="text-warning">Only Numbers allowed</p> : ""}
                  </div>
                  <div className="form-group">
                      <input
                          type="tel"
                          maxLength="4"
                          placeholder="Confirm Pin"
                          className="form-control letterSpacing"
                          id="confirmPin"
                          value={checkPin}
                          onChange={(e) => handleChange(e)}
                      />
                      {showError && !checkPin.match(constants.redemptionPinRegex) ?
                          <p className="text-warning">Only Number's
                              Allowed</p> : showError && checkPin != redemptionPin ?
                              <p className="text-danger">Pin Not Match</p> : ""}
                  </div>
                  <button onClick={(e) => submitHandler(e)} className="btn btn-primary btn-lg btn-block">
                      Continue
                  </button>
              </form>
          </div> : <h3 className='text-center'>Registration Successful. <Link to={constants.LOGIN_ROUTE}>Sign In </Link></h3>}
      </>
  );
};
export default RedemptionPin;
