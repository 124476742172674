import React, {useEffect, useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {constants} from "../commons/constants";
import {getOrderHistory} from "../commons/APIService";
import BackButton from "./commons/BackButton";
import * as Texts from "../commons/Texts";

const OrderHistory = (props) => {
    let state = useSelector(state => state.impReducer)
    let [receiptItems, setReceiptItems] = useState(null)
    let [totalPrice, setTotalPrice] = useState(0)
    let [totals, setTotals] = useState(null)
    let id = state.storeNote.storeId
    let sid = state.storeNote.sessionId
    useEffect(() => {
        getOrderHistory(id,sid).then(res => {
            console.log("order history response",res)
            setReceiptItems(res.receiptItems.items)
            setTotals(res.receiptItems.totals)
        })
    }, [])
    useEffect(()=> {
        let tp= 0;
        if(receiptItems && receiptItems != null){
            Object.keys(receiptItems).map(key=> tp = tp+ Number(receiptItems[key].subTotal))
        }
        setTotalPrice(tp)
    },[receiptItems])
    useEffect(()=>{
    }, [totals])
    return (<div className='h-100'>
        <BackButton title={Texts.ORDERHISTORY} history={() => props.history.replace(constants.START_ORDER)}/>
        <div className='card'>
            <div className='custom-card-body'>
                <div className='custom-wrap pt-2 mt-3'>
                    <table className='table table-hover table-striped table-borderless'>
                        <thead>
                        <tr>
                            <th className='position-sticky'>Qty</th>
                            <th className='position-sticky'>Name</th>
                            <th className='position-sticky'>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {receiptItems && Object.keys(receiptItems).map((key) =>
                            <tr >
                                <td>{receiptItems[key].quantity}</td>
                                <td>{receiptItems[key].name}</td>
                                <td> {parseFloat(receiptItems[key].subTotal).toFixed(2)}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='card-footer'>
                <table className='table table-hover table-light'>
                    <tbody>
                    {totals && totals != null && totals.map(r => <tr>
                        {/*<td></td>*/}
                        <td>{r.description}</td>
                        <td></td>
                        <td>$&nbsp;{r.value}</td>

                    </tr>)}
                    </tbody>
                </table>
                <button className='btn btn-primary btn-block' onClick={()=>props.history.replace(constants.START_ORDER)}> Order More </button>
            </div>
        </div>
    </div>)
}

export default OrderHistory;
