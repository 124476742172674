import React from "react";
import {constants} from "../../commons/constants";

const PaymentErrorComponent = (props) => (
  <div className='d-flex justify-content-center align-items-start bg-white' style={{height: '100vh'}}>
    <div className='col-md-12 bg-white'>
      <div className='text-center pb-3 pt-3'>
        <h2>{props.title}</h2>
        <p>{props.message}</p>
      </div>
      <div className='d-flex flex-row justify-content-center'>
        <img style={{width: '50%', maxWidth: '250px', height: 'auto', display: 'block'}} src={props.image}/>
      </div>

    </div>
    <div className='position-absolute w-100 d-flex flex-row justify-content-center' style={{bottom: '20px'}}>
      <button className='btn btn-facebook border' style={{width: '80%'}} onClick={() => {
        props.history.replace(constants.CART)
      }}>TRY AGAIN
      </button>
    </div>
  </div>
);
export default PaymentErrorComponent;
