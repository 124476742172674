import dineIn from '../images/DINE-IN.svg'
import takeAway from '../images/TAKE-AWAY.svg'
import pickUp from '../images/PICK-UP.svg'
import React, {useEffect, useState} from "react";
import {constants} from "../commons/constants";
import {useDispatch, useSelector} from "react-redux";
import {mobileOrder, paymentInformationUpdate} from "../actions";
import "react-datetime/css/react-datetime.css"
import Datetime from 'react-datetime'
import * as texts from "../commons/Texts";
import {countryCodeList} from "../commons/utility";
import moment from "moment";
import 'moment/locale/sv';
import {LeftCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
const SalesType = (props) => {
    const state = useSelector(state => state.impReducer)
    const dispatch = useDispatch();
    const salesType = state.salesType;
    const [date, setDate] = useState(moment().format('yyyy-MM-DD'))
    const [time, setTime] = useState( moment().format('HH:mm'))
    const [storeStartTime, setStoreStartTime] = useState()
    const [storeOffTime, setStoreOffTime] = useState()
    const [storeCloseTime, setStoreCloseTime] = useState()
    const [dayId, setDayId] = useState()
    const [type, setType] = useState(null);
    const [toLabel, setToLabel] = useState(false)
    const [toSelectTime, setToSelectTime] = useState(false)
    const [name, setName] = useState('')
    const [showError, setShowError]= useState(false)
    const [email, setEmail] = useState('')
    const [countryCode,setCountryCode] = useState('65')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [pagerValue,setPagerValue] = useState('')
    const [timeSlots, setTimeSlots] = useState([])
    const defaultBorder = '1px solid #ddd';
    const [dateTime, setDateTime] = useState("NOW")
    const [border, setBorder] = useState({
        DineIn: defaultBorder,
        Takeaway: defaultBorder,
        PickUp: defaultBorder
    })

    function getAllowedTimeList (minH,maxH, maxM) {
        //console.log(storeOffTime, maxH, maxM)
        const minHour = minH;
        const maxHour = maxH;
        const maxMinutes= maxM
        const hourArray = [];

        for (let i = minHour; i<= maxHour; i++) {
            hourArray.push(i);
        }

        const timeArray = [];
        const d = new Date();
        const cH = d.getHours();
        const cM = d.getMinutes();
        hourArray.map(h =>  {
            if(h < maxHour){
                if (h > cH && cM < 45) timeArray.push(`${h.length == 1 ? '0' + h : h}:00`);
                if ((h > cH || (h == cH && cM == 0))) timeArray.push(`${h.length == 1 ? '0' + h : h}:15`);
                if (h > cH || (h == cH && cM < 30  && (cM <= 15 && cM != 0))) timeArray.push(`${h.length == 1 ? '0' + h : h}:30`);
                if (h > cH || (h == cH && cM < 45 && (cM <= 30 && cM != 15))) timeArray.push(`${h.length == 1 ? '0' + h : h}:45`);
            }else if (h == maxHour){
                if (h > cH && maxMinutes >= 30) timeArray.push(`${h.length == 1 ? '0' + h : h}:00`);
                if (maxMinutes > cM && cM > 45) timeArray.push(`${h.length == 1 ? '0' + h : h}:15`)
                if (maxMinutes > cM && cM > 55) timeArray.push(`${h.length == 1 ? '0' + h : h}:30`)
            }
        });
        return timeArray;
    }

    useEffect(()=> {
        let today = moment.now()
        let val= moment(today).format("yyyy-MM-DD")
        getDayId(val)
    },[])

    const setStartCloseTime = (id) =>{
        let maxTime = moment(state.workTime.real[id-1].time[0].end)._i
        const [hoursMax, minutesMax, secondsMax] = maxTime.split(":")
        setStoreCloseTime(hoursMax)
        let startTime = moment(state.workTime.real[id-1].time[0].start)._i
        const [hoursMin, minutesMin, secondsMin] = startTime.split(":")
        setStoreOffTime(`${hoursMax}:${minutesMax}`)
        setTimeSlots(getAllowedTimeList(hoursMin, hoursMax, minutesMax))
    }

    useEffect(()=> {
        //console.log(timeSlots)
    }, [timeSlots])

    const getDayId = (value) => {
        switch ((moment(value).isoWeekday()).toString()){
            case '1':
                setStartCloseTime(1)
                setDayId(1)
                break;
            case '2':
                setStartCloseTime(2)
                setDayId(2)
                break;
            case '3':
                setStartCloseTime(3)
                setDayId(3)
                break;
            case '4':
                setStartCloseTime(4)
                setDayId(4)
                break;
            case '5':
                setStartCloseTime(5)
                setDayId(5)
                break;
            case '6':
                setStartCloseTime(6)
                setDayId(6)
                break;
            case '7':
                setStartCloseTime(7)
                setDayId(7)
                break;

        }
    }


    const onChangetInput = (id,value) =>{
        //console.log(id, value)
        if (id === 'name'){
            if(value.match(/^$|[a-zA-Z ]+$/ )){
                setShowError(false)
                setName(value)
            }
        }else if (id == 'dateTimePick'){
            setTime(moment().format("HH:mm"))
            //console.log('hello')
            setShowError(false)
            setDateTime(value)
        }else if(id==='email'){
            setShowError(false)
            setEmail(value)
        }else if(id === 'phone'){
            setShowError(false)
            setPhoneNumber(value)
        }else if (id === 'countryCode'){
            setCountryCode(value)
        }else if (id === 'date'){
            setShowError(false)
            let dateNow = moment().format('yyyy-MM-DD')
            //console.log(moment(value)._i)
            let selectedDate = moment(value)._i
            if(selectedDate >= dateNow) {
                //console.log(moment(value).format('ddd'))
                setDate(value)
                getDayId(value)
            }else if (moment(value)._i < moment().format('yyyy-MM-DD')) setShowError(true)
        }else if (id === 'time'){
            setShowError(false)
            let maxTime = moment(state.workTime.real[dayId-1].time[0].end)._i
            setStoreCloseTime(maxTime)
            let startTime = moment(state.workTime.real[dayId-1].time[0].start)._i
            setStoreStartTime(startTime)
            let selectedTime = moment(value)._i

            if(selectedTime < maxTime && selectedTime > startTime) {
                //console.log(date, moment().format("yyyy-MM-DD"))
                if(date ==  moment().format("yyyy-MM-DD") && selectedTime > moment().format('HH:mm:ss')){
                    //console.log('current date and time is greater than required')
                    setTime(value)
                }else if (date !=  moment().format("yyyy-MM-DD")){
                    //console.log('date is not current date')
                    setTime(value)
                }else setShowError(true)
            }else setShowError(true)
        }
    }

    const clickhandler = (e) => {
        if(e.target.id === 'bg01'){
            props.history.goBack();
        }
    }
    const onNumPadClick = (v,isDel) =>{
        if(pagerValue.length <3 && !isDel) {
            setPagerValue((state) => state + v.target.value)
        }else if(isDel && pagerValue.length != 0){
            let str = pagerValue;
            str = str.slice(0,-1);
            setPagerValue(str)
        }
    }
    const onConfirm = (type) => {
        setType(type);

        if(type != null && type.id != 5){
            setToLabel(true)
            //console.log(type, props)
        }else if (type.id == 5){
            setToSelectTime(true)
        }
        else {alert("Please Select Order Type")}
    }
    const validateInput = () => {
        //console.log(dateTime)
        if(!email.match(constants.emailAddressRegex)){
            setShowError(true)
            return false
        }else if (name.length < 2){
            setShowError(true)
            return false
        }else if (!phoneNumber.match(constants.phoneNumberRegex)){
            setShowError(true)
            return false
        }else if(date < moment.now()){
            setShowError(true)
            return false
        }
        else if (dateTime < moment().format("HH:mm")) {
                setShowError(true)
        }
        else {
            return true
        }
    }
    const onPayment = () =>{
        if (type.id === 5){
            if(validateInput()){
                let timeCheck = dateTime == 'NOW'?moment().format("HH:mm") : dateTime
                let schedule = date+"T"+timeCheck;
                let trial = state.mobileOrder;
                let payInfoObj = state.paymentInformation;
                payInfoObj.label = null;
                dispatch(paymentInformationUpdate(payInfoObj))
                trial.request.transaction.scheduledate = schedule;
                trial.request.transaction.notifyname= name;
                trial.request.transaction.notifyemail= email;
                trial.request.transaction.notifymobile= phoneNumber;
                trial.request.transaction.notifycountrycode= countryCode;
                trial.request.transaction.saletypenum = dateTime && dateTime == "NOW" ?2 : Number(type.id);
                props.history.push({
                    pathname: constants.PAYMENT,
                    state: trial
                })
            }
        } else {
            localStorage.setItem('label', pagerValue)
            let payInfoObj = state.paymentInformation;
            payInfoObj.label = pagerValue;
            dispatch(paymentInformationUpdate(payInfoObj))
            let trial = state.mobileOrder;
            trial.request.transaction.label = pagerValue;
            trial.request.transaction.saletypenum = Number(type.id);
            props.history.push({
                pathname: constants.PAYMENT,
                state: trial
            })
        }
    }
    return (
            <div className='modal' area-hidden="true" role="dialog" id='bg01' tabIndex={-1} style={{backgroundColor:"rgba(23,23,33, 0.5)"}} onClick={(e) => clickhandler(e)}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role="document">
                {toLabel && toLabel == true ?
                    <div className='modal-content'>
                        <div className='popup-header bg-primary'>
                            <h5 className='popup-title text-white'> Pager Code </h5>
                            <span className='popup-close-btn-left' onClick={() => setToLabel(false)}>
                                <LeftCircleTwoTone style={{fontSize: "20px"}} twoToneColor="#d92662"/>
                            </span>
                        </div>
                        <div className='modal-body text-center p-0'>
                            <label className='p-2 pager-text'>{state.pagerInfo.Message}</label>

                            <img className='custom-item-img' src={state.pagerInfo.Image}/>

                            <input className="pager-input ant-input" type='tel' maxLength={3} value={pagerValue} onChange={(e)=> {
                                setPagerValue(e.target.value)}}/>

                            <div className='pager-keypad'>
                                <div className='row mt-2 keypad-row'>

                                    <button
                                        className='col keypad-key'
                                        value='1' onClick={(e)=>{ onNumPadClick(e)}} >1
                                    </button>
                                    <button className='col keypad-key' value='2'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                    >2</button>

                                    <button className='col keypad-key'
                                            value='3'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                    >3</button>
                                </div>
                                <div className='row keypad-row'>

                                        <button
                                            className='col keypad-key'
                                            value='4'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                        >4</button>


                                        <button
                                            className='col keypad-key'
                                            value='5'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                        >5</button>


                                        <button
                                            value='6'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                            className='col keypad-key'>6
                                        </button>

                                </div>
                                <div className='row keypad-row'>

                                        <button
                                            value='7'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                            className='col keypad-key'>7</button>


                                        <button
                                            value='8'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                            className='col keypad-key'>8</button>

                                        <button
                                            value='9'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                            className='col keypad-key'>9</button>
                                </div>
                                <div className='row keypad-row'>
                                        <button onClick={()=> setPagerValue('')}  className='col keypad-key'>C</button>

                                        <button
                                          value='0'
                                            onClick={(e)=>{ onNumPadClick(e)}}
                                            className='col keypad-key'>0</button>

                                        <button
                                        onClick={(e)=>{ onNumPadClick(e,true)}}
                                        className='col keypad-key'><i className="feather-delete"/></button>

                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-primary m-auto font-weight-bold' onClick={onPayment}>Proceed to Payment</button>
                        </div>
                    </div> : toSelectTime === true ? <div className='modal-content'>
                      <div className='popup-header bg-primary'>
                          <h5 className='popup-title text-white'> Enter Contact Information </h5>
                          <span className='popup-close-btn-left' onClick={() => setToSelectTime(false)}>
                              <LeftCircleTwoTone style={{fontSize: "20px"}} twoToneColor="#d92662"/>
                          </span>
                      </div>
                        <div className='modal-body'>
                            <div className='d-flex flex-column align-content-center justify-content-center'>
                                    <div className="form-group">
                                        <label className='d-block form-label'>Select PickUp time:</label>
                                        <input className='d-inline-block form-control input-group w-50' id='date' type='date' disabled value={date}
                                        />
                                        <select id='dateTimePick' value={dateTime} className='d-inline-block form-control ml-2' style={{width:'38%'}} onChange={(e)=> onChangetInput(e.target.id, e.target.value)}>
                                            <option value="NOW" >NOW</option>
                                            {timeSlots.length > 0 && timeSlots.map(ts => <option value={ts}>{ts}</option>)}
                                        </select>
                                        {showError && dateTime && dateTime !="NOW" && dateTime < moment().format("HH:mm") &&
                                            <label className='text-danger d-block'>Select between {time} and {storeOffTime}</label>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className='form-label'>Name</label> &nbsp;
                                        <input id='name' onChange={(e)=> onChangetInput(e.target.id,e.target.value)} type='text' value={name} className='form-control'/>
                                        {showError && name.length < 2 && <p className='text-danger'> Please enter your full name</p>}
                                    </div>
                                    <div className="form-group">
                                        <label className='form-label'>Email</label> &nbsp;
                                        <input id='email' onChange={(e)=> onChangetInput(e.target.id,e.target.value)} type='email' value={email} className='form-control'/>
                                        {showError && (email.length ==0?
                                          <p className='text-danger'>Please enter your email address</p> :
                                          (!email.match(constants.emailAddressRegex) &&<p className='text-danger'>Enter a valid email id </p>))}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobile" className="text-dark d-block">{texts.ENTER_PHONE_NO}</label>
                                        <select
                                          id="countryCode"
                                          placeholder={texts.COUNTRY_CODE_PLACEHOLDER}
                                          className="form-control d-inline w-25 pl-2"
                                          value={countryCode}
                                          onChange={(e) => onChangetInput(e.target.id, e.target.value)}
                                        >
                                            {countryCodeList()}
                                        </select>
                                        <input
                                          id="phone"
                                          maxLength = "8"
                                          required={true}
                                          type="tel"
                                          title="you can only enter numbers"
                                          value={phoneNumber}
                                          className="form-control d-inline w-75 pl-2 letterSpacing"
                                          placeholder={texts.PHONE_NO_PLACEHOLDER}
                                          onChange={(e) => onChangetInput(e.target.id,e.target.value)}
                                        />
                                        {showError && !phoneNumber.match(constants.phoneNumberRegex) && <p className='text-danger'>Please enter a valid phone number</p>}
                                    </div>

                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-primary m-auto font-weight-bold' onClick={onPayment}>Proceed to Payment</button>
                        </div>
                    </div>: <div className='modal-content'>
                    <div className='popup-header bg-primary'>
                        <h5 className='popup-title text-white'> Select Order Type </h5>
                        <span className='popup-close-btn' onClick={() => props.history.go(-2)}>
                            <CloseCircleTwoTone style={{fontSize: "20px"}} twoToneColor="#d92662"/></span>
                    </div>
                    <div className='modal-body text-center' align='center'>
                            <div className='order-type' id='dine' style={{border: border.DineIn}}
                                 onClick={() => onConfirm(salesType.DineIn)}>
                                <img src={dineIn} alt=""/>
                                <h5 className='card-title'> Dine In</h5>
                            </div>
                            <div className='order-type' style={{ border: border.PickUp}}
                                 onClick={() => onConfirm(salesType.PickUp)}>
                                <img src={pickUp} className='' alt=""/>
                                <h5 className='card-title'> Pick Up</h5>
                            </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default SalesType;
