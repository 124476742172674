import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import StoreLanding from "./component/StoreLanding";
import TNC from "./component/TNC";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import Header from "./component/commons/Header";
import FooterMobileMenu from "./component/commons/FooterMobileMenu";
import HeaderSmall from "./component/commons/HeaderSmall";
import Loading from "./component/commons/Loading";
import { constants } from "./commons/constants";
import Privacy from "./component/Privacy";
import RefundCancellationPolicy from "./component/RefundCancellationPolicy";
import Feedback from "./component/Feedback";
import {get, set} from "./commons/storageService";
import {getMenuData, locationUpdate, removeItemBasket, setRedeemable, showAlertPopUp, USERINFO} from "./actions";
import {advocadoTopUpCredit, baseURL, getCustomerInfo, verifyRedemptionPIN} from "./commons/APIService";
import AddToCart from "./component/AddToCart";
import Cart from "./component/Cart";
import SalesType from "./component/SalesType";
import Sidemenu from "./component/commons/sidemenu";
import Payment from "./component/Payment";
import StoreResponse from "./component/StoreResponse";
import Processing from "./component/Processing";
import PaymentToStoreResponse from "./component/PaymentToStoreResponse";
import TopUpCredit from "./component/TopUpCredit";
import Alert from "./component/authentication/utility/Alert";
import StartOrder from "./component/StartOrder";
import PaymentTimeout from "./component/PaymentTimeout";
import PaymentCancel from "./component/PaymentCancel";
import OrderHistory from "./component/OrderHistory";

const App = (props) => {
  window.addEventListener("beforeunload", () => localStorage.removeItem('product_id'))
  window.addEventListener("beforeunload", () => localStorage.removeItem('child_cat'))
    const dispatch = useDispatch();
  const my_state = useSelector((state) => state.impReducer);
  let alertShowHide = my_state && my_state.alert && my_state.alert.showPopUp ? my_state.alert.showPopUp : false
    const getStoreID = my_state.store.store_id
    const getMenu = () => dispatch(
        getMenuData(getStoreID)
    );
    const getCustomer = () => {
        const USER_INFO = my_state && my_state.userInfo && Object.keys(my_state.userInfo).length != 0 && my_state.userInfo;
        const storeId = getStoreID
        const objCredentials = get('objCredentials')
        if (!USER_INFO) {
            dispatch(USERINFO({}));
            return null;
        } else if (!my_state.guest && USER_INFO) {
            const obj = {
                storeId: storeId,
                countryCode: USER_INFO && USER_INFO.CRM ? USER_INFO.CRM.countryCallingCode : objCredentials && objCredentials.countryCode && objCredentials.countryCode,
                mobile: USER_INFO && USER_INFO.CRM ? USER_INFO.CRM.phoneNumber : objCredentials && objCredentials.mobile && objCredentials.mobile,
            };
            if (obj.countryCode && obj.mobile) { // if user is logged in and has country code and mobile number
                getCustomerInfo(obj).then((r) => {
                    if (r && r.result_code && r.result_code == "500") {
                        return null;
                    } else if (r.result_code == "200") {
                        set("USER_INFO", r);
                        dispatch(USERINFO(r));
                    } else {
                        dispatch(USERINFO(USER_INFO));
                        return null;
                    }
                });
            } else { // if user is logged in but has no country code or mobile number
                dispatch(USERINFO({}));
                return null;
            }
        }
    };
  const location = useLocation()
    let totalProuctQuantity = 0;
    const getQuantity = () => {
        let x
        my_state.basket.map(i=> {
            // console.log(i[1].quantity)
            x = Number(i[1].quantity)
            totalProuctQuantity = x + totalProuctQuantity;
        })
        return totalProuctQuantity
    }
    totalProuctQuantity = getQuantity();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let resultIndicator = params.resultIndicator
    let type = params.type
    useEffect(()=>{
        if(type == 'stored-value' || type == 'prepaid'){
            let obj =localStorage.getItem('TopUpObj') &&  JSON.parse(localStorage.getItem('TopUpObj'))
            let cloudOrderNumber = localStorage.getItem('cloud_order_number')
            //console.log(obj)
            obj.topUp.cloudOrderNumber = cloudOrderNumber;
            obj.topUp.payment.resultIndicator = resultIndicator
            advocadoTopUpCredit(obj).then((res)=>{
                //console.log(res)
                if (res && res.result_code){
                    if(res.result_code == 200) {
                        let handleFunc = () => {
                            props.history.push(constants.STORE_HOME)
                            dispatch(showAlertPopUp({showPopUp:false}))
                        }
                        let data = {
                            message:`${res.desc}: OrderNumber: ${cloudOrderNumber}`,
                            title: 'Success',
                            showPopUp:true
                        }
                        props.history.push(constants.STORE_HOME)
                        dispatch(showAlertPopUp(data))
                    }else if (res.result_code == 500){
                        let data = {
                            message:`${res.desc}`,
                            title: 'Error',
                            showPopUp:true
                        }
                        props.history.push(constants.STORE_HOME)
                        dispatch(showAlertPopUp(data))
                    }
                }
        if (my_state && my_state.locate && my_state.locate.hasTableService != true) {
            getCustomer();
        }
            })
        }
    },[])
    const userInfo = my_state.userInfo;
 const [isModalVisible, setIsModalVisible] = useState(false)
    const [loading,setLoading] = useState(false)
    const[okText,setOkText] = useState("Submit")
  const handleOK = (amount, campaign, pin) => {
      setLoading(true)
      setOkText("Please Wait")
      if(amount && campaign){
          let obj = {
              topUp: {
                  payment: {
                      paymentId: 60,
                      topUpAmount: amount,
                      campaignType: campaign.campaignType
                  },
                  returnUrl: `${baseURL}${constants.STORE_HOME}?type=${campaign.campaignType}`,
                  cancelurl: `${baseURL}${constants.STORE_HOME}`,
                  timeouturl: `${baseURL}${constants.STORE_HOME}`,
                  salesType: 13,
                  storeId: getStoreID,
                  mobile: userInfo.CRM.phoneNumber,
                  countryCode: userInfo.CRM.countryCallingCode,
              }
          }
          localStorage.setItem('TopUpObj', JSON.stringify(obj))
          if(pin){
           let verifyPin = {
               countryCode:userInfo.CRM.countryCallingCode,
               mobile:userInfo.CRM.phoneNumber,
               pin:pin,
               storeId:getStoreID
           }
              verifyRedemptionPIN(verifyPin)
          }else {
              advocadoTopUpCredit(obj).then((res) => {
                  if (res && res.result_code && res.result_code == '200'){
                      let sessionId = res.sessionID;
                      let cloudOrderNumber = res.cloudOrderNumber;
                      let saleType = "TOP Up";
                      localStorage.setItem("cloud_order_number", cloudOrderNumber)
                      let checkOutObj = {
                          session:{
                              id: sessionId,
                          },
                          interaction:  {
                              merchant: {
                                  name: saleType,
                                  address:{
                                      line1:"200 sample st",
                                  },
                              },
                              displayControl: {
                                  billingAddress: "HIDE",
                              }
                          },
                      }
                      window.Checkout.configure(checkOutObj);
                      window.Checkout.showPaymentPage();
                  }
              })
          }
      }

  }
  const handleCancel = ()  => {
      //console.log( 'i am working')
    setIsModalVisible(false)
  }

  useEffect(() => {
    getMenu()
  }, []);
  const [visible, setVisible] = useState(false)
    const [earnedValue, setEarnedValue] = useState([])
    const [campaignType, setCampaignType] = useState('')
  const handleClick = () => {
    setVisible(true)
  };
  const onClose = () => {
    setVisible(false)
  }
  const store = my_state.store;
  let history = props.history;
  const useQuery = () => {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search),[search])
  }
  let  query = useQuery();
  useEffect(() => {
    dispatch(locationUpdate(location.pathname))
    if(location.pathname == constants.STORE_HOME){
      getCustomer();
    }
  }, [location.pathname]);
  const onCampaignClick = (item) => {
    //console.log(item)
    if (item.campaignType === 'prepaid'){
      dispatch(setRedeemable(true))
      dispatch(removeItemBasket([]))
      dispatch(getMenuData(localStorage.getItem('STORE_ID')))
      localStorage.removeItem('product_id')
      localStorage.removeItem('child_cat')
      history.push(`${constants.STORE_HOME}?=redeemable=true`)
    }
  }
  const [topUps, setTopUps] = useState([])
    const [amount, setAmount] = useState(null)
    const [campaign, setCampaign] = useState()
    const amountSelection = (amount) => {
      setAmount(amount)
    }
  const onPrepaidTopUp = (e,item) => {
    e.stopPropagation();
    //console.log(item)
      setCampaign(item)
      setCampaignType(item.campaignType)
    if(item && item.topUpAmounts) {
      setTopUps(JSON.parse(item.topUpAmounts));
      if(item.earnedValue && item.earnedValue.length > 0){
          setEarnedValue(JSON.parse(item.earnedValue))
      }
    }
    setTimeout(()=>setIsModalVisible(true),100)

  }

  return (
    <Switch>
        {my_state.locate && Object.keys(my_state.locate).length != 0 &&
            <div className="fixed-bottom-bar main-app">
            {my_state.loader && <Loading/>}
            {(my_state.location === constants.STORE_HOME) &&
                <>
                    <a
                        onClick={() => handleClick()}
                        className="toggle hc-nav-trigger hc-nav-1 pointer position-fixed"
                        role="button"
                        aria-controls="hc-nav-1"
                    >
                        <span></span>
                    </a>
                    <TopUpCredit okText={okText} loading={loading} amount={amount} campaign={campaign}
                                 amountSelected={amountSelection} topUps={topUps} campaignType={campaignType}
                                 earnedValue={earnedValue} isModalVisible={isModalVisible} handleOK={handleOK}
                                 handleCancel={handleCancel}/>
                    <Sidemenu onClose={onClose} visibility={visible} onPrepaidTopup={onPrepaidTopUp}
                              onCampaignTypeClick={onCampaignClick} history={props.history}/>
                    <Header
                        userInfo={my_state.userInfo}
                        storeInfo={store}
                        history={history}
                        prodQuantity = {totalProuctQuantity}
                    />
                    <HeaderSmall prodQuantity = {totalProuctQuantity} history={history} handleSideMenu={handleClick}/>
                </>
            }
            {(my_state.location === constants.STORE_HOME ||
                    my_state.location === constants.TNC ||
                    my_state.location === constants.FEEDBACK_CONTACT ||
                    my_state.location === constants.REFUND_POLICY || my_state.location === constants.PRIVACY) &&
                <FooterMobileMenu prodQuantity = {totalProuctQuantity} history={props.history}/>}

            {alertShowHide && <Alert/>}
            <Route exact path={constants.APP_ROUTE}>
                {my_state.isRedeemable ? <Redirect to={`${constants.STORE_HOME}?redeemable=true`}/> :
                    <Redirect from={constants.APP_ROUTE} to={constants.STORE_HOME}/>}
            </Route>
            <Route exact path={constants.STORE_HOME} component={StoreLanding}/>
            <Route path={constants.TNC} component={TNC}/>
            <Route path={constants.PRIVACY} component={Privacy}/>
            <Route path={constants.REFUND_POLICY} component={RefundCancellationPolicy}/>
            <Route path={constants.FEEDBACK_CONTACT} component={Feedback}/>
            <Route path={constants.ADDTOCART} component={AddToCart}/>
            <Route path={constants.CART} component={Cart}/>
            <Route path={constants.SALESTYPE} component={SalesType}/>
            <Route path={constants.PAYMENT} component={Payment}/>
            <Route path={constants.PAYMENTSUCCESS} component={StoreResponse}/>
            <Route path={constants.PROCESSING} component={Processing}/>
            <Route path={constants.PAYMENTSUCCESSFUL} component={PaymentToStoreResponse}/>
            <Route path={constants.START_ORDER} component={StartOrder} />
            <Route path={constants.ORDER_HISTORY} component={OrderHistory} />
            <Route path={constants.PAYMENT_TIMEOUT} component={PaymentTimeout} />
            <Route path={constants.PAYMENT_CANCEL} component={PaymentCancel} />
        </div>}
    </Switch>
  );
};

export default App;
