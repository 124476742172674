import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {showAlertPopUp} from "../../../actions";

const Alert = (props) => {
    const mystate = useSelector((state) => state.impReducer)
    let alertInfo = mystate.alert
    let dispatch = useDispatch();
    console.log(props)
    const handleClose =  () => {
        dispatch(showAlertPopUp({showPopUp:false}))
    }
    console.log(props)
    return(
        // <div className="alert alert-danger alert-dismissible">
        //     <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={props.onClick}>&times;</a>
        //     <strong>{props.type}</strong> {props.message}
        // </div>
        <div className="modal background-overlay"  id="myModal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    {/*Modal Header */}
                    <div className="modal-header">
                        <h4 className="modal-title">{props.type ? props.type : alertInfo.title}</h4>
                        <button type="button" className="close" data-dismiss="modal"
                                onClick={props.onClick ? props.onClick:handleClose} >&times;</button>
                    </div>

                    {/*Modal body*/}
                    <div className="modal-body">
                        {props.message? props.message : alertInfo.message}
                    </div>

                    {/*Modal footer*/}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal"
                                onClick={props.onClick ? props.onClick:handleClose} >Close</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Alert;