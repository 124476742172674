import React from "react";
import {constants} from "../../commons/constants";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, USERINFO} from "../../actions";
import {remove} from "../../commons/storageService";

const FooterMobileMenu = (props) => {
  const mystate = useSelector(state => state.impReducer)
  let dispatch = useDispatch();
  return (
      <div className="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none">
        <div className="row">
          <div className="col selected">
            <Link to={constants.STORE_HOME} className="text-danger small font-weight-bold text-decoration-none">
              <p className="h4 m-0">
                <i className="feather-home text-danger"/>
              </p>
              Home
            </Link>
          </div>
          {/*<div className="col">*/}
          {/*  <Link to={constants.TNC} className="text-dark small font-weight-bold text-decoration-none">*/}
          {/*    <p className="h4 m-0">*/}
          {/*      <i className="feather-file-text"/>*/}
          {/*    </p>*/}
          {/*    T & C*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className="col bg-white rounded-circle mt-n4 px-3 py-2">
            <div className="rounded-circle mt-n0 shadow btn-primary">
              <Link to={constants.CART} className="pointer menu-list">
                <i className="feather-shopping-cart position-relative">
                  {mystate.basket && mystate.basket.length > 0 &&<span
                      className="custom-badge-cart">{props.prodQuantity}</span>}
                </i>
              </Link>
            </div>
          </div>
          <div className="col">
            {mystate && mystate.locate && (mystate.locate.hasTableService && mystate.locate.tableNum != null) ?
                <a onClick={() => props.history.replace(constants.ORDER_HISTORY)}
                   className="small font-weight-bold text-decoration-none text-danger">
                  <p className="h4 m-0">
                    <i className="feather-list text-danger"/>
                  </p>
                  Order History
                </a> :
                <>{
                  mystate.userInfo && mystate.userInfo.name ? <a onClick={() => {
                    dispatch(login(false));
                    remove("USER_INFO");
                    dispatch(USERINFO({}))
                  }} className="small font-weight-bold text-decoration-none btn">
                    <p className="h4 m-0">
                      <i className="feather-log-out"/>
                    </p>
                    Logout
                  </a> : <a onClick={() => props.history.push(constants.LOGIN_ROUTE)}
                            className="small font-weight-bold text-decoration-none">
                    <p className="h4 m-0">
                      <i className="feather-log-in"/>
                    </p>
                    LogIn
                  </a>
                }</>
            }
          </div>
        </div>
      </div>
  );
}

export default FooterMobileMenu;
