import React, {useEffect, useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateTableNumber} from "../actions";
import {constants} from "../commons/constants";
import {locateStoreById} from "../commons/APIService";
const StartOrder = (props) => {
    const state = useSelector(state => state.impReducer)
    const dispatch = useDispatch();
    const logo = state && state.storeNote && state.storeNote.Images && state.storeNote.Images.Logo;
    // const [tableNum, setTableNumber] = useState(state.myOrders && state.myOrders.tableNumber ? state.myOrders.tableNumber : "")
    let storeCredentials;
    const updateTable = async () => {
        storeCredentials = await locateStoreById(state.storeNote.storeId, state.storeNote.sessionId);
    }
    useEffect(()=> {

        if (state && state.mainMenu && state.mainMenu.length >0){
            //console.log("I will only show once",state.mainMenu)
            updateTable()
        }
    },[])
    const onStartOrder = () => {
        props.history.replace(constants.STORE_HOME)
    }
    const onOrderHistory = () => {
        props.history.replace(constants.ORDER_HISTORY)
    }
    return (
        <div className='login-page vh-100'>
            <div className="bg-login vh-100" style={{background:`url(${state.storeNote.Images.LandingPage}) no-repeat center/cover`}}></div>
            <div className='background-overlay'>
                <div className="d-flex vh-100 back">
                    <div className="d-flex flex-column justify-content-around text-center px-5 col-md-6 col-lg-6 ml-auto vh-100">
                        <div className="img-fluid">
                            <img className="img-header" src={logo}/>
                        </div>
                        <div>
                            <h4 className="store-title-2 text-white">{JSON.parse(state.store.store_name).en}</h4><br />
                            <label className='mt-1 text-white'>Table Number: {state && state.locate && state.locate.tableNum }</label>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <button className='btn btn-primary' onClick={onStartOrder}>Start Order</button>
                            <button className='btn btn-secondary mt-1' onClick={onOrderHistory}>Order History</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartOrder;
