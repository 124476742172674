import * as texts from "../../commons/Texts";
import {countryCodeList} from "../../commons/utility";
import {constants} from "../../commons/constants";
import React, {useEffect, useReducer, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {get} from "../../commons/storageService";
import {getOTP, resetPassword} from "../../commons/APIService";
import {handleFocus} from "./utility/functions";
import Alert from './utility/Alert';
import * as Texts from "../../commons/Texts";

const ForgotPassword = (props) => {
    let history = useHistory();
    const mystate = useSelector((state) => state.impReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        let storeInfo = get(constants.STORE_INFO)
        // TODO: NEED TO CHECK
        let storeID = storeInfo && storeInfo.DeviceInfo && storeInfo.DeviceInfo.store_id ?
            storeInfo.DeviceInfo.store_id : null
        setUserData({ storeId: storeID });
    }, []);
    const [otp, setOtp] = useState(false);
    const [userData, setUserData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            mobile: "",
            password: "",
            confirmPassword:"",
            countryCode: "65",
            storeId: "",
            function:constants.FUNCTION_FORGOT_PASSWORD
        }
    );
    const [enteredPin, setEnteredPin] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            a: "",
            b: "",
            c: "",
            d: "",
            error: false,
        }
    );
    const isValidData = (userData) => {
        return (
            userData &&
            userData.mobile &&
            userData.mobile.match(constants.phoneNumberRegex) &&
            userData.password &&
            userData.password.length >= 6 &&
            userData.password.match(constants.pinRegex)
        );
    };
    const [showError, setShowError] = useState(false);
    const { countryCode } = userData;
    const [showError2, setShowError2] = useState(false);
    const [errorMessage, setErrorMessage] = useState({title:"", message:""});
    const handleChange = (e) => {
        let a = document.getElementById("1").value,
            b = document.getElementById("2").value,
            c = document.getElementById("3").value,
            d = document.getElementById("4").value;
        let id = e.target.id;
        if (e.target.value.match(constants.numberRegex)) {
            setEnteredPin({ error: false });
            if (id == "1") {
                setEnteredPin({ a: e.target.value });
            } else if (id == "2") {
                setEnteredPin({ b: e.target.value });
            } else if (id == "3") {
                setEnteredPin({ c: e.target.value });
            } else if (id == "4") {
                setEnteredPin({ d: e.target.value });
            }
        } else {
            setEnteredPin({ error: true });
        }
        setUserData({
            OTP: `${a}${b}${c}${d}`,
        });
    };

    const otpRequest = (e) => {
        e.preventDefault()
        setShowError(true);
        if (userData.password === userData.confirmPassword){
            if (isValidData(userData)){
                getOTP(userData).then((r)=> {
                    //console.log(r)
                    if (r && r.result_code && r.result_code == "200") {
                        setShowError(false);
                        setOtp(true)
                    }
                } )
            }
        } 
    }

    const reqReset = () => {
        let params = {
            countryCode: userData.countryCode,
            mobile: userData.mobile,
            password: userData.password,
            storeId: userData.storeId,
            otp: userData.OTP
        }
        resetPassword(params).then(r => {
            if (r && r.result_code && r.result_code == '200') {
                history.push(constants.LOGIN_ROUTE)
            } else if (r && r.result_code && r.result_code == '7222'){
                setShowError(true)
            }
        })
    }
    return (
        <>{otp ?  <div>
            <h2 className="mb-3">{texts.ENTER_OTP_TEXT}</h2>
            <h6 className="text-black-50">{Texts.AN_OTP_HAS_BEEN_SENT}</h6>
            {showError && <Alert
                onClick={()=> {
                    setShowError(false)
                    setOtp(false)
                    setEnteredPin({a:'',b:'',c:'',d:''})
                }}
                message={texts.USER_NOT_FOUND}
                type="Error!"
            />}
            <form className="mt-5 mb-4 login-form">
                <div className="row my-5 mx-0 otp">
                    <div className="col pr-1 pl-0">
                        <input
                            id="1"
                            maxLength={1}
                            type="text"
                            placeholder=""
                            value={enteredPin.a}
                            className="form-control form-control-lg"
                            onKeyUp={(e) => handleFocus(e)}
                            onChange={e=>handleChange(e)}
                            autoFocus={true}
                        />
                    </div>
                    <div className="col px-2">
                        <input
                            id="2"
                            maxLength={1}
                            type="text"
                            placeholder=""
                            value={enteredPin.b}
                            className="form-control form-control-lg"
                            onKeyUp={(e) => handleFocus(e)}
                            onChange={e=>handleChange(e)}
                        />
                    </div>
                    <div className="col px-2">
                        <input
                            id="3"
                            maxLength={1}
                            type="text"
                            placeholder=""
                            value={enteredPin.c}
                            className="form-control form-control-lg"
                            onKeyUp={(e) => handleFocus(e)}
                            onChange={e=>handleChange(e)}
                        />
                    </div>
                    <div className="col pl-1 pr-0">
                        <input
                            id="4"
                            type="text"
                            placeholder=""
                            value={enteredPin.d}
                            maxLength={1}
                            className="form-control form-control-lg"
                            onKeyUp={(e) => handleFocus(e)}
                            onChange={e=>handleChange(e)}
                        />
                    </div>
                </div>
                {enteredPin.error ? (
                    <p className="text-warning text-center font-weight-bold">Only Numbers allowed</p>
                ) : (
                    ""
                )}
                <button
                    type="button"
                    onClick={()=> reqReset()}
                    className="btn btn-lg btn-primary btn-block"
                >
                    Verify OTP
                </button>
            </form>
        </div> : <div className="px-5 col-10 mx-auto">
                <h2 className="text-dark my-0">{texts.RESET_PASSWORD}</h2>
                <form className="mt-5 mb-4 login-form" autoComplete="off">
                    <div className="form-group">
                        {showError2 && errorMessage.message && (
                            <Alert
                                onClick={()=> setShowError2(false)}
                                message={errorMessage.message}
                                type={errorMessage.title}
                            />
                        )}
                        <label htmlFor="mobile" className="text-dark d-block mb-2">
                            {texts.ENTER_PHONE_NO}
                        </label>
                        <select
                            id="country-code"
                            placeholder={texts.COUNTRY_CODE_PLACEHOLDER}
                            className="form-control d-inline-block w-auto border pl-2 phone-num-1"
                            value={countryCode}
                            onChange={(e) => {
                                setShowError2(false);
                                setUserData({countryCode: e.target.value});
                            }}
                        >
                            {countryCodeList()}
                        </select>
                        <input
                            type="tel"
                            title="you can only enter numbers"
                            value={userData.phoneNumber}
                            className="form-control d-inline-block pl-2 letterSpacing phone-num-2"
                            placeholder={texts.PHONE_NO_PLACEHOLDER}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            onChange={(e) => {
                                setShowError2(false);
                                setUserData({mobile: e.target.value});
                            }}
                            autoComplete="off"
                        />
                        {showError && !userData.mobile ? (
                            <p className="text-danger">{texts.ENTER_PHONE_NO_MSG}</p>
                        ) : showError &&
                        !userData.mobile.match(constants.phoneNumberRegex) ? (
                            <p className="text-danger">
                                {texts.ENTER_VALID_PHONE_NUMBER}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className="text-dark">
                            {texts.ENTER_NEW_PIN}
                        </label>
                        <input
                            type="password"
                            placeholder={texts.LOGIN_PIN_PLACEHOLDER}
                            className="form-control letterSpacing pl-2 mb-1"
                            id="password"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => setUserData({password: e.target.value})}
                        />
                        {showError && userData.password.length < 6 ? (
                            <p className="text-danger">
                                {texts.PIN_MUST_CONTAIN_6_NUMBERS}
                            </p>
                        ) : showError && !userData.password.match(constants.pinRegex) ? (
                            <p className="text-danger">
                                {texts.PIN_MUST_CONTAIN_6_NUMBERS}
                            </p>
                        ) : (
                            ""
                        )}
                        <input
                            type="password"
                            placeholder={texts.RETYPE_PIN_PLACEHOLDER}
                            className="form-control letterSpacing pl-2"
                            id="confirmPassword"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => setUserData({confirmPassword: e.target.value})}
                        />
            {showError &&
            userData.password.length > 0 &&
            userData.confirmPassword != userData.password ? (
              <p className=" text-danger">{texts.PASSWORD_NOT_MATCH}</p>
            ) : (
              ""
            )}
                    </div>
                    <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={(e) => otpRequest(e)}
                    >
                        <i className="feather-message-circle"/> {texts.GET_OTP}
                    </button>
                    <button
                        className="btn btn-secondary btn-lg btn-block"
                        onClick={(e) => {e.preventDefault(); history.goBack()}}
                    >
                        <i className="feather-log-in"/> Back to Login
                    </button>
                </form>
            </div>}
        </>
    )
}

export default ForgotPassword;