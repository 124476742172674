import {CREATEUSER, login, OTP} from "../../../actions";
import { constants } from "../../../commons/constants";
import {toNumber} from "lodash";


export const isValidData = (data) => {
  return (
      data.mobile &&
      data.mobile.match(constants.phoneNumberRegex) &&
      data.password &&
      data.password.length >= 6 &&
      data.name &&
      data.name.length > 0 &&
      data.email &&
      data.email.match(constants.emailAddressRegex) &&
      data.confirmPassword &&
      data.confirmPassword === data.password
  )
}
export const validateUser = (mystate, dispatch, history, user) => {
  for (let i = 0; i < mystate.registered.length; i++) {
    if (
      mystate.registered[i].countryCode === user.countryCode &&
      mystate.registered[i].phoneNumber === user.phoneNumber
    ) {
      if (mystate.registered[i].loginPin === user.loginPin) {
        dispatch(login(true));
        history.push("/Main");
      } else alert("wrong login Pin");
    }
  }
};
export const handleFocus = (e) => {
  let x = e.target;
  let nextId, id;
  if (e.key === "Backspace" && x.id > 1) {
    nextId = toNumber(x.id) - 1;
    id = JSON.stringify(nextId);
    document.getElementById(id).focus();
  } else if (e.key === "ArrowLeft" && x.id > 1) {
    nextId = toNumber(x.id) - 1;
    id = JSON.stringify(nextId);
    document.getElementById(id).focus();
  } else if (e.key === "ArrowRight" && x.id < 4) {
    nextId = toNumber(x.id) + 1;
    id = JSON.stringify(nextId);
    document.getElementById(id).focus();
  }
  else if (x.value !== "" && x.id < 4 && e.key !== "Backspace") {
    nextId = toNumber(x.id) + 1;
    id = JSON.stringify(nextId);
    document.getElementById(id).focus();
  }
};
