import React, { useEffect, useState } from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {constants} from "../commons/constants";
import {getMenuData, removeItemBasket, setRedeemable} from "../actions";
import {v4 as uuidv4} from "uuid";
import {deleteItem, quantityChange} from "../commons/utility";
import {FrownTwoTone, CloseOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import AlertRemoveProduct from "./authentication/utility/AlertRemoveProduct";

const StoreLanding = (props) => {
  let dispatch = useDispatch();
  let storeid = localStorage.getItem('STORE_ID')
  const mystate = useSelector((state) => state.impReducer);
  let [menuItem, setMenuItem] = useState(null)
  let [mainMenuScroll, setMainMenuScroll] = useState(0)
  let [mainMenuMaxScroll, setMainMenuMaxScroll] = useState(window.innerWidth)
  let [subMenuScroll, setSubMenuScroll] = useState(0)
  let [subMenuMaxScroll, setSubMenuMaxScroll] = useState(window.innerWidth)
  const [confirmDelete, setConfirmDelete] = useState(null)
  const deleteProduct= () => {
    setConfirmDelete(null)
  }
  const confirmDelProduct = ()  => {
    deleteItem(confirmDelete)
    setConfirmDelete(null)
  }

  useEffect(()=>{
    if(mystate.isRedeemable){
      props.history.push(`${constants.STORE_HOME}?redeemable=true`)
    }
  }, [])



  const [subMenu, setSubMenu] = useState(
    JSON.parse(localStorage.getItem("child_cat"))
  );
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(
    localStorage.getItem("child_cat") ? true : false
  );
  useEffect(() => {
    if (mystate && mystate.allProducts && mystate.allProducts.products) {
      if (localStorage.getItem("product_id")) {
        subMenuHandleClick(localStorage.getItem("product_id"));
      } else {
        let id = mystate?.mainMenu[0]?.id;
        setMenuItem(id);
        subMenuHandleClick(id);
      }
    }
  }, [mystate.allProducts.products]);
  const MainMenu = () => {
    let Menu = mystate.mainMenu;
    return Menu.map((select, i) => {
      let name = JSON.parse(select.cat_name);
      return (
        <div
          className="col"
          //width 150 and height img 100px
          style={{width:'110px', maxWidth: '110px', minWidth: '110px'}}
          key={select.id}
          id={select.id}
          onClick={() => handleMenuClick(select.id)}
        >
          <div className={`rounded d-block p-2 text-center shadow-sm pointer menu-item ${select.id == menuItem ? 'item-active' : ''}`}>
            <img
              style={{width:"100px", height:"80px"}}
              alt="#"
              src={select.cat_pic}
              className="img-fluid mb-2"
              onClick={(e) => handleMenuClick(e, select.id)}
            />
            <span className="m-0 small text-truncate d-block">{name.en}</span>
          </div>
        </div>
      );
    });
  };

  const handleMenuClick = (id) => {
    setMenuItem(id);
    mystate.mainMenu.map((select, i) => {
      if (id == select.id) {
        if (select.child.length != 0) {
          localStorage.setItem("child_cat", JSON.stringify(select.child));
          setSubMenu(select.child);
          setShow(true);
          setProducts([]);
        } else {
          localStorage.removeItem("child_cat");
          setShow(false);
          setSubMenu();
          let arr = [];
          if (
            mystate &&
            mystate.allProducts &&
            mystate.allProducts.products &&
            mystate.allProducts.products.length > 0
          ) {
            localStorage.setItem("product_id", id);
            mystate.allProducts.products.map((select, i) => {
              if (id == select.cate_id) {
                arr.push(select);
              }
            });
          }
          setProducts(arr);
        }
      }
    });
  };
  const subMenuHandleClick = (id) => {
    localStorage.setItem("product_id", id);
    //console.log(window.innerWidth)
    let arr = [];
    mystate.allProducts.products.map((select, i) => {
      if (id == select.cate_id) {
        arr.push(select);
      }
      setProducts(arr);
    });
  };
  const subMenuCreation = () => {
    return subMenu.map((select, i) => {
      let name = JSON.parse(select.cat_name);
      return (
        <div
          className="col"
          style={{width:'110px', maxWidth: '110px', minWidth: '110px'}}
          key={select.id}
          id={select.id}
          onClick={() => subMenuHandleClick(select.id)}
        >
          <div className={`rounded d-block p-2 text-center shadow-sm pointer menu-item ${select.id == menuItem ? 'item-active' : ''}`}>
            <img
              style={{width:"100px", height:"80px"}}
              alt="#"
              src={select.cat_pic}
              className="img-fluid mb-2"
              onClick={() => subMenuHandleClick(select.id)}
            />
            <span className="m-0 small d-block">{name.en}</span>
          </div>
        </div>
      );
    });
  };


  const selectProduct = (product, type) => {
    let alaCarte = false;
    let allProductAtt = mystate.allProductsAtt.atts;
    let comboInfo = mystate.comboInfo.data;
    let comboProducts = mystate.comboProducts.products;
    let modifiers = [];
    let comboCategories = [];
    let mainProduct='';
    let mustSelectComboDrink = false;
    if (product.hasModifiers == 1){
      comboInfo.map((data, i) => {
        if (data.cdata.length > 1 ) {
          data.cdata[0].product_id.map((key) => {
            if (key.product_id === product.product_id) {
              mainProduct = data.activity_sn;
              //console.log('combo data without modifier',key)
              for (let i = 1; i < data.cdata.length; i++) {
                let d = data.cdata[i];
                //console.log("min max",d)
                if (d.max_num == 1 && d.min_num == 1) {
                  mustSelectComboDrink = true
                }
                let comboItems = {
                  label: JSON.parse(d.type_name).en,
                  products: [],
                  maxSelect: d.max_num,
                  minSelect: d.min_num,
                }
                //console.log(comboProducts, d.product_id)
                d.product_id.map(combo => {
                  comboProducts.map((match) => {
                    if (combo.product_id === match.product_id) {
                      match.price = combo.product_price
                      comboItems.products.push(match)
                    }
                  })
                })
                comboCategories.push(comboItems);
              }
              //console.log("COMBO CATEGORIES: ", comboCategories)
            }
          })
        }
      })
      let modiFound = false;
      allProductAtt.map((data) => {
        if (product.product_id === data.product_id) {
          modifiers = data.atts;
          modiFound = true;
        }
      });
      props.history.push({
        pathname: constants.ADDTOCART,
        state: {
          product: product,
          modifiers: modifiers,
          comboCategories: comboCategories,
          mainProductSn: mainProduct,
          mustSelectComboDrink: mustSelectComboDrink
        },
      })
    } else if (product.hasModifiers == 0){
      if (alaCarte == false) {
        comboInfo.map((data, i) => {
          if (data.cdata.length > 1) {
            data.cdata[0].product_id.map((key) => {
              if (key.product_id === product.product_id) {
                mainProduct = data.activity_sn;
                //console.log('combo data without modifier', key)
                for (let i = 1; i < data.cdata.length; i++) {
                  let d = data.cdata[i];
                  //console.log("min max", d)
                  if (d.max_num == 1 && d.min_num == 1) {
                    mustSelectComboDrink = true
                  }
                  let comboItems = {
                    label: JSON.parse(d.type_name).en,
                    products: [],
                    maxSelect: d.max_num,
                    minSelect: d.min_num,
                  }
                  //console.log(comboProducts, d.product_id)
                  d.product_id.map(combo => {
                    comboProducts.map((match) => {
                      if (combo.product_id === match.product_id) {
                        match.price = combo.product_price
                        comboItems.products.push(match)
                      }
                    })
                  })
                  comboCategories.push(comboItems);
                }
              }
            })
          }
        })
        if (comboCategories.length > 1){
          props.history.push({
            pathname: constants.ADDTOCART,
            state: {
              product: product,
              comboCategories: comboCategories,
              mainProductSn: mainProduct,
              mustSelectComboDrink: mustSelectComboDrink
            },
          })
        }else if (comboCategories.length < 1){
          alaCarte = true
          console.log(alaCarte)
        }
      }
      if (alaCarte === true) {
        let newBasket = mystate.basket
        let found = false
        //console.log('I am here')
        newBasket.map((item, i) => {
          //console.log("My Prod", item, product);
          if (item[1].prodNum && item[1].prodNum === product.product_sn) {
            let q = item[1].quantity + 1
            let totalPrice = product.price * q
            item[1].quantity = q;
            item[0].totalPrice = totalPrice;
            item[0].prodInfo = product;
            dispatch(removeItemBasket(newBasket))
            found = true;
          }
        })
        if (!found) {
          let data = [{
            MainProduct: '',
            id: uuidv4(),
            MainProductPrice: parseFloat(product.price),
            totalPrice: parseFloat(product.price),
            prodInfo: product
          },
            {
              Modifiers: [],
              prodNum: product.product_sn,
              quantity: 1
            }]
          newBasket.push(data)
          dispatch(removeItemBasket(newBasket))
        }
      }
    }
  };
  const [showOldEntry, setShowOldEntry] = useState('none')
  const [productSelected, setProductSelected] = useState(null)
  const duplicateProduct = (productSelected) => {
    let checkBasket = mystate.basket
      let returnItem = checkBasket.map((item) => {
        if(item[1].prodNum === productSelected.product_sn) {
          return (
              <div className='col'>
                <div className='customize-box'>
                  {
                    (productSelected && productSelected.hasModifiers > 0) &&
                    <p className='small text-center'>
                      Click here to create
                      <br/>
                      <div className='new-link' onClick={()=>selectProduct(productSelected)}>
                        <PlusOutlined/>
                        <span>&nbsp;New Customization</span>
                      </div>
                    </p>
                  }
                  <div className='img-box'>
                    <img className='prod-img' src={item[0].prodInfo.product_pic}/>
                    <span className="prod-name">{JSON.parse(item[0].prodInfo.product_name).en}</span>
                    <DeleteOutlined className='icon-top-right position-absolute' onClick={()=> setConfirmDelete(item[0].id)}/>
                  </div>
                  <div className="d-flex flex-column align-content-center justify-content-between mt-2 ">

                    <div className="d-flex flex-row justify-content-center mb-2">
                      <div className='count-number float-right'>
                        <button type='button' className='btn-sm left dec btn btn-outline-secondary'
                                onClick={()=> quantityChange("subtract",item[0].id, null, setConfirmDelete) }>
                          <i className='feather-minus'/>
                        </button>
                        <input className="count-number-input text-white bg-secondary" value={item[1].quantity} readOnly/>
                        <button type='button' className='btn-sm left dec btn btn-outline-secondary'
                                onClick={()=> quantityChange("add", item[0].id)}>
                          <i className='feather-plus'/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {
                    item[0] && item[0].selectedCombo && Object.keys(item[0].selectedCombo).map((key, i) =>
                      item[0].selectedCombo[key] && item[0].selectedCombo[key].length > 0 &&
                      <div className="cart-mod">
                        <h4 className="cart-mod-title">- {key}</h4>
                        {item[0].selectedCombo[key].map(item => <p
                          className="cart-mod-item">{item.name}</p>)}
                      </div>
                    )
                  }
                  {
                    item[0] && item[0].selectedModifiers && Object.keys(item[0].selectedModifiers).map((key, i) =>
                      item[0].selectedModifiers[key] && item[0].selectedModifiers[key].length > 0 &&
                      <div className="cart-mod">
                        <h4 className="cart-mod-title">- {key}</h4>
                        {item[0].selectedModifiers[key].map(item=><p className="cart-mod-item">{item.name}</p>)}
                      </div>
                    )
                  }
                </div>
              </div>
          )
        }
      })
    let condition = false;

    returnItem.forEach((item)=> {if(item)condition = true})
    return condition ?returnItem : <div><h1>Cart Is Empty</h1></div>
  }
  const productMenuCreation = () => {
    let checkBasket = mystate.basket;
    return products.map((select, i) => {
      let name = JSON.parse(select.product_name);
        return (
            <div className="product-container col-xs-12 col-sm-12 col-md-6 col-lg-4" onClick={()=> selectProduct(select)} key={Math.random()}>
              <div className="product-item">

                <div className="item-left">
                <img alt={name.en} className='prod-img' src={select.product_pic} />
                </div>
                <div className="item-right">
                  <div className="item-right-top">
                    <h5 className="prod-title">{name.en}</h5>
                    <p className="prod-desc">{select.note}</p>
                  </div>

                  <div className="item-right-bottom">
                    <div className="left-sec">
                      <span className="prod-price">${select.price}</span>
                    </div>
                    <div className="right-sec">
                      {
                        _.findIndex(checkBasket, (o) => o[1].prodNum == select.product_sn) > -1 ?
                          <i onClick={(e) => {
                            e.stopPropagation();
                            setShowOldEntry('')
                            setProductSelected(select)
                          }} className='prod-add-icon feather-plus'>
                            <i className='badge-icon feather-check'/>
                          </i> :
                          <i onClick={(e) => {
                            e.stopPropagation();
                            selectProduct(select)
                          }} className='prod-add-icon feather-plus'/>
                      }
                    </div>
                  </div>
                </div>

              </div>
            </div>
      );
    });
  };

  const onScrollEventCapturing = (e,id) => {
    const scrollLeft = document.getElementById(id).scrollLeft;
    if  (id === "main-scroll-horizontal"){
      if (scrollLeft == 0) {
        setMainMenuScroll(scrollLeft);
      } else {
        setMainMenuMaxScroll(document.getElementById(id).scrollWidth)
        setMainMenuScroll(document.getElementById(id).scrollLeft + document.getElementById(id).offsetWidth)
      }
    }else {
      if (scrollLeft == 0){
        setSubMenuScroll(scrollLeft)
      }else {
        setSubMenuMaxScroll(document.getElementById(id).scrollWidth)
        setSubMenuScroll(document.getElementById(id).scrollLeft + document.getElementById(id).offsetWidth)
      }
    }
  }

  return (
    <div className="container-fluid custom-container">
      <div className='modal' style={{display:showOldEntry, backgroundColor:"rgba(2,2,2,0.7)"}} tabIndex={-1} role='dialog'>
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary'>
                <h5 className='modal-title text-white'>Add or Customize Product</h5>
                <CloseOutlined className='text-white' onClick={()=> setShowOldEntry('none')}/>
            </div>
            <div className='modal-body'>
              <div className='overflow-scroll py-2'>

                {
                  productSelected && duplicateProduct(productSelected).length > 0 ?
                    duplicateProduct(productSelected) :
                    <div className='col text-center'>
                      <h5> No More Similar Product  Found</h5>
                      <h3><FrownTwoTone twoToneColor='red'/></h3>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {mystate.isRedeemable && <button className='btn btn-primary' onClick={() => {
        dispatch(removeItemBasket([]))
        dispatch(setRedeemable(false))
        localStorage.removeItem('product_id')
        dispatch(getMenuData(localStorage.getItem("STORE_ID")))
        props.history.push(constants.STORE_HOME)
      }}>Exit Redeemable</button>}
      {/*<SlickSliderMenu settings={mainMenuSettings} Menu={MainMenu()} />*/}

        <div className='mt-3 main-menu-list'>
          <div className='menu-lft-btn '>
            <i className={`feather-chevrons-left ${mainMenuScroll != 0 ? '' : 'disabled-btn'}`}
                onClick={() => {
                  document.getElementById('main-scroll-horizontal').scrollLeft -= 100
                  setMainMenuScroll(document.getElementById('main-scroll-horizontal').scrollLeft)
                }}
            />
          </div>
          <div className='mainMenu' id='main-scroll-horizontal' onScroll={(e) => onScrollEventCapturing(e, e.target.id)}>
            {MainMenu()}
          </div>
          <div className='menu-rgt-btn'>
             <i className={`feather-chevrons-right ${mainMenuScroll < mainMenuMaxScroll ? '' : 'disabled-btn'}`} onClick={() => {
              document.getElementById('main-scroll-horizontal').scrollLeft += 66;
              setMainMenuMaxScroll(document.getElementById('main-scroll-horizontal').scrollWidth);
              setMainMenuScroll(document.getElementById('main-scroll-horizontal').scrollLeft + document.getElementById('main-scroll-horizontal').offsetWidth);
            }}/>
          </div>
        </div>
      {confirmDelete && <AlertRemoveProduct visible={confirmDelete != null ? true : false} infoText='Delete Product' description='Are you sure you want to remove producut from your cart' setCofirmDelete = {deleteProduct} accept ={confirmDelProduct}/>}
      {show && (
        // <SlickSliderMenu settings={subMenuSettings} Menu={subMenuCreation()} />
          <div className='main-menu-list'>
            <div className='menu-lft-btn' >
              <i className={`feather-chevrons-left ${subMenuScroll == 0 ? 'disabled-btn' : ''}`}
                 onClick={() => {
                   document.getElementById('sub-menu-scroll-horizontal').scrollLeft -= 60
                   // console.log(document.getElementById('sub-menu-scroll-horizontal').scrollLeft)
                   setSubMenuScroll(document.getElementById('sub-menu-scroll-horizontal').scrollLeft)
                 }}/>
            </div>
            <div className='subMenu' id='sub-menu-scroll-horizontal' onScroll={(e) => onScrollEventCapturing(e, e.target.id)}>
              {subMenuCreation()}
            </div>
            <div className='menu-rgt-btn'>
              <i className={`feather-chevrons-right ${subMenuScroll < subMenuMaxScroll ? '' : 'disabled-btn'}`}
                 onClick={() => {
                   document.getElementById('sub-menu-scroll-horizontal').scrollLeft += 60
                   setSubMenuMaxScroll(document.getElementById('sub-menu-scroll-horizontal').scrollWidth);
                   setSubMenuScroll(document.getElementById('sub-menu-scroll-horizontal').scrollLeft + document.getElementById('sub-menu-scroll-horizontal').offsetWidth);
                 }}></i>
            </div>
          </div>

      )}
      {products && (
          <div className='productMenu' style={{paddingBottom:'56px'}}>

            {productMenuCreation()}
          </div>
      )}


    </div>
  );
};

export default StoreLanding;
