import * as Texts from "../commons/Texts";
import { useEffect } from "react";
import { get } from "../commons/storageService";
import { getFeedback } from "../commons/APIService";
import React, { useState } from "react";
import BackButton from "./commons/BackButton";
import {constants} from "../commons/constants";

const Feedback = (props) => {
    const [contentFeedback, setContentFeedback] = useState("");
    const readFeedback = async () => {
        const storeDetails = get("STORE_CREDENTIALS");
        const deviceNo = storeDetails.deviceID;
        let feedback = await getFeedback(deviceNo)
        setContentFeedback(feedback.data)
    };
    useEffect(() => {
        readFeedback();
    }, []);
    return (
      <div>
        <BackButton title={Texts.FEEDBACK} history={() => props.history.replace(constants.STORE_HOME)}/>
        <div className="st-content container">
                <p className="static-text">{contentFeedback}</p>
            </div>
      </div>
    );
};

export default Feedback;
