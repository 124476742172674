import React from "react";
import {Link} from "react-router-dom";
import { constants } from "../../commons/constants";
import { guest, login, USERINFO } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { remove } from "../../commons/storageService";

const Header = (props) => {
  const mystate = useSelector(state => state.impReducer)
  const {userInfo,storeInfo,history} = props;
  const logo = JSON.parse(storeInfo.store_note).Images.Logo;
  const dispatch = useDispatch();
  return (
    <div className="no-gutters fixed-top">
      <header className="section-header">
        <section className="header-main shadow-sm bg-white">
          <div className="container-fluid">
            <div className="row align-items-center" style={{padding: "12px"}}>
              <div className="col-4 d-flex flex-row align-content-center">
                <Link to={constants.STORE_HOME} >
                  {/*<span className="pointer brand-wrap mb-0">*/}
                  <div className="img-fluid">
                    <img className="img-header" src={logo}/>
                  </div>
                </Link>

                <div className="row">
                  <div className="d-flex flex-column justify-content-center">
                    <h4 className="store-title-2">{JSON.parse(storeInfo.store_name).en}</h4>
                    <p className="text-muted small m-0">
                      {storeInfo.street}
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="col-1 d-flex align-items-center m-none">*/}
              {/*  <div className="dropdown mr-3">*/}
              {/*<span*/}
              {/*    className="pointer text-dark dropdown-toggle d-flex align-items-center py-3"*/}
              {/*    id="navbarDropdown"*/}
              {/*    role="button"*/}
              {/*    data-toggle="dropdown"*/}
              {/*    aria-haspopup="true"*/}
              {/*    aria-expanded="false"*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <i className="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"/>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <p className="text-muted mb-0 small">Select Location</p>*/}
              {/*    Jawaddi Ludhiana...*/}
              {/*  </div>*/}
              {/*</span>*/}
              {/*    <div*/}
              {/*        className="dropdown-menu p-0 drop-loc"*/}
              {/*        aria-labelledby="navbarDropdown"*/}
              {/*    >*/}
              {/*      <div className="osahan-country">*/}
              {/*        <div className="search_location bg-primary p-3 text-right">*/}
              {/*          <div className="input-group rounded shadow-sm overflow-hidden">*/}
              {/*            <div className="input-group-prepend">*/}
              {/*              <button className="border-0 btn btn-outline-secondary text-dark bg-white btn-block">*/}
              {/*                <i className="feather-search"/>*/}
              {/*              </button>*/}
              {/*            </div>*/}
              {/*            <input*/}
              {/*                type="text"*/}
              {/*                className="shadow-none border-0 form-control"*/}
              {/*                placeholder="Enter Your Location"*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="p-3 border-bottom">*/}
              {/*      <span className="pointer text-decoration-none">*/}
              {/*        <p className="font-weight-bold text-primary m-0">*/}
              {/*          <i className="feather-navigation"/> New York, USA*/}
              {/*        </p>*/}
              {/*      </span>*/}
              {/*        </div>*/}
              {/*        <div className="filter">*/}
              {/*          <h6 className="px-3 py-3 bg-light pb-1 m-0 border-bottom">*/}
              {/*            Choose your country*/}
              {/*          </h6>*/}
              {/*          <div className="custom-control  border-bottom px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio1"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio1"*/}
              {/*            >*/}
              {/*              Afghanistan*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*          <div className="custom-control  border-bottom px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio2"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*                defaultChecked*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio2"*/}
              {/*            >*/}
              {/*              India*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*          <div className="custom-control  border-bottom px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio3"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio3"*/}
              {/*            >*/}
              {/*              USA*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*          <div className="custom-control  border-bottom px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio4"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio4"*/}
              {/*            >*/}
              {/*              Australia*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*          <div className="custom-control  border-bottom px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio5"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio5"*/}
              {/*            >*/}
              {/*              Japan*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*          <div className="custom-control  px-0 custom-radio">*/}
              {/*            <input*/}
              {/*                type="radio"*/}
              {/*                id="customRadio6"*/}
              {/*                name="location"*/}
              {/*                className="custom-control-input"*/}
              {/*            />*/}
              {/*            <label*/}
              {/*                className="custom-control-label py-3 w-100 px-3"*/}
              {/*                htmlFor="customRadio6"*/}
              {/*            >*/}
              {/*              China*/}
              {/*            </label>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="col-8">
                <div className="d-flex align-items-center justify-content-end pr-5">
                  {/*<span className="pointer widget-header mr-4 text-dark">*/}
                  {/*  <div className="icon d-flex align-items-center">*/}
                  {/*    <i className="feather-search h6 mr-2 mb-0" />{" "}*/}
                  {/*    <span>Search</span>*/}
                  {/*  </div>*/}
                  {/*</span>*/}

                  {/*<span className="pointer widget-header mr-4 text-white btn bg-primary m-none">*/}
                  {/*  <div className="icon d-flex align-items-center">*/}
                  {/*    <i className="feather-disc h6 mr-2 mb-0" />{" "}*/}
                  {/*    <span>Offers</span>*/}
                  {/*  </div>*/}
                  {/*</span>*/}
                  <div className="dropdown mr-4 m-none">
                    <span
                      className="pointer dropdown-toggle text-dark py-3 d-block"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        alt="#"
                        src="/img/avatar.png"
                        className="img-fluid rounded-circle header-user mr-2 header-user"
                      />
                      Hi{" "}
                      {Object.keys(userInfo).length === 0
                        ? "Guest"
                        : !userInfo.name
                        ? userInfo.CRM.phoneNumber
                        : userInfo.name}
                    </span>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >

                      <Link
                        className="pointer dropdown-item"
                        to={constants.STORE_HOME}
                      >
                        Home
                      </Link>
                      <Link
                        className="pointer dropdown-item"
                        to={constants.REFUND_POLICY}
                      >
                        Refund and Cancellation Policy
                      </Link>
                      <Link
                        className="pointer dropdown-item"
                        to={constants.FEEDBACK_CONTACT}
                      >
                        Contant us
                      </Link>
                      <Link
                        className="pointer dropdown-item"
                        to={constants.TNC}
                      >
                        Term of use
                      </Link>
                      <Link
                        className="pointer dropdown-item"
                        to={constants.PRIVACY}
                      >
                        Privacy policy
                      </Link>
                      {Object.keys(userInfo).length === 0 ? (
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            dispatch(login(false));
                            dispatch(guest(false));
                            history.push(constants.LOGIN_ROUTE)
                          }}
                        >
                          Login
                        </a>
                      ) : (
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            dispatch(login(false));
                            remove("USER_INFO");
                            dispatch(USERINFO({}));
                            history.push(constants.LOGIN_ROUTE)
                          }}
                        >
                          Logout
                        </a>
                      )}

                    </div>
                  </div>

                  <span className="pointer widget-header mr-4 text-dark" >
                    <div className="icon d-flex align-items-center" >

                      <Link to={constants.CART} className='d-flex'>
                        <i className='feather-shopping-cart pointer position-relative' style={{fontSize:"24px"}}>
                          {mystate.basket && mystate.basket.length > 0 &&
                          <span className="custom-badge-cart">{mystate.basket && mystate.basket.length > 0 && props.prodQuantity}</span>}
                        </i>
                      </Link>

                      {/*<Link to={constants.CART} className="pointer menu-list">*/}
                      {/*  <i className="feather-shopping-cart h6 mr-2 mb-0" />Cart*/}
                      {/*  <span className="custom-badge-cart">{mystate.basket && mystate.basket.length > 0 && mystate.basket.length}</span>*/}
                      {/*  /!*<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{mystate.basket && mystate.basket.length > 0 && mystate.basket.length}</span>*!/*/}
                      {/*</Link>*/}
                    </div>
                  </span>
                  <span className="pointer toggle">
                    <span />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    </div>
  );
};

export default Header;
