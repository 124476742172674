import {useSelector, useDispatch} from "react-redux";
import {mobileOrder, paymentInformationUpdate, removeItemBasket} from "../actions";
import {constants} from "../commons/constants";
import {Link} from "react-router-dom";
import {createRecipt} from '../commons/utility'
import React, {useEffect, useState} from "react";
import {quantityChange, deleteItem} from "../commons/utility";
import BackButton from "./commons/BackButton";
import {Divider} from "antd";
import AlertRemoveProduct from "./authentication/utility/AlertRemoveProduct";
import {mobileOrderRequest} from "../commons/APIService";



const Cart = (props) => {
    const mystate = useSelector(state => state.impReducer)
    const dispatch = useDispatch();
    const productsArray = mystate.basket;
    const [totalEstimatedPrice, setTotalEstimatedPrice] = useState();
    const [confirmDelete, setConfirmDelete] = useState(null)
    //console.log('getTotalPrice', productsArray)
    const updatePrice = () => {
        let price=0;
        productsArray.map(item=> price= price + Number(item[0].totalPrice))
        //console.log("price found", price.toFixed(2))
        setTotalEstimatedPrice(price.toFixed(2))
    }
    useEffect(()=>{
        updatePrice()
    },[])
    const onCheckOut = (basket) => {
        let objData = {
            mainproduct: '',
            quantity:'',
            subproducts: {
                subproduct:[]
            }
        }
        let trial = {
            request: {
                transaction: {
                    comboitems: {
                        comboitem: []
                    },
                    payments: {
                        payment: []
                    },
                    returnurl: '',
                    cancelurl:'',
                    timeouturl:'',
                    singleitems: {
                        singleitem: []
                    },
                    label:'',
                    saletypenum:'',
                    storeid:''
                }
            },
        }
        let totalPrice = 0;
        let obj = {
            tender:'',
        }
        let combo= null;
        let single = null;
        console.log('Basket:', basket)
        basket.map((item,i)=> {
           let data = JSON.parse(JSON.stringify(objData))
           console.log('item', item)
           if (item.length > 2){
               combo = JSON.parse(JSON.stringify(item))
               data.mainproduct = combo[0].MainProduct;
               data.quantity = Number(combo[1].quantity);
               data.costEach = item[0].MainProductPrice; // Add here else Pixel products that is "Manual Price" will fail
               totalPrice = parseFloat(combo[0].totalPrice) + totalPrice
               console.log('Data:', data)
               console.log('Combo before splice:', combo)
               combo.splice(0,1)
               console.log('Combo after splice:', combo)
               data.subproducts.subproduct = JSON.parse(JSON.stringify(combo));
               trial.request.transaction.comboitems.comboitem.push(data)
           }else if (item.length == 2){
               single = JSON.parse(JSON.stringify(item))
               console.log('Single before splice:', single)
               totalPrice = parseFloat(single[0].totalPrice) + totalPrice
               single[1].costEach = single[0].prodInfo.price; // Add here else Pixel products that is "Manual Price" will fail
               single.splice(0,1)
               console.log('Single after splice:', single)
               trial.request.transaction.singleitems.singleitem.push(single[0])
           }
       })
        obj.tender= parseFloat(totalPrice).toFixed(2).toString()
        trial.request.transaction.storeid = mystate.store.store_id
        trial.request.transaction.payments.payment.push(obj)
        dispatch(mobileOrder(trial))
        if (mystate && mystate.locate && mystate.locate.hasTableService && mystate.locate.tableNum != null){
            trial.request.transaction.saletypenum = Number(1)
            trial.request.transaction.tablesessionid = mystate.storeNote.sessionId.toString()
            trial.request.transaction.payments.payment[0].methodnumber = Number(999)
            dispatch(mobileOrder(trial))
            console.log("Mobile order object for Table Service:", trial)
            mobileOrderRequest(trial).then(r => {
                console.log("Server response:",r)
                if (r.result_code == 200) {
                    let storeMessage = JSON.parse(r.store_response)
                    let paymentSuccessObj = mystate.paymentInformation;
                    paymentSuccessObj.recipt = mystate.basket
                    paymentSuccessObj.grandTotal = trial.request.transaction.payments.payment[0].tender
                    paymentSuccessObj.totalPrice = trial.request.transaction.payments.payment[0].tender
                    paymentSuccessObj.cloudOrderNumber = r.cloud_order_number
                    dispatch(paymentInformationUpdate(paymentSuccessObj))
                    localStorage.setItem('recipt', JSON.stringify(mystate.basket))
                    if (storeMessage.Response.ErrorNum == 0) {
                        dispatch(removeItemBasket([]))
                        props.history.push({
                            pathname: constants.STORE_RESPONSE,
                            state: r
                        })
                    } else if (storeMessage.Response.ErrorNum == -1 || storeMessage.Response.ErrorNum == 999) {
                        let s = r
                        s.isError = true
                        props.history.push(
                            {
                                pathname: constants.STORE_RESPONSE,
                                state: s
                            }
                        )
                    }
                }
                else if ((r.response && r.response.data && r.response.data.result_code == 500) || r.result_code == 500 ){
                    let s = r;
                    s.isError = true;
                    props.history.push({
                        pathname: constants.STORE_RESPONSE,
                        state: s
                    })
                }
            })

        }else if (mystate && mystate.locate && mystate.locate.hasTableService == false) {
            dispatch(mobileOrder(trial))
            props.history.push(
                {
                    pathname: constants.SALESTYPE,
                    state: trial
                })
        }
    }

    const products = mystate.allProducts.products;
    //selected Products need to be saved in localstorage for creating final recipt....
    const selectedProducts = mystate.basket;
    const deleteProduct= () => {
        setConfirmDelete(null)
        console.log(confirmDelete)
        updatePrice()
    }
    const confirmDelProduct = ()  => {
        deleteItem(confirmDelete)
        setConfirmDelete(null)
        updatePrice()
    }

    return (
        <div className='rounded-top'>
            <BackButton title="Your Cart" history={() => props.history.replace(constants.STORE_HOME)}/>
            {/*<i className=' feather-chevrons-left pointer' onClick={() => props.history.goBack()}> BACK </i>*/}
            <div className='d-flex justify-content-center mt-5'>
                <div className='col-sm-11 pt-2'>

                {selectedProducts && selectedProducts.length > 0 ?
                    <div className='rounded'>
                        <div className=' py-2 row'>
                            {createRecipt(props.history.location.pathname, selectedProducts, products, quantityChange, deleteItem,updatePrice,confirmDelete, setConfirmDelete, deleteProduct, confirmDelProduct)}
                        </div>
                        {/*{confirmDelete && <AlertRemoveProduct visible={confirmDelete != null ? true : false} infoText='Delete Product' description='Are you sure you want to remove producut from your cart' setCofirmDelete = {deleteProduct} accept ={confirmDelProduct}/>}*/}
                    </div> :
                    <div className='card-body'>
                        <div className='col-sm-12 text-center'>
                            <img src="https://i.imgur.com/dCdflKN.png" width="130" height="130" className="img-fluid mb-4 mr-3"/>
                            <h5><strong>YOUR CART IS EMPTY</strong></h5>
                            <p>You have not ordered anything yet</p>
                            <p>Not sure what to order? Check out our most popular dishes from the main menu!</p>
                            <Link className='btn btn-outline-danger p-3' to={constants.STORE_HOME}>START ORDERING</Link>
                        </div>
                    </div>
                }
                {/*<div className='d-block'>*/}
                {/*    <div className='d-flex justify-content-center'><h4>Total Price: $ {totalEstimatedPrice}</h4></div>*/}
                {/*</div>*/}

                {selectedProducts && selectedProducts.length > 0 &&
                  <div>
                      <Divider orientation="center">Order Summary</Divider>
                    <div className='order-summary bg-white p-2 mb-2 d-flex flex-row justify-content-between'>
                        <h5>Total Price</h5>
                        <h5>${totalEstimatedPrice}</h5>
                    </div>
                    <div className='d-flex flex-row justify-content-center mt-3'>
                        <Link className='w-50 btn btn-danger font-weight-bold' onClick={() => onCheckOut(productsArray)}> { mystate && mystate.locate && mystate.locate.hasTableService == true ? "Place Order" :"CHECK OUT"}</Link>
                    </div>
                  </div>
                }
                </div>
            </div>
        </div>
    )
}
export default Cart;
