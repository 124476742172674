import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import { PrivateRoute } from "./commons/PrivateRoute";
import { constants } from "./commons/constants";
import LocateStore from "./component/LocateStore";
import Auth from "./component/authentication/Auth";
import StartOrder from "./component/StartOrder";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path={constants.AUTH_ROUTE} component={Auth}/>
          <Route path={constants.LOCATE_STORE} exact={true} component={LocateStore} />
            <Route path={constants.LOCATE_STORE2} exact={true} component={LocateStore} />
          <Route path="/" component={App}/>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
